/**
 * Created by GSP on 15/09/2015.
 */
/**
 * Created by RKL on 10/09/2015.
 */
define('chartView',[
  'module',
  'logger',
  'marionette',
  'underscore',
  'chart',
  'template!chartViewTpl'
], function(
    module,
    Logger,
    Marionette,
    _,
    Chart,
    viewTpl
) {
  'use strict';

  var ChartView = Marionette.ItemView.extend({

    template: viewTpl,

    ui: {
      chart: '.chart-canvas',
      sliderVert: '.slider-range-vert',
      resetSliderVert: '.reset-slider-vert',
      resetSlider: '.reset-slider'
    },

    events: {
      'click @ui.resetSliderVert': 'resetSliderVert',
      'click @ui.resetSlider': 'resetSlider'
    },

    resetSlider: function() {
      this.filterValuesX(null, null);
    },

    resetSliderVert: function() {
      this.filterValuesY(null, null);
    },

    initOptions: function() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1.2,
        hover: {
          animationDuration: 0,
          intersect: false
        },
        onCustomClick: _.bind(function(item) {
          if(this.onCurveClick) {
            this.onCurveClick(item.well, item.target);
          }
        }, this),
        tooltips: {
          callbacks: {
            label: function(tooltipItem, data) {
                var label = data.datasets[tooltipItem.datasetIndex].label || '';
                if (label) {
                    label += ': ';
                }
                label += Math.round(tooltipItem.yLabel * 100) / 100;
                return label;
            },
            title: function(tooltipItem, data) {
                var label = data.datasets[tooltipItem[0].datasetIndex].tooltip || '';
                return tooltipItem[0].xLabel + ' ' + label;
            }
          }
        }
      };
    },

    getOptions: function(options) {
      return _.extend(options || {}, this.initOptions());
    },

    onShow: function() {
      this.type = this.options.type;
      if ( !_.isUndefined(this.data) && !_.isEmpty(this.data)) {
        this.instance = new Chart(this.ui.chart[0].getContext('2d'), {
          type: this.type,
          data: this.data,
          options: this.getOptions(this.options)
        });

        if (this.options.legend) {
          this.$el.prepend(this.options.legend);
        }
      }
      
      var ci = this.instance.chart;
      if (this.filterValuesY) {
        var legV = ci.boxes[3];
        this.ui.sliderVert.css('top', this.instance.chartArea.top + 'px');
        this.ui.sliderVert.css('height', this.instance.chartArea.bottom - this.instance.chartArea.top + 'px');
        this.ui.resetSliderVert.css('top', this.instance.chartArea.top - 30 + 'px');
        this.ui.resetSliderVert.show();
        this.ui.sliderVert.slider({
          range: true,
          min: legV.min,
          orientation: 'vertical',
          max: legV.max,
          values: [ legV.min, legV.max ],
          change: _.bind(function( event, ui ) {
            if((ui.values[0] !== legV.min || ui.values[1] !== legV.max)) {
              this.filterValuesY(ui.values[0], ui.values[1]); 
            }
          }, this)
        });
      }
      if (this.filterValuesX) {
        var legH = ci.boxes[2];
        this.ui.resetSlider.show();
        $('.slider-range').slider({
          range: true,
          min: legH.min,
          max: legH.max,
          values: [ this.minXVal || legH.min, this.maxXVal || legH.max ],
          change: _.bind(function( event, ui ) {
            if((ui.values[0] !== legH.min || ui.values[1] !== legH.max) && 
            (this.minXVal !== ui.values[0] || this.maxXVal !== ui.values[1])) {
              this.filterValuesX(ui.values[0], ui.values[1]);
            } else if (this.minXVal && this.maxXVal) {
              this.filterValuesX(null, null);
            }
          }, this)
        });
      }
    },

    onDestroy : function() {
      if (this.instance) {
        this.instance.destroy();
        Logger.log(module, 'destroy');
      }
    }
  });

  module.exports = ChartView;
});
