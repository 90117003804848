/**
 * Created by BFR on 29/04/2020.
 */
define('tracsView',[
  'module',  
  'marionette',
  'underscore',
  'app',
  'jquery',
  'settings',
  'jqgridView',
  'template!tracsTpl',
  'colorUtils',
  'dateUtils',
  'entities/tracs'
], function(
  module,
  Marionette,
  _,
  App,
  $,
  Settings,
  JqGridView,
  viewTpl,
  ColorUtils,
  dateConverter
) {
  'use strict';

  var TracTableView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'tracsFilters',

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list',
        jqgRow: 'tr.jqgrow'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
      }, this.gridEvents);
    },

    onShow: function() {
      $.material.init();
    },

    serializeData: function() {
      var templateData = {};
      templateData.creatDate = dateConverter.toDateFormatString(this.model.get('creatDate'));
      templateData.model = this.model.toJSON();
      return templateData;
    },

    gridOptions: function(data) {
       var defaultsOptions, options, 
        _colModel = [];

        _colModel.push({
            label: '',
            name: 'secId',
            key: true,
            hidden: true
        });

        _colModel.push({
          label: _.i18n('trac.creatDate'),
          name: 'creatDate',
          classes: 'order-link',
          formatter: this.dateTimeFormatter,
          search: false,
          index: 'date',
          width: 60
        });

        _colModel.push({
            label: _.i18n('trac.refUserpid'),
            name: 'refUserpid',
            classes: 'well-list-pos-label',
            formatter: this.userFormatter,
            search: false,
            sortable: false,
            index: 'refUserpid',
            width: 60
        });

        _colModel.push({
            label: _.i18n('trac.type'),
            name: 'type',
            classes: 'well-list-label',
            formatter: this.defaultFormatter,
            search: false,
            sortable: false,
            index: 'type',
            width: 120
        });

        _colModel.push({
          label: _.i18n('trac.comment'),
          name: 'comment',
          classes: 'well-list-label',
          formatter: this.defaultFormatter,
          search: false,
          sortable: false,
          index: 'comment',
          width: 120
      });


      defaultsOptions = this.gridInitOptions(data);
      options = {
          colModel: _colModel,
          sortname: 'date'
      };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    onLoadComplete: function(data) {
      this.codeTableData = data;
      if(data && data.length) {
        this.ui.jqGrid.setSelection(data[0].secId).click();
      }
    },

    userFormatter: function(cellValue, options) {
      var value = '';
      if (cellValue) {
        value = cellValue.nickName?cellValue.nickName:cellValue.logonId;
      }
      return '<span class="cell-default" data-row-id="' +
          options.rowId + '">' + value + '</span>';
    },

    onPreselectRow: function(rowId) {
      if (this.codeTableData) {
        this.ui.jqgRow.removeClass('btn-secondary');
        this.ui.jqGrid.find('tr#' + rowId).addClass('btn-secondary');
      } else {
        return;
      }
    },

    pagination: function() {
      return {
          first: 0,
          rowCount: 0,
          pageSize: 50,
          currentPage: 1
      };
    }
  });

  module.exports = TracTableView;
});
