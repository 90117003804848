/**
 * Created by OLD on 04/09/2015.
 */
define('colorUtils',[
  'module'
], function(
  module
) {
  'use strict';

  var ColorConverter = {
    decimalToHexa: function (decimal) {
      var hexa = parseInt(decimal, 10).toString(16);

      while (hexa.length < 6) {
        hexa = '0' + hexa;
      }
      return '#' + hexa;
    },

    rgbToDecimal: function(r, g, b) {
      return ((r * 65536) + (g * 256) + b);
    },

    rgbObjectToHexa: function(rgb) {
      return this.decimalToHexa(this.rgbToDecimal(rgb.r, rgb.g, rgb.b));
    },

    hexToRgb: function(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
      } : null;
    },

    colorTarget: function(target, run, well) {
      var color;
      if (run && run.get('settings') && run.get('settings').TARGETS_COLORS) {
        var col = run.get('settings').TARGETS_COLORS.split(';')[target - 1];
        color = this.hexToRgb(col);
      } else {
        switch(target) {
          case 1: color = {
            r:226,
            g:147,
            b:239
          }; break;
          case 2: color = { 
            r:0,
            g:0,
            b:239
          }; break;
          case 3: color = {
            r:0,
            g:239,
            b:0
          }; break;
          case 4: color = {
            r:239,
            g:0,
            b:0
          }; break;
          case 5: color = {
            r:239,
            g:0,
            b:239
          }; break;
          case 6: color = {
            r:0,
            g:239,
            b:239
          }; break;
        }
      }
      if (well && well.get('res' + target + 'CodeErr')) {
        if (well.get('res' + target + 'CodeErr') === 'E') {
          color.dashed = {
            borderDashOffset: 3,
            borderDash: [2,4]
          };
        } else if (well.get('res' + target + 'CodeErr') === 'W') {
          color.dashed = {
            borderDashOffset: 10,
            borderDash: [12,8]
          };
        }
      }
      return color;
    }
  };

  module.exports = ColorConverter;
});

