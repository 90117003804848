/**
 * Created by bfr on 10/04/2020.
 */
define('racksMainRouter',[
    'require',
    'exports',
    'module',
    'logger',
    'marionette',
    'app',
    'contentShowController',
    'itemPagesNameMixin',
    'emptyView'
], function(
    require,
    exports,
    module,
    Logger,
    Marionette,
    App,
    ContentShowController,
    ItemPagesNameMixin,
    EmptyView
) {
    'use strict';

    var RacksRouter = Marionette.AppRouter.extend({
        appRoutes: {
            'racks': 'showView',
            'racks/empty': 'showEmpty',
            'racks/new': 'showViewNewRack',
            'racks/:rackSecId': 'showRackTableTab',
            'racks/:rackSecId/detail': 'showRackDetailTab',
            'racks/:rackSecId/table': 'showRackTableTab',
            'racks/:rackSecId/details': 'showRackDetailsTab',
            'racks/:rackSecId/details/filters/:filters': 'showRackDetailsTabFilters',
            'racks/:rackSecId/details/row/:row': 'showRackDetailsTabRow',
            'racks/:rackSecId/details/column/:column': 'showRackDetailsTabColumn',
            'racks/:rackSecId/details/row/:row/column/:column': 'showRackDetailsTab'
        }
    });

    var API = {

        showEmpty: function() {
        },

        showViewNewRack: function() {
            this.showViewRack(true);
        },

        showViewRack: function(newItem) {
            require([
                'racksShowController'
            ], function(RacksShowController) {
                ContentShowController.showContent().done(function() {
                    App.startSubModule('racksMain');
                    RacksShowController.showRacks();
                    App.execute('set:active:nav:item', ItemPagesNameMixin.RACKS);

                    if (newItem) {
                        RacksShowController.showNewRack();
                    } else if (RacksShowController.createEditView) {
                        RacksShowController.createEditView.hide(true);
                    }
                });
            });
        },

        showView: function() {
            this.showViewRack(false);
        },

        showRackDetailTab: function(rackSecId) {
            ContentShowController.showContent().done(function() {
                require([
                    'rackTabsLayoutController',
                    'rackDetailTabController'
                ], function(RackTabsLayoutController, RackDetailTabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    region.show(new EmptyView());
                    RackTabsLayoutController.showTabsRackLayout(region, 'detail', rackSecId).done(function(region, rackModel) {
                        RackDetailTabController.showDetailTab(region, rackModel);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RACKS);
                    });
                });
            });
        },

        showRackTableTab: function(rackSecId) {
            ContentShowController.showContent().done(function() {
                require([
                    'rackTabsLayoutController',
                    'rackTableTabController'
                ], function(RackTabsLayoutController, RackTableTabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    region.show(new EmptyView());
                    RackTabsLayoutController.showTabsRackLayout(region, 'table', rackSecId).done(function(region, rackModel) {
                        RackTableTabController.showTableTab(region, rackModel);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RACKS);
                    });
                });
            });
        },

        showRackDetailsTab: function(rackSecId, row, column) {
            ContentShowController.showContent().done(function() {
                require([
                    'rackTabsLayoutController',
                    'rackDetailsTabController'
                ], function(RackTabsLayoutController, RackDetailsTabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    RackTabsLayoutController.showTabsRackLayout(region, 'details', rackSecId).done(function(region, rackModel) {
                        RackDetailsTabController.showDetailsTab(region, rackModel, row, column);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RACKS);
                    });
                });
            });
        },

        showRackDetailsTabFilters: function(rackSecId, filters) {
            ContentShowController.showContent().done(function() {
                require([
                    'rackTabsLayoutController',
                    'rackDetailsTabController'
                ], function(RackTabsLayoutController, RackDetailsTabController) {
                    var region = App.regions.getRegion('main').currentView.getRegion('content');
                    RackTabsLayoutController.showTabsRackLayout(region, 'details', rackSecId).done(function(region, rackModel) {
                        var filtersJson = filters?JSON.parse(filters):null;
                        RackDetailsTabController.showDetailsTab(region, rackModel, null, null, filtersJson);
                        App.execute('set:active:nav:item', ItemPagesNameMixin.RACKS);
                    });
                });
            });
        },

        showRackDetailsTabRow: function(rackSecId, row) {
            this.showRackDetailsTab(rackSecId, row);
        },

        showRackDetailsTabColumn: function(rackSecId, column) {
            this.showRackDetailsTab(rackSecId, null, column);
        }
    };

    App.on('racks:show', function() {
        App.navigate('racks');
        API.showView();
    });

    App.on('before:start', function() {
        new RacksRouter({
            controller: API
        });
    });

    module.exports = RacksRouter;
});
