
define('template!rackDetailTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="tab-content content">\n  <div class="well run-detail">\n    <div class="detailed-info-form-container">\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('rack.rackName') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( rackName ? rackName : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('rack.rackId') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( rackId ? rackId : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('rack.lRackId') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( lRackId ? lRackId : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('rack.eRackId') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( eRackId ? eRackId : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('rack.rackSt') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( rackSt ? rackSt : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('rack.pcrSt') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( pcrSt ? pcrSt : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('rack.nbCol') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( nbCol ? nbCol : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="col-md-6">\n          <div class="col-xs-4">' +
((__t = ( _.i18n('rack.nbRows') )) == null ? '' : __t) +
'</div>\n          <div class="col-xs-8">' +
((__t = ( nbRows ? nbRows : '-' )) == null ? '' : __t) +
'</div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-primary js-edit">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

