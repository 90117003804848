define('rackCreateFromUploadView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!rackCreateFromUploadTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'settings',
  'autocompleteView',
  'entities/runs'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  Settings,
  AutocompleteView
) {
  'use strict';

  var CreateFromUploadView = DialogFormView.extend({
    template: createEditLayoutTpl,

    events: {
      'click .js-cancel-popup': 'hide',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      selectLis: '.js-select-lis-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
    ],

    fromTemplate: true,

    onShow: function() {
      $.material.init();
      this.lisView = new AutocompleteView(
          this._getAutocompleteOptions('refLis', 'code', 'code', 'v2/lis',
            'selectlis.placeholder', 'code', this.onLisChange)
      );
      this.getRegion('selectLis').show(this.lisView);

      this.triggerMethod('enable:cancel:confirm');
    },

    onLisChange: function(fieldName, model) {
      this.refLis = model;
    },

    onConfirm: function() {
        this.trigger('popup-create:selectLis', this.refLis);
        this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
    }
  });

  module.exports = CreateFromUploadView;
});
