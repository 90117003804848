
define('template!chartViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="col-xs-12" style="height:500px;">\n    <canvas class="chart-canvas"></canvas>\n    <div class="slider-range" style="margin-left: 40px; margin-right: 6px;"></div>\n    <i class="mdi-action-cached reset-slider-vert clickable" style="display: none; position: absolute; right: -5px;"></i>\n    <div class="slider-range-vert" style="position: absolute; bottom: 0px; right: 0px;"></div>\n    <i class="mdi-action-cached reset-slider clickable" style="display: none; position: absolute; bottom: -17px; left: 22px;"></i>\n</div>\n';

}
return __p
};});

