define('entities/samples',[
  'app',
  'backbone',
  'settings',
  'jquery'
], function(
  App,
  Backbone,
  Settings,
  $
) {
  'use strict';

  var Sample = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/samples/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },

    defaults: {
      sampleId: '',
      orderId: '',
      patId: '',
      sampleDt: '',
      ppgr: 'S',
      sampleTest: ''
    },
    idAttribute: 'secId',

    getImageUid:function() {},

    postUrl: function () {
      return Settings.url('compuzz', 'v2/samples');
    },

    save: function() {
      this.url = this.postUrl();
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var SampleCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/samples');
    },
    model: Sample
  });

  var API = {

    getSampleCollectionUrl: function() {
      new SampleCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/samples', params);
    },

    getSampleCountUrl: function() {
      var params = '';
      return 'v2/samples/rowCount' + params;
    },

    deleteSample: function(secId){
      var url = Settings.url('compuzz', 'v2/samples/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    importXlsFile: function(fileInfo, lisSecId) {
      var defer = $.Deferred(),
        formdata =  new FormData(),
        url = Settings.url('compuzz', 'v2/samples/excel', { lisSecId: lisSecId });

      formdata.append('file', fileInfo);

      $.ajax({
        type: 'POST',
        url: url,
        data: formdata,
        processData: false,
        contentType: false,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    },

    checkTests: function(tests) {
      var defer = $.Deferred(),
        url = Settings.url('compuzz', 'v2/samples/checkTests', { tests: tests });

      $.ajax({
        type: 'GET',
        url: url,
        success: function (data) {
          defer.resolve(data);
        },
        error: function(data) {
          defer.reject(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('sample:get-collection-url', function() {
      return API.getSampleCollectionUrl();
  });

  App.reqres.setHandler('sample:get-count-url', function() {
    return API.getSampleCountUrl();
  });

  App.reqres.setHandler('sample:model', function(sampleJSON) {
    return new Sample(sampleJSON);
  });

  App.reqres.setHandler('sample:delete', function(secId) {
    return API.deleteSample(secId);
  });

  App.reqres.setHandler('sample:importXlsFile', function(fileInfo, lisSecId) {
    return API.importXlsFile(fileInfo, lisSecId);
  });

  App.reqres.setHandler('sample:checkTests', function(tests) {
    return API.checkTests(tests);
  });
});
