define('entities/rackels',[
  'app',
  'backbone',
  'settings'
], function(
  App,
  Backbone,
  Settings
) {
  'use strict';

  var Rackel = Backbone.Model.extend({
    fetch: function() {
      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      'rowNb':  '',
      'colNb': ''
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function (rackSecId) {
      return Settings.url('compuzz', 'v2/racks/' + rackSecId + '/rackels');
    },

    save: function() {
      this.url = this.postUrl(this.get('rack'));
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var RackelCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/racks');
    },
    model: Rackel
  });

  var API = {

    getRackelCollectionUrl: function(rackSecId, row, column, assaySecId, smpid) {
      new RackelCollection();
      var params = {};
      if (row) {
        params.row = row;
      }
      if (column) {
        params.column = column;
      }
      if (assaySecId) {
        params.assaySecId = assaySecId;
      }
      if (smpid) {
        params.smpid = smpid;
      }
      return Settings.url('compuzz', 'v2/racks/' + rackSecId + '/rackels', params);
    },

    getRackelsCountUrl: function(rackSecId, row, column, assaySecId, smpid) {
      var params = '?';
      if (row) {
        params += 'row=' + row + '&';
      }
      if (column) {
        params += 'column=' + column + '&';
      }
      if (assaySecId) {
        params += 'assaySecId=' + assaySecId + '&';
      }
      if (smpid) {
        params += 'smpid=' + smpid + '&';
      }
      return 'v2/racks/'+rackSecId+'/rackels/rowCount' + params;
    }
  };

  App.reqres.setHandler('rackel:get-collection-url', function(rackSecId, row, column, assaySecId, smpid) {
      return API.getRackelCollectionUrl(rackSecId, row, column, assaySecId, smpid);
  });

  App.reqres.setHandler('rackel:get-count-url', function(rackSecId, row, column, assaySecId, smpid) {
    return API.getRackelsCountUrl(rackSecId, row, column, assaySecId, smpid);
  });

  App.reqres.setHandler('rackel:model', function(rackJSON) {
    return new Rackel(rackJSON);
  });
});
