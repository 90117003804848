
define('template!wellCreateEditTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('well.pos') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="p-t-8">\n            ' +
((__t = ( model.pos ? model.pos : '-' )) == null ? '' : __t) +
'\n          </div>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="plateId">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('well.smpid') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input js-smpid"\n                 data-field-name="smpid" placeholder="' +
((__t = ( _.i18n('well.smpid') )) == null ? '' : __t) +
'"\n                 value="' +
((__t = ( model.smpid )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('run.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                 data-placement="top">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('well.smptype') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input" data-field-name="smptype" value="">\n            <option value="" ';
  if (!model.smptype) { ;
__p += ' selected ';
 } ;
__p += ' ></option>\n            <option value="E" ';
  if (model.smptype === 'E') { ;
__p += ' selected ';
 } ;
__p += ' >' +
((__t = ( _.i18n('well.smptype.e') )) == null ? '' : __t) +
'</option>\n            <option value="C" ';
  if (model.smptype === 'C') { ;
__p += ' selected ';
 } ;
__p += ' >' +
((__t = ( _.i18n('well.smptype.c') )) == null ? '' : __t) +
'</option>\n            <option value="PC" ';
  if (model.smptype === 'PC') { ;
__p += ' selected ';
 } ;
__p += ' >' +
((__t = ( _.i18n('well.smptype.pc') )) == null ? '' : __t) +
'</option>\n            <option value="NC" ';
  if (model.smptype === 'NC') { ;
__p += ' selected ';
 } ;
__p += ' >' +
((__t = ( _.i18n('well.smptype.nc') )) == null ? '' : __t) +
'</option>\n            <option value="U" ';
  if (model.smptype === 'U') { ;
__p += ' selected ';
 } ;
__p += ' >' +
((__t = ( _.i18n('well.smptype.u') )) == null ? '' : __t) +
'</option>\n            <option value="P" ';
  if (model.smptype === 'P') { ;
__p += ' selected ';
 } ;
__p += ' >' +
((__t = ( _.i18n('well.smptype.p') )) == null ? '' : __t) +
'</option>\n          </select>\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('well.refAssay') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="js-select-assay-region"></div>\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('well.refKitL') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="js-select-kitl-region"></div>\n        </div>\n      </div>\n      <div class="row">\n        <div class=""></div>\n        <label class="col-xs-offset-1 col-xs-3">' +
((__t = ( _.i18n('well.target') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-1">' +
((__t = ( _.i18n('well.res') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-3">' +
((__t = ( _.i18n('well.values') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-1">' +
((__t = ( _.i18n('well.resText') )) == null ? '' : __t) +
'</label>\n        <label class="col-xs-2">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</label>\n      </div>\n      ';
 for (var i = 1 ; i <= 6 ; i++) { ;
__p += '\n        ';
 if (model['meltCurve' + i] || model['amplCurve'+i]) { ;
__p += '\n        <div class="row">\n          <label class="col-xs-offset-1 col-xs-3">' +
((__t = ( values['target' + i] )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-1">\n            ';
 if (values['res'+i+'Color']) { ;
__p += '\n            <div class="well-square-icon-green">\n              <div class="well-circle-icon" style="background: ' +
((__t = ( values['res'+i+'Color'] )) == null ? '' : __t) +
';"/>\n            </div>\n            ';
 } ;
__p += '\n          </div>\n          <div class="form-group col-xs-3 m-b-0 m-r-0 m-l-0">\n            <input type="text" class="form-control floating-label inputRes1Num" data-field-name="' +
((__t = ( 'res'+i+'Num1' )) == null ? '' : __t) +
'"\n                   placeholder="" value="' +
((__t = ( (model['res'+i+'Num1']?model['res'+i+'Num1']:'') + (model['res'+i+'Num2']?', '+model['res'+i+'Num2']:'') + (model['res'+i+'Num3']?', '+model['res'+i+'Num3']:'') )) == null ? '' : __t) +
'">\n          </div>\n          <div class="form-group col-xs-1 m-b-0 m-r-0 m-l-0">\n            <input type="text" class="form-control floating-label js-info-input inputResCode" data-field-name="' +
((__t = ( 'res'+i+'Code' )) == null ? '' : __t) +
'"\n                   maxlength="5" placeholder="" value="' +
((__t = ( model['res'+i+'Code'] )) == null ? '' : __t) +
'">\n          </div>\n          <div class="form-group col-xs-2 m-b-0 m-r-0 m-l-0">\n            <input type="text" class="form-control floating-label js-info-input" data-field-name="' +
((__t = ( 'res'+i+'Comment' )) == null ? '' : __t) +
'"\n                   maxlength="5" placeholder="" value="' +
((__t = ( model['res'+i+'Comment'] )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n        ';
 } ;
__p += '\n      ';
 } ;
__p += '\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

