
define('template!runTemplateTabsTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="with-header">\n  <div class="tab-layout-header js-tab-layout-header hidden-print">\n    <div class="tab-layout-info row">\n      <div class="logo-container col-left col-lg-2">\n        <!-- Logo -->\n        <div class="runIcon">\n          RT\n        </div>\n        <i class="btn-edit-run mdi-content-create clickable" style="font-size: 25px;" title="' +
((__t = ( _.i18n('well.tooltip.edit') )) == null ? '' : __t) +
'"></i>\n      </div>\n      <div class="col-left name-holder js-name-holder m-t-0 col-lg-6">\n        <p class="large-bold-compuzz">' +
((__t = ( name )) == null ? '' : __t) +
'</p>\n        <p class="small-regular-compuzz">' +
((__t = ( analyser )) == null ? '' : __t) +
'</p>\n      </div>\n      <div class="js-header-region"></div>\n    </div>\n    <div class="navbar navbar-default navbar-md row m-b-0">\n      <div class="navbar-collapse collapse">\n        <ul class="nav navbar-nav p-l-10">\n          ';
 _.each(tabs, function(tab) { ;
__p += '\n            <li class="js-tab-title tab-layout-tab js-tab-layout-tab js-' +
((__t = ( tab.name )) == null ? '' : __t) +
' clickable" data-tab-name="' +
((__t = ( tab.name )) == null ? '' : __t) +
'"><a>' +
((__t = ( tab.title )) == null ? '' : __t) +
'</a></li>\n          ';
 }) ;
__p += '\n        </ul>\n      </div>\n    </div>\n  </div>\n</div>\n<div class="tab-layout-content js-tabs-region height-100"></div>';

}
return __p
};});

