
define('template!wellTpl2', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="ui-show-result-cell wellSelectableItem" ' +
((__t = ( 'style="' + (colorAssay?('background-color:' + colorAssay + '40 !important;'):'') +' width:100%; height:53px;"'  )) == null ? '' : __t) +
'>\n    ';
 if(displayResult) { ;
__p += '\n        ';
 if (fixedError || fixedWarning) { ;
__p += ' \n            <i class="mdi-alert-warning" style="position: absolute; color:black; font-size:10px; left:4px; top:18px;"></i>\n        ';
 } ;
__p += '\n        ';
 if (warning || error) { ;
__p += '\n            <i class="mdi-alert-warning" style="position: absolute; color:black; font-size:18px; top:18px;"></i>\n        ';
 } ;
__p += '\n    ';
 } ;
__p += '\n    <div class=" p-l-0 p-r-0 col-xs-2 h-16"></div>\n    <span class="col-xs-10 small-regular-compuzz p-l-5 p-r-0 h-16 smp-id-link"\n        style="white-space: nowrap; overflow: hidden; font-family: monospace;">' +
((__t = ( well.smpid )) == null ? '' : __t) +
'</span>\n    <div class="well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: green;"></div>\n    <span class="btn-assay col-xs-10 small-regular-compuzz p-l-2 p-r-0 h-16"\n    style="white-space: nowrap; overflow: hidden;"><span style="' +
((__t = ( (colorAssay?('background-color:' + colorAssay + ' !important;'):'') )) == null ? '' : __t) +
'">' +
((__t = ( well.refAssay?well.refAssay.code:'' )) == null ? '' : __t) +
'</span></span>\n    <div class="well-result-icons p-l-0 p-r-0 col-xs-2 h-16"></div>\n    <div class="col-xs-10 container-fluid h-16">\n        <div class="row m-b-0">\n            ';
 if (displayResult) { ;
__p += '\n                <div class="icon-result1"></div>\n                <div class="icon-result2"></div>\n                <div class="icon-result3"></div>\n                <div class="icon-result4"></div>\n                <div class="icon-result5"></div>\n                <div class="icon-result6"></div>\n            ';
 } ;
__p += '\n        </div>\n    </div>\n\n    <span class="empty-circle item-selector-circle multi-selector">\n      <i class="mdi-action-check-circle icon"></i>\n    </span>\n    ';
 if (displayResult) { ;
__p += '\n        ';
 if (runStatus === '3' && !well.expSt) { ;
__p += '\n        <span class="arrowNowExported">➔</span>\n        ';
 } ;
__p += '\n    ';
 } ;
__p += '\n\n    ';
 if (wellResColor) { ;
__p += ' \n        <div style="position:absolute; top: 34px; width: 5px; height: 18px; background-color: ' +
((__t = ( wellResColor )) == null ? '' : __t) +
';"></div>\n    ';
 } ;
__p += '\n    ';
 if(colorCtrl) { ;
__p += '\n        ';
 if (well.smptype === 'NC') { ;
__p += '\n            <i class="mdi-content-remove-circle smp-id-link" style="position: absolute; font-size:18px; left:0px;"></i>\n        ';
 } else if(well.smptype === 'PC') { ;
__p += ' \n            <i class="mdi-content-add-circle smp-id-link" style="position: absolute; font-size:18px; left:0px;"></i>\n        ';
 } ;
__p += '\n    ';
 } ;
__p += '\n    ';
 if(well.otherWellSameAssay) { ;
__p += '\n        <div class="triangle-same-assay smp-id-link" style="color: ' +
((__t = ( colorOtherWellSameAssay )) == null ? '' : __t) +
'; right: 0px; left: inherit;"></div>\n    ';
 } ;
__p += '\n    ';
 if(well.otherWellOtherAssay) { ;
__p += '\n        <div class="triangle-other-assay smp-id-link" style="color: ' +
((__t = ( colorOtherWellOtherAssay )) == null ? '' : __t) +
'; right: 0px; left: inherit;"></div>\n    ';
 } ;
__p += '\n    ';
 if (pooled) { ;
__p += ' \n        <span class="pooled-label" style="position: absolute; bottom: 0px; left: 0px;">p</span>\n    ';
 } ;
__p += '\n    ';
 if (pool) { ;
__p += ' \n        <span class="pool-label" style="position: absolute; bottom: 0px; left: 0px;">P</span>\n    ';
 } ;
__p += '\n</div>';

}
return __p
};});

