/**
 * Created by bfr on 10/04/2020.
 */
define('racksMain',[
    'module',
    'logger',
    'racksMainRouter'
], function(
    module,
    Logger
) {
    'use strict';

    var Racks = {
        start: function() {
            Logger.log(module, 'starting RacksModule');
        },

        stop: function() {
            Logger.log(module, 'stopping RacksModule');
        }
    };

    module.exports = Racks;
});
