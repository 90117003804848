define('runEditLayoutView',[
  'module',
  'dialogFormView',
  'template!runEditLayoutTpl',
  'savingBehavior',
  'underscore',
  'app',
  'settings',
  'autocompleteView',
  'entities/runs'
], function(
  module,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  _,
  App,
  Settings,
  AutocompleteView
) {
  'use strict';

  /*var selectRunTemplateRegion = Marionette.Region.extend({
    el: '.js-select-run-template-region',
    allowMissingEl: true
  });*/

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      inputName: '.run-input-name',
      poolCheckbox: '.pool-checkbox',
      afterLyse: '.after-lyse'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChangeLocal',
      'click .js-confirm': 'onConfirm'
    },

    onInputChangeLocal: function(e) {
      this.onInputChange(e);
      this.refreshDisableEnableCheckbox();
    },

    regions: {
      selectLmbAnalyzer: '.js-select-lmbanalyzer-region',
      selectKitProt: '.js-select-kitprot-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'name', type: 'required'}
    ],

    initialize: function(options) {
      this.model = options.runModel.clone();
    },

    refreshDisableEnableCheckbox: function() {
      var elem = {};
      if (this.model.get('isLYState') || this.model.get('isELState') || this.model.get('isPCState')) {
        elem.poolEnabled = false;
      } else {
        elem.poolEnabled = true;
      }

      if (this.model.get('isDWPoolPlate')) {
        elem.afterLyseEnabled = false;
      } else {
        elem.afterLyseEnabled = true;
      }
      this.ui.poolCheckbox.prop('disabled', !elem.poolEnabled);
      this.ui.afterLyse.prop('disabled', !elem.afterLyseEnabled);
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      if (templateData.xmlFileUid) {
        templateData.resultFileUrl = Settings.url('file', templateData.xmlFileUid) + '&cmds=on';
      }
      if (templateData.layoutFileUid) {
        templateData.layoutFileUrl = Settings.url('file', templateData.layoutFileUid) + '&cmds=on';
      }
      return templateData;
    },

    onShow: function() {
      $.material.init();
      this.lmbAnalyzerView = new AutocompleteView(
          this._getAutocompleteOptions('refLmbAnalyzer', 'code', 'code', 'v2/lmbanalyzers',
            'selectlmbanalyzer.placeholder', 'code')
      );
      this.getRegion('selectLmbAnalyzer').show(this.lmbAnalyzerView);

      var kitProtView = new AutocompleteView(
          this._getAutocompleteOptions('refKitProt', 'code', 'code', 'v2/kitprots/mineAndShared',
              'selectkitprot.placeholder', 'code', this.onKitProtChange)
      );
      this.getRegion('selectKitProt').show(kitProtView);

      this.triggerMethod('enable:cancel:confirm');

      this.refreshDisableEnableCheckbox();
    },

    onLmbAnalyzerchange: function(fieldName, model) {
      this.currentLmbAnalyzer = model;
    },

    onKitProtChange: function(fieldName, model) {
      this.currentKitProt = model;
      if (model) {
        model.unset('autocompleteValue', {silent: true});
      }
      this.onChange(fieldName, model);
      if (model) {
        if (!this.ui.inputName.val()) {
          this.ui.inputName.val(model.get('runNamePattern'));
          this.ui.inputName.change();
        }

        if (this.mbAModelView) {
          this.mbAModelView.destroy();
        }
        this.mbAModelView = new AutocompleteView(
          this._getAutocompleteOptions('refLmbAnalyzer', 'code', 'code', 'v2/mbamodels?kitProtSecId=' + model.get('secId'),
            'selectkitmbamodel.placeholder', 'code')
        );
        this.getRegion('selectMbAModel').show(this.mbAModelView);
      }
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.wells = this.model.get('wells');
        this.model.set('wells', null);
        this.model.set('refRunTemplate', null);
        this.model.save();
      }
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();
      this.model.set('wells', this.wells);
      this.options.runModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('run:header:rerender', modelJSON);
      App.navigate('runs/empty', {trigger: false, replace: true});
      App.navigate('runs/' + model.get('secId'), {trigger: true});
      this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
      if (App.getCurrentRoute() ===  'runs/new') {
        App.navigate('runs', { trigger: true });
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=code').addClass('has-error');
    }
  });

  module.exports = CreateEditLayoutView;
});
