define('runCreatePoolingView',[
  'module',
  'dialogFormView',
  'template!runCreatePoolingTpl',
  'savingBehavior',
  'underscore',
  'autocompleteView',
  'entities/runs'
], function(
  module,
  DialogFormView,
  createPoolingTpl,
  SavingBehavior,
  _,
  AutocompleteView
) {
  'use strict';

  var CreatePoolingView = DialogFormView.extend({
    template: createPoolingTpl,

    fromWell: 'A01',
    toWell: 'H12',

    ui: {
      input: '.js-info-input',
      selectRun1: '.js-select-run-1-region',
      selectRun2: '.js-select-run-2-region',
      selectRun3: '.js-select-run-3-region',
      selectRun4: '.js-select-run-4-region',
      selectRun5: '.js-select-run-5-region',
      fromWell: '.run-from-well',
      toWell: '.run-to-well'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'click .js-confirm': 'onConfirm',
      'change @ui.fromWell': 'onFromWellChange',
      'change @ui.toWell': 'onToWellChange'
    },

    regions: {
      selectRun1: '.js-select-run-1-region',
      selectRun2: '.js-select-run-2-region',
      selectRun3: '.js-select-run-3-region',
      selectRun4: '.js-select-run-4-region',
      selectRun5: '.js-select-run-5-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
    ],

    onFromWellChange: function() {
      this.fromWell = this.ui.fromWell.val();
    },

    onToWellChange: function() {
      this.toWell = this.ui.toWell.val();
    },

    onShow: function() {
      $.material.init();
      var run1View = new AutocompleteView(
        this._getAutocompleteOptions('run1', 'name', 'name', 'v2/runs?isDWPoolPlate=true',
            'selectrun.placeholder', 'name', this.onRunChange)
      );
      this.getRegion('selectRun1').options.allowMissingEl = true;
      this.getRegion('selectRun1').show(run1View);

      var run2View = new AutocompleteView(
        this._getAutocompleteOptions('run2', 'name', 'name', 'v2/runs?isDWPoolPlate=true',
            'selectrun.placeholder', 'name', this.onRunChange)
      );
      this.getRegion('selectRun2').options.allowMissingEl = true;
      this.getRegion('selectRun2').show(run2View);

      var run3View = new AutocompleteView(
        this._getAutocompleteOptions('run3', 'name', 'name', 'v2/runs?isDWPoolPlate=true',
            'selectrun.placeholder', 'name', this.onRunChange)
      );
      this.getRegion('selectRun3').options.allowMissingEl = true;
      this.getRegion('selectRun3').show(run3View);

      var run4View = new AutocompleteView(
        this._getAutocompleteOptions('run4', 'name', 'name', 'v2/runs?isDWPoolPlate=true',
            'selectrun.placeholder', 'name', this.onRunChange)
      );
      this.getRegion('selectRun4').options.allowMissingEl = true;
      this.getRegion('selectRun4').show(run4View);

      var run5View = new AutocompleteView(
        this._getAutocompleteOptions('run5', 'name', 'name', 'v2/runs?isDWPoolPlate=true',
            'selectrun.placeholder', 'name', this.onRunChange)
      );
      this.getRegion('selectRun5').options.allowMissingEl = true;
      this.getRegion('selectRun5').show(run5View);

      this.triggerMethod('enable:cancel:confirm');
    },

    onRunChange: function(fieldName, model) {
      this[fieldName] = model;
    },

    onConfirm: function() {
      var runs = [];
      for (var i = 1 ; i <=5 ; i++) {
        if (this['run'+i]) {
          runs.push(this['run'+i].get('secId'));          
        }
      }
      this.trigger('run:create-pooling', this.fromWell, this.toWell, runs);
      this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
    }
  });

  module.exports = CreatePoolingView;
});
