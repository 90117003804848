
define('template!wellTemplateTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div style="' +
((__t = ( backgroundColor?'background-color:' + backgroundColor + '; ':'' )) == null ? '' : __t) +
'width:100%; height:53px;">\n    <div class="p-l-15">\n        <span class="col-xs-12 small-regular-compuzz p-l-2 p-r-0">' +
((__t = ( well.smpid )) == null ? '' : __t) +
'</span>\n        <span class="col-xs-12 small-regular-compuzz p-l-2 p-r-0">' +
((__t = ( well.refAssay?well.refAssay.code:'' )) == null ? '' : __t) +
'</span>\n    </div>\n    ';
 if (well.smptype === 'NC') { ;
__p += '\n        <i class="mdi-content-remove-circle smp-id-link" style="position: absolute; font-size:18px; left:0px;"></i>\n    ';
 } else if(well.smptype === 'PC') { ;
__p += ' \n        <i class="mdi-content-add-circle smp-id-link" style="position: absolute; font-size:18px; left:0px;"></i>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

