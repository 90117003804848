define('runTemplateTabsLayoutView',[
  'module',
  'tabsBaseView',
  'template!runTemplateTabsHeaderTpl',
  'template!runTemplateTabsTpl',
  'jquery',
  'underscore',
  'app',
  'backToListBehavior',
  'marionette',
  'settings',
  'runTemplateCreateEditLayoutView'
], function(
  module,
  TabsBaseView,
  headerTpl,
  runTemplateTabsTpl,
  $,
  _,
  App,
  BackToListBehavior,
  Marionette,
  Settings,
  CreateEditLayoutView
) {
  'use strict';

  var HeaderView = Marionette.LayoutView.extend({
    template: headerTpl
  });

  var RunTemplateTabsLayoutView = Marionette.LayoutView.extend({

    template: runTemplateTabsTpl,
    tagName: 'div',

    ui: {
        logo: '.js-logo-holder',
        tabs: '.js-tab-layout-tab',
        tabsRegion: '.js-tabs-region',
        header: '.js-tab-layout-header'
    },

    events: {
        'click .js-tab-layout-tab': '_navigate',
        'click .btn-edit-run': 'onEditRun'
    },

    regions: {
        tabs: '.js-tabs-region',
        header: '.js-header-region'
    },

    behaviors: function() {
      this.backToListUrl = 'runtemplates';
      return {
        BackToList: {
          behaviorClass: BackToListBehavior,
          redirectUrl: this.backToListUrl
        }
      };
    },

    onEditRun: function() {
      var createEditView = new CreateEditLayoutView({
        runTemplateModel: this.model
      });
      createEditView.show({
        title: _.i18n('runtemplate.edit')
      });
    },

    onShowTabsBase: function() {
      this.getRegion('header').show(new HeaderView({
        model: this.model
      }));
    },

    onNavigate: function(tabName) {
      var currentRoute = App.getCurrentRoute(),
        routeSplitted = currentRoute.split('/'), newRoute = [];

      newRoute.push(routeSplitted[0]);
      newRoute.push(routeSplitted[1]);
      newRoute.push(tabName);
      App.navigate(newRoute.join('/'), {trigger: true});
    },

      serializeData: function() {
          var templateData = this.model.toJSON();
          templateData.tabs = [];
          _.each(this.options.tabs, function(tab) {
            if (!tab.role || Settings.get('cAccountRoles')[tab.role]) {
              templateData.tabs.push(tab);
            }
          });
          templateData.analyser = '';
          if (templateData.refLmbAnalyzer) {
              templateData.analyser = templateData.refLmbAnalyzer.code;
          }
          return templateData;
      },

      onShow: function() {
          this.ui.logo.addClass('default-upload-image');
          this._setHeight();
          $(window).on('resize', _.bind(this._setHeight, this));
          this.triggerMethod('show:tabs:base');
          if (this.options.edit) {
            this.onEditRun();
          }
      },

      onShowActive: function(tabName) {
          this.ui.tabs.each(function(index, tab) {
              var $tab = $(tab);
              if($tab.hasClass('js-' + tabName)) {
                  $tab.addClass('active');
              } else {
                  $tab.removeClass('active');
              }
          });
      },

      onDestroy: function() {
        $(window).off('resize');
      },

      _navigate: function(e) {
          var $target = $(e.currentTarget);
          if($target.hasClass('active')) {
              return;
          }
          this.triggerMethod('navigate', $target.data('tab-name'));
      },

      _setHeight: _.throttle(function() {
          var windowHeight = $(window).height(),
              headerHeight = $('header').height() || 75,
              detailsHeaderHeight = this.ui.header.outerHeight(),
              height = windowHeight - headerHeight - detailsHeaderHeight - 40;

          this.ui.tabsRegion.height(height);
      }, 500)
  });

  module.exports = RunTemplateTabsLayoutView;
});
