/**
 * Created by RKL on 21/08/2015.
 */
define('runTemplatesShowController',[
  'module',
  'logger',
  'underscore',
  'app',
  'runTemplatesShowView',
  'jquery',
  'bootbox',
  'emptyView',
  'runTemplateCreateEditLayoutView',
  'entities/runtemplates'
], function(
  module,
  Logger,
  _,
  App,
  View,
  $,
  bootbox,
  EmptyView,
  CreateEditLayoutView
) {
  'use strict';

  var RunTemplatesController = {
    showRuns: function() {
      var view = new View();

      this.view = view;

      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      view.listenTo(view, 'onLoadSelectedRunTemplates', _.bind(this.onLoadSelectedRuns, this));
      view.listenTo(view, 'runtemplate:delete', _.bind(this.onDelete, this));
      App.regions.getRegion('main').currentView.getRegion('content').show(view);
    },

    showNewRun: function() {
      this.createEditView = new CreateEditLayoutView({
        runTemplateModel: App.request('runtemplate:model'),
        newItem: true
      });
      this.createEditView.show({
        title: _.i18n('runtemplate.new')
      });
    },

    onGridLoaded: function() {
      var that = this, data;

      data = {
        url: App.request('runtemplate:get-collection-url'),
        rowCountUrl: App.request('runtemplate:get-count-url')
      };

      that.view.displayGrid(data);
    },

    onLoadSelectedRuns: function(first, last, params) {
      $.when(App.request('runtemplate:get-collection-to-page', params))
          .done(_.bind(function(runs) {
            this.view.triggerMethod('selectRunTemplates', first, last, runs);
            $('#content-blinder').hide();
          }, this))
          .fail(function() {
            $('#content-blinder').hide();
          });
    },

    onDelete: function(secId){ 
      bootbox.confirm(_.i18n('runtemplate.confirmDelete'), _.bind(function(result) {
        if(result){
          App.request('runtemplate:delete', secId)
              .done(_.bind(function(){
                this.view.updatePagination('reloadGrid');
              }, this));
        }
    }, this));
    }
  };

  module.exports = RunTemplatesController;
});
