define('runTabsData',[
  'module',
  'underscore',
  'rolesMixin'
], function(
  module,
  _,
  RolesMixin
) {
  'use strict';

  module.exports = [
    {
      name: 'table',
      title: _.i18n('runs.table.tabTitle')
    },
    {
      name: 'details',
      title: _.i18n('runs.details.tabTitle')
    },
    {
      name: 'assays-targets-2',
      title: _.i18n('runs.assays.targets.tabTitle'),
      displayOnPooled: false
    },
    {
      name: 'results',
      title: _.i18n('runs.results.tabTitle'),
      displayOnPooled: false
    },
    {
      name: 'tracs',
      title: _.i18n('runs.tracs.tabTitle'),
      role : RolesMixin.DASHBOARD_RUNS_RUNTRACS
    }
  ];
});
