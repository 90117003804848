define('entities/tracs',[
  'app',
  'backbone',
  'settings'
], function(
  App,
  Backbone,
  Settings
) {
  'use strict';

  var Trac = Backbone.Model.extend({
    fetch: function() {
      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      
    },
    idAttribute: 'secId'
  });

  var TracCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/runs');
    },
    model: Trac
  });

  var API = {
    getWellCollectionUrl: function(runSecId) {
      new TracCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/runs/' + runSecId + '/runtracs', params);
    },

    getWellsCountUrl: function(runSecId) {
      var params = '?';
      return 'v2/runs/'+runSecId+'/runtracs/rowCount' + params;
    }
  };

  App.reqres.setHandler('trac:get-collection-url', function(runSecId) {
      return API.getWellCollectionUrl(runSecId);
  });

  App.reqres.setHandler('trac:get-collection', function(runSecId) {
      var colUrl = API.getWellCollectionUrl(runSecId);
      var Col = new TracCollection();
      Col.url = colUrl;
      return Col;
  });

  App.reqres.setHandler('trac:get-count-url', function(runSecId) {
    return API.getWellsCountUrl(runSecId);
  });

  App.reqres.setHandler('trac:get-collection-result-url', function(runSecId) {
    return API.getWellResultCollectionUrl(runSecId);
  });

  App.reqres.setHandler('trac:model', function(runJSON) {
    return new Trac(runJSON);
  });
});
