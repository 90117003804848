define('runTableTabView',[
    'module',
    'marionette',
    'template!runTableTabTpl',
    'underscore',
    'settings',
    'app',
    'envHandler',
    'backbone',
    'dateUtils',
    'wellView',
    'autocompleteView',
    'template!wellTooltipTpl',
    'runCreateTemplateLayoutView',
    'runSelectTemplateLayoutView',
    'runCreatePoolingView',
    'entities/wells'
], function(
    module,
    Marionette,
    runTableTabTpl,
    _,
    Settings,
    App,
    EnvironmentHandler,
    Backbone,
    DateUtil,
    WellView,
    AutocompleteView,
    wellTooltipTpl,
    CreateTemplateLayoutView,
    SelectTemplateView,
    CreatePoolingView
) {
    'use strict';

    var TableTabView = Marionette.LayoutView.extend({
        template: runTableTabTpl,

        selectedWells: [],

        triggers: {
            'click .fill-smpids': 'run:edit-smpids'
        },

        ui: {
            lineTitle: '.wellTableLineTitleItem',
            columnTitle: '.wellTableColumnTitleItem',
            importXmlBtn: '.js-update-runs',
            importLayoutBtn: '.js-update-layout-runs',
            searchWellBtn: '.search-well-button',
            selectResult: '.select-result',
            selectSmpId: '.select-smpid',
            refreshBtn: '.refresh-button',
            newRunTemplateBtn: '.new-run-template-button',
            print: '.js-print'
        },

        events: {
            'click @ui.lineTitle': 'onLineTitleClick',
            'click @ui.columnTitle': 'onColumnTitleClick',
            'change @ui.importXmlBtn': 'onImportXmlClick',
            'change @ui.importLayoutBtn': 'onImportLayoutClick',
            'click @ui.searchWellBtn': 'onSearchWellClick',
            'change @ui.selectResult': 'onResultChange',
            'change @ui.selectSmpId': 'onSmpIdChange',
            'click @ui.refreshBtn': 'refreshBtnClick',
            'click @ui.newRunTemplateBtn': 'newRunTemplate',
            'click .download-report-run-button': 'onDownloadReportClick',
            'click @ui.print': 'onPrint',
            'click .select-run-button': 'onSelectClick',
            'click .auto-validation-run-button': 'onAutoValidationClick',
            'click .download-export-run-button': 'onDownloadExportClick',
            'click .download-export-repeat-run-button': 'onDownloadExportRepeatClick',
            'click .download-re-export-repeat-run-button': 'onDownloadReExportRepeatClick',
            'click .refresh-wells-button': 'refreshWellsBtnClick',
            'click .emergency-export-run-button': 'onDownloadEmergencyExportClick',
            'click .re-export-run-button': 'onDownloadReExportClick',
            'click .re-export-import-file-run-button': 'onDownloadImportFile', 
            'click .select-warn-err-run-button': 'onSelectWarnErrorClick',
            'click .select-expert-run-button': 'onSelectExpertClick',
            'click .select-to-repeat-run-button': 'onSelectToRepeatClick',
            'click .select-to-repeat-other-run-button': '   ',
            'click .select-val1-button': 'onSelectVal1Click',
            'click .select-val2-button': 'onSelectVal2Click',
            'click .import-layout-from-tpl-button': 'onImportLayoutFromTplClick',
            'click .create-pooling-button': 'onCreatePoolingClick',
            'click .plate-layout-export-run-button': 'onPlateLayoutExportClick',
            'click .download-export-pool-disolve-run-button': 'onExportPoolDisolbeClick'
        },

        regions: {
            assaySearch: '.js-select-assay-region',
            mbAnaSearch: '.js-select-mbana-region',
            resultSearch: '.js-select-result-region',
            tblA01: '.tbl-A01',
            tblA02: '.tbl-A02',
            tblA03: '.tbl-A03',
            tblA04: '.tbl-A04',
            tblA05: '.tbl-A05',
            tblA06: '.tbl-A06',
            tblA07: '.tbl-A07',
            tblA08: '.tbl-A08',
            tblA09: '.tbl-A09',
            tblA10: '.tbl-A10',
            tblA11: '.tbl-A11',
            tblA12: '.tbl-A12',
            tblB01: '.tbl-B01',
            tblB02: '.tbl-B02',
            tblB03: '.tbl-B03',
            tblB04: '.tbl-B04',
            tblB05: '.tbl-B05',
            tblB06: '.tbl-B06',
            tblB07: '.tbl-B07',
            tblB08: '.tbl-B08',
            tblB09: '.tbl-B09',
            tblB10: '.tbl-B10',
            tblB11: '.tbl-B11',
            tblB12: '.tbl-B12',
            tblC01: '.tbl-C01',
            tblC02: '.tbl-C02',
            tblC03: '.tbl-C03',
            tblC04: '.tbl-C04',
            tblC05: '.tbl-C05',
            tblC06: '.tbl-C06',
            tblC07: '.tbl-C07',
            tblC08: '.tbl-C08',
            tblC09: '.tbl-C09',
            tblC10: '.tbl-C10',
            tblC11: '.tbl-C11',
            tblC12: '.tbl-C12',
            tblD01: '.tbl-D01',
            tblD02: '.tbl-D02',
            tblD03: '.tbl-D03',
            tblD04: '.tbl-D04',
            tblD05: '.tbl-D05',
            tblD06: '.tbl-D06',
            tblD07: '.tbl-D07',
            tblD08: '.tbl-D08',
            tblD09: '.tbl-D09',
            tblD10: '.tbl-D10',
            tblD11: '.tbl-D11',
            tblD12: '.tbl-D12',
            tblE01: '.tbl-E01',
            tblE02: '.tbl-E02',
            tblE03: '.tbl-E03',
            tblE04: '.tbl-E04',
            tblE05: '.tbl-E05',
            tblE06: '.tbl-E06',
            tblE07: '.tbl-E07',
            tblE08: '.tbl-E08',
            tblE09: '.tbl-E09',
            tblE10: '.tbl-E10',
            tblE11: '.tbl-E11',
            tblE12: '.tbl-E12',
            tblF01: '.tbl-F01',
            tblF02: '.tbl-F02',
            tblF03: '.tbl-F03',
            tblF04: '.tbl-F04',
            tblF05: '.tbl-F05',
            tblF06: '.tbl-F06',
            tblF07: '.tbl-F07',
            tblF08: '.tbl-F08',
            tblF09: '.tbl-F09',
            tblF10: '.tbl-F10',
            tblF11: '.tbl-F11',
            tblF12: '.tbl-F12',
            tblG01: '.tbl-G01',
            tblG02: '.tbl-G02',
            tblG03: '.tbl-G03',
            tblG04: '.tbl-G04',
            tblG05: '.tbl-G05',
            tblG06: '.tbl-G06',
            tblG07: '.tbl-G07',
            tblG08: '.tbl-G08',
            tblG09: '.tbl-G09',
            tblG10: '.tbl-G10',
            tblG11: '.tbl-G11',
            tblG12: '.tbl-G12',
            tblH01: '.tbl-H01',
            tblH02: '.tbl-H02',
            tblH03: '.tbl-H03',
            tblH04: '.tbl-H04',
            tblH05: '.tbl-H05',
            tblH06: '.tbl-H06',
            tblH07: '.tbl-H07',
            tblH08: '.tbl-H08',
            tblH09: '.tbl-H09',
            tblH10: '.tbl-H10',
            tblH11: '.tbl-H11',
            tblH12: '.tbl-H12'
        },

        onCreatePoolingClick: function() {
            this.createPooling = new CreatePoolingView({
                runModel: this.model
            });
            this.createPooling.listenTo(this.createPooling, 'run:create-pooling', _.bind(function(fromWell, toWell, runs) {
                this.trigger('run:create-pooling', fromWell, toWell, runs);
            }, this));
            this.createPooling.show({
                title: _.i18n('run.createPooling')
            });
        },

        onImportLayoutFromTplClick: function() {
            this.selectTemplate = new SelectTemplateView({
                runModel: this.model
            });
            this.selectTemplate.listenTo(this.selectTemplate, 'run:template-selected', _.bind(function() {
                this.trigger('run:import-layout-from-template', this.selectTemplate.currentTemplate);
            }, this));
            this.selectTemplate.show({
                title: _.i18n('run.selectTemplate')
            });
        },

        onAutoValidationClick: function() {
            this.trigger('run:auto-validation');
        },

        onDownloadReportClick: function() {
            window.location = Settings.url('compuzz', 'v2/runs/' + this.model.get('secId') + '/report');
        },

        onDownloadExportClick: function() {
            window.location = Settings.url('compuzz', 'v2/runs/' + this.model.get('secId') + '/results/export');
        },

        onDownloadExportRepeatClick: function() {
            window.location = Settings.url('compuzz', 'v2/runs/' + this.model.get('secId') + '/results/export', { 
                onlyre: true 
            });
        },

        onDownloadReExportRepeatClick: function() {
            window.location = Settings.url('compuzz', 'v2/runs/' + this.model.get('secId') + '/results/export', { 
                onlyre: true, 
                reexport: true 
            });
        },

        onDownloadEmergencyExportClick: function() {
            window.location = Settings.url('compuzz', 'v2/runs/' + this.model.get('secId') + '/results/export', {
                emergency: true,
                wellPos: this.selectedWells.join(',')
            });
        },

        onDownloadReExportClick: function() {
            window.location = Settings.url('compuzz', 'v2/runs/' + this.model.get('secId') + '/results/export', {
                reexport: true
            });
        },

        onExportPoolDisolbeClick: function() {
            window.location = Settings.url('compuzz', 'v2/runs/' + this.model.get('secId') + '/results/export', {
                poolre: true
            });
        },

        onPlateLayoutExportClick: function() {
            window.location = Settings.url('compuzz', 'v2/runs/' + this.model.get('secId') + '/exportLayout');
        },

        onDownloadImportFile: function() {
            window.location = Settings.url('file', this.model.get('xmlFileUid'), {
                cmds: 'on'
            });
        },

        onSelectVal1Click: function() {
            var val1Wells = [];
            _.each(this.model.get('wells'), function(well) {
                var add = false;
                for (var i = 1 ; i <= 6 ; i++) {
                    if (well['val' + i + 'St'] === 2.1 || well['val' + i + 'St'] === 2.2) {
                        add = true;
                        break;
                    }
                }
                if (add) {
                    val1Wells.push(well.pos);
                }
            });
            if (val1Wells && val1Wells.length) {
                var arj = val1Wells.join('-');
                App.navigate('/runs/' + this.model.get('secId') + '/details/select/' + arj, {trigger: true});
            } else {
                alert(_.i18n('error.noVal1'));
            }
        },

        onSelectVal2Click: function() {
            var val2Wells = [];
            _.each(this.model.get('wells'), function(well) {
                var add = false;
                for (var i = 1 ; i <= 6 ; i++) {
                    if (well['val' + i + 'St'] === 3.2) {
                        add = true;
                        break;
                    }
                }
                if (add) {
                    val2Wells.push(well.pos);
                }
            });
            if (val2Wells && val2Wells.length) {
                var arj = val2Wells.join('-');
                App.navigate('/runs/' + this.model.get('secId') + '/details/select/' + arj, {trigger: true});
            } else {
                alert(_.i18n('error.noVal2'));
            }
        },

        onSelectWarnErrorClick: function() {
            var warnErrWells = [];
            _.each(this.model.get('wells'), function(well) {
                if (well.res1CodeErr || well.res2CodeErr || well.res3CodeErr ||
                    well.res4CodeErr || well.res5CodeErr || well.res6CodeErr) {
                    warnErrWells.push(well.pos);
                }
            });
            if (warnErrWells && warnErrWells.length) {
                var arj = warnErrWells.join('-');
                App.navigate('/runs/' + this.model.get('secId') + '/details/select/' + arj, {trigger: true});
            } else {
                alert(_.i18n('error.noErrorWarning'));
            }
        },

        onSelectExpertClick: function() {
            var expertWells = [];
            _.each(this.model.get('wells'), function(well) {
                if (well.val1St === 3.1 || well.val2St === 3.1 || well.val3St === 3.1 ||
                    well.val4St === 3.1 || well.val5St === 3.1 || well.val6St === 3.1) {
                    expertWells.push(well.pos);
                }
            });
            if (expertWells && expertWells.length) {
                var arj = expertWells.join('-');
                App.navigate('/runs/' + this.model.get('secId') + '/details/select/' + arj, {trigger: true});
            } else {
                alert(_.i18n('error.noExpertValidation'));
            }
        },

        onSelectToRepeatClick: function() {
            var expertWells = [];
            _.each(this.model.get('wells'), function(well) {
                if (well.repeat1St || well.repeat2St || well.repeat3St ||
                    well.repeat4St || well.repeat5St || well.repeat6St) {
                    expertWells.push(well.pos);
                }
            });
            if (expertWells && expertWells.length) {
                var arj = expertWells.join('-');
                App.navigate('/runs/' + this.model.get('secId') + '/details/select/' + arj, {trigger: true});
            } else {
                alert(_.i18n('error.noExpertValidation'));
            }
        },

        onSelectToRepeatOtherClick: function() {
            var expertWells = [];
            _.each(this.model.get('wells'), function(well) {
                if ((well.otherWellOtherAssay || well.otherWellSameAssay) && 
                        (well.repeat1St || well.repeat2St || well.repeat3St ||
                        well.repeat4St || well.repeat5St || well.repeat6St)) {
                    expertWells.push(well.pos);
                }
            });
            if (expertWells && expertWells.length) {
                var arj = expertWells.join('-');
                App.navigate('/runs/' + this.model.get('secId') + '/details/select/' + arj, {trigger: true});
            } else {
                alert(_.i18n('error.noExpertValidation'));
            }
        },

        newRunTemplate: function() {
            this.createTemplate = new CreateTemplateLayoutView({
                runModel: this.model,
                newItem: true
            });
            this.createTemplate.show({
                title: _.i18n('run.newTemplate')
            });
        },

        refreshWellsBtnClick: function() {
            var wells = { lst: [] };
            _.each(this.selectedWells, _.bind(function (pos) {
                var w = _.findWhere(this.model.get('wells'), { pos: pos });
                wells.lst.push(w.secId);
            }, this));
            this.trigger('run:refreshWells', wells);
        },

        refreshBtnClick: function() {
            this.trigger('run:refresh');
        },

        onImportXmlClick: function(e) {
            e.preventDefault();
            e.stopPropagation();
            if (e && e.target && e.target.files && e.target.files[0]) {
                this.uploadXml(e.target.files[0], this.ui.importXmlBtn);
            }
        },

        onImportLayoutClick: function(e) {
            e.preventDefault();
            e.stopPropagation();
            if (e && e.target && e.target.files && e.target.files[0]) {
                this.uploadXml(e.target.files[0], this.ui.importLayoutBtn);
            }
        },

        uploadXml: function(fileInfo, ui) {
            ui.val('');
            this.trigger('run:importXml', fileInfo);
        },

        onLineTitleClick: function(event) {
            this.trigger('well:filteredDetailRow', event.target.textContent);
        },

        onColumnTitleClick: function(event) {
            this.trigger('well:filteredDetailColumn', event.target.textContent);
        },

        serializeData: function() {
            var templateData = this.model.toJSON();
            templateData.createDate = DateUtil.toMDYHHMMFormat(this.model.get('createDate'));

            if (this.model.get('settings') && this.model.get('settings')['REM-ERR-WARN-IC'] === '1') {
                templateData.displayExportRepeat = true; 
            } else {
                templateData.displayExportRepeat = false;
            }

            return templateData;
        },

        onShow: function() {
            this.showWell();

            this.selectedWells = [];

            var assayView = new AutocompleteView(
                this._getKitAutocompleteOptions('assaySecId', 'v2/assays', 'code', 'selectassay.placeholder')
            );
            this.getRegion('assaySearch').show(assayView);

            var mbAnaView = new AutocompleteView(
                this._getKitAutocompleteOptions('mbAnaSecId', 'v2/mbanas', 'code', 'selectmbana.placeholder')
            );
            this.getRegion('mbAnaSearch').show(mbAnaView);
        },

        showImageZoomPopover: function(well, target) {
            if ((well.get('smpid') || well.get('smptype')) && well.get('refAssay')) {
              var templateData = well.toJSON();
              templateData.results = this.model.getResultForWell(well);
              templateData.smpIdFormated = this.model.getFormatedSmpId(templateData);

              templateData.poolSmpIds = '';
              if (templateData.wells && templateData.wells.length > 0) {
                _.each(templateData.wells, function(w) {
                    templateData.poolSmpIds += w.smpid + ',';
                });
                if (templateData.poolSmpIds.indexOf(',') !== -1) {
                    templateData.poolSmpIds = templateData.poolSmpIds.substring(0, templateData.poolSmpIds.length - 1);
                }
              }

              templateData = _.extend(templateData, well.getErrorObject());

              var content = Marionette.Renderer.render(wellTooltipTpl, templateData);

              target.attr('data-content', content);
              target.popover({
                trigger: 'hover',
                placement: 'top',
                html: 'true',
                delay: {
                  show: '500',
                  hide: '100'
                },
                container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content')
              }).data('bs.popover').tip().addClass('jqgrid-popover');
            }
        },
        

        showWell: function() {
            this.model.sortWell();
            var firstOfAssay, lastAssay, assayIndex = 0;
            _.each(this.model.get('wells'), _.bind(function(well) {
                if (!lastAssay || (well.refAssay && well.refAssay.code && well.refAssay.code !== lastAssay.code)) {
                    firstOfAssay = true;
                    assayIndex++;
                } else {
                    firstOfAssay = false;
                }
                if (well.refAssay) {
                    lastAssay = well.refAssay;
                }

                var wellView = new WellView({
                    model: well,
                    modelRun: this.model,
                    firstOfAssay: firstOfAssay,
                    assayIndex: assayIndex
                });
                wellView.listenTo(wellView, 'well:filteredDetailAssay', _.bind(this.filteredDetailAssay, this));
                wellView.listenTo(wellView, 'well:filteredDetailItem', _.bind(this.filteredDetailItem, this));
                wellView.listenTo(wellView, 'well:showSampleIds', _.bind(this.showSampleIds, this));
                wellView.listenTo(wellView, 'well:showSampleIdsQc', _.bind(this.showSampleIdsQc, this));
                wellView.listenTo(wellView, 'well:selectedWell', _.bind(this.onWellSelected, this));
                this.getRegion('tbl' + well.pos).show(wellView);
                var wellM = App.request('well:model', well);
                this.showImageZoomPopover(wellM, this.$('.tbl-' + well.pos));
            }, this));
        },

        onPrint: function() {
            var $print = $('.js-print-table')
                .clone()
                .addClass('print')
                .prependTo('#print-region');

            $('#print-region').find('.js-kitl-table-details-for-printing-container').remove();
            $('.js-kitl-table-details-for-printing-container').clone().prependTo('#print-region');
            $('html,body').css('overflow', 'visible');
            $('#main-region').hide();
            //window.print() stops JS execution
            window.print();

            //Remove div once printed
            $print.remove();
            $('html,body').css('overflow', 'hidden');
            $('#main-region').show();
        },

        onWellSelected: function(pos, select) {
            if (select) {
                this.selectedWells.push(pos);
            } else {
                var index = this.selectedWells.indexOf(pos);
                if (index > -1) {
                    this.selectedWells.splice(index, 1);
                }
            }
        },

        onSelectClick: function() {
            if (this.selectedWells) {
                var arj = this.selectedWells.join('-');
                App.navigate('/runs/' + this.model.get('secId') + '/details/select/' + arj, {trigger: true});
            }
        },

        _getKitAutocompleteOptions: function(property, service, valueKey, label) {
            return {
                type: 'remote',
                remote: Settings.url('compuzz', service),
                valueKey: valueKey,
                apiKey: 'secId',
                placeholder: _.i18n(label),
                value: null,
                name: property,
                onPressKey: true,
                lazyLoad: true,
                keys: {
                    query: 'code',
                    limit: 'pageSize'
                },
                callback: _.bind(this.onChange, this)
            };
        },

        onSearchWellClick: function() {
            App.navigate('/runs/' + this.model.get('secId') + '/details/filters/' + JSON.stringify(this.filters), {trigger: true});
        },

        onResultChange: function(e) {
            var $target = $(e.currentTarget),
                value = $target.val();

            if (!this.filters) {
                this.filters = {};
            }
            this.filters.result = value;
        },

        onSmpIdChange: function(e) {
            var $target = $(e.currentTarget),
                value = $target.val();

            if (!this.filters) {
                this.filters = {};
            }
            this.filters.smpid = value;
        },

        onChange: function(fieldName, model) {
            if (!this.filters) {
                this.filters = {};
            }
            this.filters[fieldName] = model.get('secId');
        },

        filteredDetailItem:function (well) {
            this.trigger('well:filteredDetailItem', well);
        },

        filteredDetailAssay:function (assaySecId) {
            this.trigger('well:filteredDetailAssay', assaySecId);
        },

        showSampleIds:function (sampleId, model) {
            this.trigger('well:showSampleIds', sampleId, model);
        },

        showSampleIdsQc:function (sampleId, model) {
            this.trigger('well:showSampleIdsQc', sampleId, model);
        }
    });

    module.exports = TableTabView;
});
