define('wellEditSmpidView',[
    'module',
    'marionette',
    'template!wellEditSmpidTpl',
    'underscore',
    'wellEditSmpIdView'
], function(
    module,
    Marionette,
    wellTpl,
    _,
    CreateEditSmpIdLayoutView
) {
    'use strict';

    var WellView = Marionette.LayoutView.extend({

        editMode: true, 

        ui: {
            elementRight: '.elementRight',
            inputSmpid: '.js-input-smpid'
        },

        regions: {
        },

        events: {
            'change @ui.inputSmpid': 'onInputSmpIdChange',
            'dblclick': 'onEditWellsSmpId'
        },

        getTemplate: function() {
            return wellTpl;
        },

        onShow: function() {
        },

        serializeData: function() {
          var templateData = {
            well: _.clone(this.model.toJSON())
          };
          if (!this.editMode && templateData.well.smpid) {
            templateData.well.smpid = this.options.modelRun.getFormatedSmpId(this.model);
          }
          templateData.colorCtrl = null;
          switch (templateData.well.smptype) {
              case 'NC': templateData.colorCtrl = true; break;
              case 'PC': templateData.colorCtrl = true; break;
              case 'C': templateData.colorCtrl = true; break;
          }
          templateData.editMode = this.options.editMode;
          templateData.tabIndex = this.options.tabIndex;

          templateData.backgroundColor = null;

          if (templateData.well.smpid && this.options.modelRun.get('settings')['P-LAYOUT-COL']) {
            var col = Number(this.model.get('pos').substring(1, 3)) - 1;
            templateData.backgroundColor = this.options.modelRun.get('settings')['P-LAYOUT-COL'].split(';')[col];
          }

          return templateData;
        },

        onEditWellsSmpId:function() {
            this.createEditSmpIdView = new CreateEditSmpIdLayoutView({
                models: [this.model],
                newItem: false
            });
            this.createEditSmpIdView.show({
                title: _.i18n('well.edit'),
                saved: this.render
            });

            this.createEditSmpIdView.listenTo(this.createEditSmpIdView, 'popup:hide', _.bind(this.mofi, this));
        },

        mofi: function() {
            this.model.set('smptype', this.createEditSmpIdView.models[0].get('smptype'));
            this.model.set('smpid', this.createEditSmpIdView.models[0].get('smpid'));
            this.render();
        },

        onInputSmpIdChange: function(e) {
            console.log('change');
            this.model.set('smpid', this.$el.find(e.target).val());
            $(e.target).next('.js-input-smpid').focus();
            this.model.set('amplCurve1', null);
            this.model.set('amplCurve2', null);
            this.model.set('amplCurve3', null);
            this.model.set('amplCurve4', null);
            this.model.set('amplCurve5', null);
            this.model.set('amplCurve6', null);
            this.model.set('meltCurve1', null);
            this.model.set('meltCurve2', null);
            this.model.set('meltCurve3', null);
            this.model.set('meltCurve4', null);
            this.model.set('meltCurve5', null);
            this.model.set('meltCurve6', null);
            this.model.save().then(_.bind(function() {
                _.each(this.options.modelRun.get('wells'), _.bind(function(well) {
                    if (well.pos === this.model.get('pos')) {
                        well.smpid = this.model.get('smpid');
                    }
                }, this));
                this.render();
            }, this));
        }
    });

    module.exports = WellView;
});
