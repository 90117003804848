define('tracsTabController',[
    'module',
    'app',
    'underscore',
    'backbone',
    'tracsView',
    'entities/tracs'
], function(
    module,
    App,
    _,
    Backbone,
    TracsView
) {
    'use strict';

    var TracsController = {

        onShowTracsTable: function(region, runModel) {
            this.model = runModel;
            this.region = region;
            this.view = new TracsView({
                tabName: this.tabName
            });
            this.view.model = this.model;
            this.view.listenTo(this.view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            region.show(this.view);
        },

        onGridLoaded: function() {
          var that = this, data;
          data = {
            url: App.request('trac:get-collection-url', this.model.get('secId')),
            rowCountUrl: App.request('trac:get-count-url', this.model.get('secId'))
          };
          that.view.displayGrid(data);
        }
    };

    module.exports = TracsController;
});
