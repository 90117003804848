
define('template!headerWellTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="img-responsive">\n    <div class="row m-b-0" style="background-color: #e6e6e6">\n        <div class="col-xs-1 p-l-0 p-r-0">\n            ' +
((__t = ( pos )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-2 p-l-0 p-r-0">\n            ';
 if (error) { ;
__p += '\n            <div class="mdi-alert-error well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: red;"></div>\n            ';
 } else if (warning) { ;
__p += '\n            <div class="mdi-alert-warning well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: orange;"></div>\n            ';
 } else if (ok) { ;
__p += '\n            <div class="mdi-action-done well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: green;"></div>\n            ';
 } ;
__p += '\n        </div>\n        <div class="col-xs-2 p-l-0 p-r-0">\n            ' +
((__t = ( refAssay?refAssay.code:'' )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-3 p-l-0 p-r-0" style="white-space: nowrap;">\n            ';
 if (pooled) { ;
__p += ' \n                <span class="pooled-label">p</span>\n            ';
 } ;
__p += '\n            ';
 if (pool) { ;
__p += ' \n                <span class="pool-label">P</span>\n            ';
 } ;
__p += '\n            ' +
((__t = ( smpid )) == null ? '' : __t) +
'\n            ';
 if(otherWellSameAssay) { ;
__p += '\n                <div class="triangle-same-assay sampleid clickable" style="color: ' +
((__t = ( colorOtherWellSameAssay )) == null ? '' : __t) +
'; right: 0px; left: inherit;"></div>\n            ';
 } ;
__p += '\n            ';
 if(otherWellOtherAssay) { ;
__p += '\n                <div class="triangle-other-assay sampleid clickable" style="color: ' +
((__t = ( colorOtherWellOtherAssay )) == null ? '' : __t) +
'; right: 0px; left: inherit;"></div>\n            ';
 } ;
__p += '\n        </div>\n        <div class="col-xs-2  p-l-0 p-r-0">\n            ' +
((__t = ( tags )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-1 p-l-0 p-r-0">\n            ';
 if (expSt) { ;
__p += '\n                <div class="mdi-action-exit-to-app"></div>\n            ';
 } ;
__p += '\n        </div>\n        <div class="col-xs-1 p-l-0 p-r-0">\n            ' +
((__t = ( refKitL != null ? refKitL.lotNb : '' )) == null ? '' : __t) +
'\n        </div>\n    </div>\n    <hr class="well-separator m-t-0 m-b-0"/>\n    ';
 _.each(results, function(result) { ;
__p += '\n    <div class="row m-b-0">\n        <div class="col-xs-3 p-l-0 p-r-0">\n            ';
 if (expSt) { ;
__p += '\n                <i class="btn-info-well mdi-action-info-outline clickable p-r-20" style="font-size: 20px;" data-target="' +
((__t = ( result.index )) == null ? '' : __t) +
'"></i>\n            ';
 } else { ;
__p += '\n                <i class="mdi-toggle-check-box btn-validate-result clickable" data-target="' +
((__t = ( result.index )) == null ? '' : __t) +
'" title="' +
((__t = ( _.i18n('well.tooltip.validate') )) == null ? '' : __t) +
'"></i>\n                <i class="btn-negative-result mdi-content-remove-circle-outline clickable" style="font-size: 20px;" data-target="' +
((__t = ( result.index )) == null ? '' : __t) +
'" title="' +
((__t = ( _.i18n('well.tooltip.negative') )) == null ? '' : __t) +
'"></i>\n                <i class="btn-repeat-pcr-result mdi-content-reply clickable" style="font-size: 20px;" data-target="' +
((__t = ( result.index )) == null ? '' : __t) +
'" title="' +
((__t = ( _.i18n('well.tooltip.repeatPcr') )) == null ? '' : __t) +
'"></i>\n                <i class="btn-repeat-dna-result mdi-content-reply-all clickable" style="font-size: 20px;" data-target="' +
((__t = ( result.index )) == null ? '' : __t) +
'" title="' +
((__t = ( _.i18n('well.tooltip.repeatDna') )) == null ? '' : __t) +
'"></i>\n                <i class="btn-expert-result mdi-social-school clickable" style="font-size: 20px;" data-target="' +
((__t = ( result.index )) == null ? '' : __t) +
'" title="' +
((__t = ( _.i18n('well.tooltip.expert') )) == null ? '' : __t) +
'"></i>\n                <i class="btn-edit-well mdi-content-create clickable" style="font-size: 20px;" data-target="' +
((__t = ( result.index )) == null ? '' : __t) +
'" title="' +
((__t = ( _.i18n('well.tooltip.edit') )) == null ? '' : __t) +
'"></i>\n            ';
 } ;
__p += '\n            <i class="btn-graph-well mdi-editor-insert-chart clickable" style="font-size: 20px;" data-target="' +
((__t = ( result.index )) == null ? '' : __t) +
'" title="' +
((__t = ( _.i18n('well.tooltip.graph') )) == null ? '' : __t) +
'"></i>\n        </div>\n        <div class="col-xs-2 p-l-0 p-r-0">\n            ' +
((__t = ( result.valSt )) == null ? '' : __t) +
' ' +
((__t = ( result.userCode )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-2 p-l-0 p-r-0">\n            <div class="targetGraphColor" style="background-color: ' +
((__t = ( result.colorGraph )) == null ? '' : __t) +
';"></div>\n            <label class="m-b-0" data-toggle="tooltip" data-html="true" title="' +
((__t = ( result.propCFormated )) == null ? '' : __t) +
'">' +
((__t = ( result.target )) == null ? '' : __t) +
'</label>\n        </div>\n        <div class="col-xs-2 p-l-0 p-r-0">\n            ' +
((__t = ( result.resTxt )) == null ? '' : __t) +
' ' +
((__t = ( result.iconHtml )) == null ? '' : __t) +
'' +
((__t = ( result.resFormated )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-1 p-l-0 p-r-0">\n            ' +
((__t = ( result.resCodeErr )) == null ? '' : __t) +
'\n        </div>\n        <div class="col-xs-2 p-l-0 p-r-0">\n            <label class="row m-b-0">' +
((__t = ( result.comment )) == null ? '' : __t) +
'</label>\n        </div>\n    </div>\n    ';
 }); ;
__p += '\n\n</div>';

}
return __p
};});

