
define('template!miniWellTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div style="' +
((__t = ( well.selected?'background-color: #ffffff;':'background-color: #dadada;' )) == null ? '' : __t) +
' height: 19px;">\n    <div style="' +
((__t = ( wellResColor?'background-color: '+wellResColor+';':'' )) == null ? '' : __t) +
' height: 19px; width:4px; position: absolute;"></div>\n    ';
 if(well.otherWellSameAssay) { ;
__p += '\n        <div class="triangle-same-assay-mini" style="color: ' +
((__t = ( colorOtherWellSameAssay )) == null ? '' : __t) +
';"></div>\n    ';
 } ;
__p += '\n    ';
 if(well.otherWellOtherAssay) { ;
__p += '\n        <div class="triangle-other-assay-mini" style="color: ' +
((__t = ( colorOtherWellOtherAssay )) == null ? '' : __t) +
';"></div>\n    ';
 } ;
__p += '\n    <div class="h-16 miniWellSelectableItem" style="padding-left: 6px;">\n        <div class="m-b-0">\n            ';
 if (displayResult) { ;
__p += '\n                <div class="result-icon" style="margin-left:-4px;">\n                    \n                </div>\n            ';
 } ;
__p += '\n        </div>\n        <span class="empty-circle item-selector-circle multi-selector">\n          <i class="mdi-action-check-circle icon" style="font-size: 11px; position: absolute;"></i>\n        </span>\n    </div>\n</div>\n';

}
return __p
};});

