define('runCreateLayoutView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!runCreateLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'settings',
  'autocompleteView',
  'entities/runs'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  Settings,
  AutocompleteView
) {
  'use strict';

  /*var selectRunTemplateRegion = Marionette.Region.extend({
    el: '.js-select-run-template-region',
    allowMissingEl: true
  });*/

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      inputName: '.run-input-name',
      createRunRadio: '.create-run-radio',
      createRunFromTemplate: '.createRunFromTemplate',
      createRunFromEmpty: '.createRunFromEmpty',
      createRunFromPlateLayout: '.createRunFromPlateLayout',
      createRunFromCyclerExport: '.createRunFromCyclerExport',
      uploadRunsPlateLayout: '.js-upload-runs-plate-layout',
      uploadRunsCyclerExport: '.js-upload-runs-cycler-export'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm',
      'change @ui.createRunRadio': 'onCreateRunRadioChange',
      'change @ui.uploadRunsPlateLayout': 'onLoadFilePlateLayout',
      'change @ui.uploadRunsCyclerExport': 'onLoadFilePlateLayout'
    },

    regions: {
      selectRunTemplate: '.js-select-run-template-region',
      selectKitProtFromCyclerExport: '.js-select-kitprot-region-from-cycler-export'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'name', type: 'required'}
    ],

    currentMode: 'fromTemplate',

    initialize: function(options) {
      this.model = options.runModel.clone();
      this.currentMode = 'fromTemplate';
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      return templateData;
    },

    onCreateRunRadioChange: function() {
      if(this.ui.createRunRadio){
        this.ui.createRunFromTemplate.toggleClass('hidden', true);
        this.ui.createRunFromEmpty.toggleClass('hidden', true);
        this.ui.createRunFromPlateLayout.toggleClass('hidden', true);
        this.ui.createRunFromCyclerExport.toggleClass('hidden', true);
        _.each(this.ui.createRunRadio, _.bind(function(radio) {
          switch(radio.value) {
            case 'fromTemplate':
              if (radio.checked) {
                this.ui.createRunFromTemplate.toggleClass('hidden', false);
              }
              break;
            case 'fromEmpty':
              if (radio.checked) {
                this.ui.createRunFromEmpty.toggleClass('hidden', false);
              }
              break;
            case 'fromPlateLayout':
              if (radio.checked) {
                this.ui.createRunFromPlateLayout.toggleClass('hidden', false);
              }
              break;  
            case 'fromCyclerExport':
              if (radio.checked) {
                this.ui.createRunFromCyclerExport.toggleClass('hidden', false);
              }
              break;  
          }
          if (radio.checked) {
            this.currentMode = radio.value;
          }
        }, this));
      }
    },

    onLoadFilePlateLayout: function(e) {
      if (e && e.target && e.target.files && e.target.files[0]) {
        this.currentFile = e.target.files[0];
      }
    },

    onShow: function() {
      $.material.init();
      var kitProtViewFromCyclerExport = new AutocompleteView(
        this._getAutocompleteOptions('refKitProt', 'code', 'code', 'v2/kitprots/mineAndShared',
            'selectkitprot.placeholder', 'code', this.onKitProtChange)
      );
      this.getRegion('selectKitProtFromCyclerExport').show(kitProtViewFromCyclerExport);

      var runTempalteView = new AutocompleteView(
          this._getAutocompleteOptions('refRunTemplate', 'name', 'name', 'v2/runtemplates',
              'selectruntemplate.placeholder', 'nameComment')
      );
      this.getRegion('selectRunTemplate').options.allowMissingEl = true;
      this.getRegion('selectRunTemplate').show(runTempalteView);

      this.triggerMethod('enable:cancel:confirm');
    },

    onKitProtChange: function(fieldName, model) {
      this.currentKitProt = model;
      if (model) {
        model.unset('autocompleteValue', {silent: true});
      }
      this.onChange(fieldName, model);
      if (model) {
        if (!this.ui.inputName.val()) {
          this.ui.inputName.val(model.get('runNamePattern'));
          this.ui.inputName.change();
        }

        if (this.mbAModelView) {
          this.mbAModelView.destroy();
        }
        this.mbAModelView = new AutocompleteView(
          this._getAutocompleteOptions('refLmbAnalyzer', 'code', 'code', 'v2/mbamodels?kitProtSecId=' + model.get('secId'),
            'selectkitmbamodel.placeholder', 'code')
        );
        this.getRegion('selectMbAModel').show(this.mbAModelView);
      }
    },

    onConfirm: function() {
      switch(this.currentMode) {
        case 'fromTemplate': 
            if(this.validate([{name: 'refRunTemplate', type: 'required'}])) {
              App.request('run:from-template', this.model.get('refRunTemplate').get('secId')).done(_.bind(function(model) {
                App.navigate('runs/' + model.get('secId'), {trigger: true});
                this.hide();
              }, this));
            }
            break;
        case 'fromPlateLayout':
            this.ui.uploadRunsPlateLayout.val('');
            this.trigger('run:create-from-popup', this.currentFile, this.currentLmbAnalyzer, this.currentKitProt, true);
            this.hide();
            break;
        case 'fromCyclerExport':
            this.ui.uploadRunsPlateLayout.val('');
            this.trigger('run:create-from-popup', this.currentFile, this.currentLmbAnalyzer, this.currentKitProt, false);
            this.hide();
            break;
        default: 
            if(this.validate(this.fieldsToValidate)) {
              this.wells = this.model.get('wells');
              this.model.set('wells', null);
              this.model.set('refRunTemplate', null);
              this.model.save();
            }
      }
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();
      this.model.set('wells', this.wells);
      this.options.runModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('run:header:rerender', modelJSON);
      App.navigate('runs/' + model.get('secId') + '/edit', {trigger: true});
      this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
      if (App.getCurrentRoute() ===  'runs/new') {
        App.navigate('runs', { trigger: (this.currentMode !== 'fromPlateLayout' && this.currentMode !== 'fromCyclerExport') });
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=code').addClass('has-error');
    }
  });

  module.exports = CreateEditLayoutView;
});
