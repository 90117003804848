
define('template!runTemplateCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group" data-field-name="name">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input run-input-name" data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('run.refKitProt') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="js-select-kitprot-region"></div>\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('runTemplate.isPool') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="checkbox" data-field-name="isPool" class="js-keep-logged-in js-info-input" ' +
((__t = ( isPool ? 'checked' : '' )) == null ? '' : __t) +
'>\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('runTemplate.isPCR') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="checkbox" data-field-name="isPCR" class="js-keep-logged-in js-info-input" ' +
((__t = ( isPCR ? 'checked' : '' )) == null ? '' : __t) +
'>\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('runTemplate.isPR') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="checkbox" data-field-name="isPR" class="js-keep-logged-in js-info-input" ' +
((__t = ( isPR ? 'checked' : '' )) == null ? '' : __t) +
'>\n        </div>\n      </div>\n      ';
 if (newItem) { ;
__p += '\n        <div class="form-group" data-field-name="plateColumnNb">\n          <label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("run.plateColumnNb") )) == null ? '' : __t) +
':</label>\n          <div class="col-xs-9">\n            <select class="form-control js-info-input" data-field-name="plateColumnNb" value="' +
((__t = ( plateColumnNb )) == null ? '' : __t) +
'">\n              <option value="1" ';
  if (plateColumnNb === '1') { ;
__p += ' selected ';
 } ;
__p += ' >1</option>\n              <option value="2" ';
  if (plateColumnNb === '2') { ;
__p += ' selected ';
 } ;
__p += ' >2</option>\n              <option value="3" ';
  if (plateColumnNb === '3') { ;
__p += ' selected ';
 } ;
__p += ' >3</option>\n              <option value="4" ';
  if (plateColumnNb === '4') { ;
__p += ' selected ';
 } ;
__p += ' >4</option>\n              <option value="5" ';
  if (plateColumnNb === '5') { ;
__p += ' selected ';
 } ;
__p += ' >5</option>\n              <option value="6" ';
  if (plateColumnNb === '6') { ;
__p += ' selected ';
 } ;
__p += ' >6</option>\n              <option value="7" ';
  if (plateColumnNb === '7') { ;
__p += ' selected ';
 } ;
__p += ' >7</option>\n              <option value="8" ';
  if (plateColumnNb === '8') { ;
__p += ' selected ';
 } ;
__p += ' >8</option>\n              <option value="9" ';
  if (plateColumnNb === '9') { ;
__p += ' selected ';
 } ;
__p += ' >9</option>\n              <option value="10" ';
  if (plateColumnNb === '10') { ;
__p += ' selected ';
 } ;
__p += ' >10</option>\n              <option value="11" ';
  if (plateColumnNb === '11') { ;
__p += ' selected ';
 } ;
__p += ' >11</option>\n              <option value="12" ';
  if (plateColumnNb === '12') { ;
__p += ' selected ';
 } ;
__p += ' >12</option>\n            </select>\n          </div>\n        </div>\n        <div class="form-group" data-field-name="plateRowNb">\n          <label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("run.plateRowNb") )) == null ? '' : __t) +
':</label>\n          <div class="col-xs-9">\n            <select class="form-control js-info-input" data-field-name="plateRowNb" value="' +
((__t = ( plateRowNb )) == null ? '' : __t) +
'">\n              <option value="A" ';
  if (plateRowNb === 'A') { ;
__p += ' selected ';
 } ;
__p += ' >1</option>\n              <option value="B" ';
  if (plateRowNb === 'B') { ;
__p += ' selected ';
 } ;
__p += ' >2</option>\n              <option value="C" ';
  if (plateRowNb === 'C') { ;
__p += ' selected ';
 } ;
__p += ' >3</option>\n              <option value="D" ';
  if (plateRowNb === 'D') { ;
__p += ' selected ';
 } ;
__p += ' >4</option>\n              <option value="E" ';
  if (plateRowNb === 'E') { ;
__p += ' selected ';
 } ;
__p += ' >5</option>\n              <option value="F" ';
  if (plateRowNb === 'F') { ;
__p += ' selected ';
 } ;
__p += ' >6</option>\n              <option value="G" ';
  if (plateRowNb === 'G') { ;
__p += ' selected ';
 } ;
__p += ' >7</option>\n              <option value="H" ';
  if (plateRowNb === 'H') { ;
__p += ' selected ';
 } ;
__p += ' >8</option>\n            </select>\n          </div>\n        </div>\n      ';
 } ;
__p += '\n      <div class="form-group" data-field-name="comment">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <textarea class="form-control floating-label js-info-input" rows="4" data-field-name="comment" placeholder="' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

