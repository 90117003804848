/**
 * Created by OBL on 7/01/2016.
 */
define('rolesMixin',['module', 'settings'], function(module, Settings){
    'use strict';
    var prefixApplication = Settings.get('application').toUpperCase();
    var RolesMixin = {
        LOGED: 'LOGED',
        AUTHENTICATED: 'AUTHENTICATED',
        SUPERUSER: 'SUPERUSER',
        SUPERADMINISTRATOR: 'SUPERADMINISTRATOR',
        ADMIN: 'ADMIN',
        EXPORT: 'EXPORT',
        VECTORIZE: 'VECTORIZE',
        ORDERENTRY: 'ORDERENTRY',
        DEVDOC: 'DEVDOC',
        VECTORIZATOR: 'VECTORIZATOR',
        CREDITS : 'CREDITS',
        //----LOGOTOOL------------------------------
        LOGOTOOL_TEMPLATE : 'LOGOTOOL_TEMPLATE',
        //----DASHBOARD------------------------------
        // ACCOUNT
        // -- ACCOUNT
        DASHBOARD_ACCOUNT: prefixApplication + '_ACCOUNT',
        DASHBOARD_ACCOUNT_ACCOUNT: prefixApplication + '_ACCOUNT_ACCOUNT',
        //--TABS
        DASHBOARD_ACCOUNT_ACCOUNT_PROFILE : prefixApplication + '_ACCOUNT_ACCOUNT_PROFILE',
        DASHBOARD_ACCOUNT_ACCOUNT_CREDITS: prefixApplication + '_ACCOUNT_ACCOUNT_CREDITS',
        DASHBOARD_ACCOUNT_ACCOUNT_ADMINISTRATOR: prefixApplication + '_ACCOUNT_ACCOUNT_ADMINISTRATOR',
        DASHBOARD_ACCOUNT_ACCOUNT_SHARE : prefixApplication + '_ACCOUNT_ACCOUNT_SHARE',
        DASHBOARD_ACCOUNT_ACCOUNT_SERVICES : prefixApplication + '_ACCOUNT_ACCOUNT_SERVICES',
        DASHBOARD_ACCOUNT_ACCOUNT_ACTIVITY: prefixApplication + '_ACCOUNT_ACCOUNT_ACTIVITY',
        DASHBOARD_ACCOUNT_ACCOUNT_ATTACHMENTS: prefixApplication + '_ACCOUNT_ACCOUNT_ATTACHMENTS',
        // -- GRAPHICAL ID
        DASHBOARD_ACCOUNT_GRAPHICALID: prefixApplication + '_ACCOUNT_GRAPHICALID',
        //--TABS
        DASHBOARD_ACCOUNT_GRAPHICALID_FONTS: prefixApplication + '_ACCOUNT_GRAPHICALID_FONTS',
        DASHBOARD_ACCOUNT_GRAPHICALID_OBJECTNAMES: prefixApplication + '_ACCOUNT_GRAPHICALID_OBJECTNAMES',
        DASHBOARD_ACCOUNT_GRAPHICALID_DESIGNELEMENTS: prefixApplication + '_ACCOUNT_GRAPHICALID_DESIGNELEMENTS',
        DASHBOARD_ACCOUNT_GRAPHICALID_TEMPLATES: prefixApplication + '_ACCOUNT_GRAPHICALID_TEMPLATES',
        //CLIENTS
        DASHBOARD_CUSTOMERS : prefixApplication + '_CUSTOMERS',
        DASHBOARD_CUSTOMERS_CUSTOMERS: prefixApplication + '_CUSTOMERS_CUSTOMERS',
        //--TABS
        DASHBOARD_CUSTOMERS_CUSTOMERS_PROFILE : prefixApplication + '_CUSTOMERS_CUSTOMERS_PROFILE',
        DASHBOARD_CUSTOMERS_CUSTOMERS_DESIGNELEMENTS : prefixApplication + '_CUSTOMERS_CUSTOMERS_DESIGNELEMENTS',
        DASHBOARD_CUSTOMERS_CUSTOMERS_LOGOSIMAGES : prefixApplication + '_CUSTOMERS_CUSTOMERS_LOGOSIMAGES',
        DASHBOARD_CUSTOMERS_CUSTOMERS_DATASETS : prefixApplication + '_CUSTOMERS_CUSTOMERS_DATASETS',
        DASHBOARD_CUSTOMERS_CUSTOMERS_PRODUCTTEMPLATES : prefixApplication + '_CUSTOMERS_CUSTOMERS_PRODUCTTEMPLATES',
        DASHBOARD_CUSTOMERS_CUSTOMERS_TEMPLATES : prefixApplication + '_CUSTOMERS_CUSTOMERS_TEMPLATES',
        DASHBOARD_CUSTOMERS_CUSTOMERS_ATTACHMENTS : prefixApplication + '_CUSTOMERS_CUSTOMERS_ATTACHMENTS',
        DASHBOARD_CUSTOMERS_CUSTOMERS_FONTS : prefixApplication + '_CUSTOMERS_CUSTOMERS_FONTS',
        DASHBOARD_CUSTOMERS_CUSTOMERS_CONTACTS : prefixApplication + '_CUSTOMERS_CUSTOMERS_CONTACTS',
        //RUNS
        DASHBOARD_EXTRACTRUNS: prefixApplication + '_EXTRACTRUNS',
        DASHBOARD_RUNS: prefixApplication + '_RUNS',
        DASHBOARD_RUNS_RUNTRACS: prefixApplication + '_RUNS_RUNTRACS',
        DASHBOARD_RACKS: prefixApplication + '_RACKS',
        DASHBOARD_RUNTEMPLATES: prefixApplication + '_RUNTEMPLATES',
        DASHBOARD_SAMPLES: prefixApplication + '_SAMPLES',
        //SUPPLIERS
        DASHBOARD_SUPPLIERS: prefixApplication + '_SUPPLIERS',
        DASHBOARD_SUPPLIERS_SUPPLIERS: prefixApplication + '_SUPPLIERS_SUPPLIERS',
        DASHBOARD_SUPPLIERS_PRODUCTFINDER: prefixApplication + '_SUPPLIERS_PRODUCTFINDER',
        //GRAPHICAL REQUESTS
        DASHBOARD_GRAPHICALREQUESTS : prefixApplication + '_GRAPHICALREQUESTS',
        DASHBOARD_GRAPHICALREQUESTS_ALL_IN_ONE: prefixApplication + '_GRAPHICALREQUESTS_ALL_IN_ONE',
        DASHBOARD_GRAPHICALREQUESTS_VECTORIZATION : prefixApplication + '_GRAPHICALREQUESTS_VECTORIZATION',
        DASHBOARD_GRAPHICALREQUESTS_FILECONVERSION: prefixApplication + '_GRAPHICALREQUESTS_FILECONVERSION',
        DASHBOARD_GRAPHICALREQUESTS_REMOVEBACKGROUND: prefixApplication + '_GRAPHICALREQUESTS_REMOVEBACKGROUND',
        //SUPERADMIN
        DASHBOARD_SUPERADMIN: prefixApplication + '_SUPERADMIN',
        DASHBOARD_SUPERADMIN_ACCOUNT_PROFILE: prefixApplication + '_SUPERADMIN_ACCOUNT_PROFILE',
        DASHBOARD_SUPERADMIN_ACCOUNT_ACTIVITY: prefixApplication + '_SUPERADMIN_ACCOUNT_ACTIVITY',
        DASHBOARD_SUPERADMIN_ACCOUNT_CREDITS: prefixApplication + '_SUPERADMIN_ACCOUNT_CREDITS',
        DASHBOARD_SUPERADMIN_ACCOUNT_GRAPHICALREQUESTS : prefixApplication + '_SUPERADMIN_ACCOUNT_GRAPHICALREQUESTS',
        DASHBOARD_SUPERADMIN_MBANA : prefixApplication + '_SUPERADMIN_MBANA',
        DASHBOARD_SUPERADMIN_MBSMPMAT : prefixApplication + '_SUPERADMIN_MBSMPMAT',
        DASHBOARD_SUPERADMIN_MBANARESGR : prefixApplication + '_SUPERADMIN_MBANARESGR',
        DASHBOARD_SUPERADMIN_MBAMODEL : prefixApplication + '_SUPERADMIN_MBAMODEL',

        // OTHERS
        DASHBOARD_TIMETRACKER: prefixApplication + '_TIMETRACKER',
        DASHBOARD_SUBACCOUNTCREATOR: prefixApplication + '_SUBACCOUNTCREATOR',
        DASHBOARD_ACCOUNTCREATOR: prefixApplication + '_ACCOUNTCREATOR',
        DASHBOARD_ACCOUNTSHARECREATOR : prefixApplication + '_ACCOUNTSHARECREATOR',
        DASHBOARD_ADMIN: prefixApplication + '_ADMIN',
        DASHBOARD_ADMIN_TRANSLATIONS: prefixApplication + '_ADMIN_TRANSLATIONS',
        DASHBOARD_ADMIN_KIT: prefixApplication + '_ADMIN_KIT',
        DASHBOARD_ADMIN_KITCS: prefixApplication + '_ADMIN_KITCS',
        DASHBOARD_ADMIN_LMBANALYZER: prefixApplication + '_ADMIN_LMBANALYZER',
        DASHBOARD_ADMIN_LMBANA: prefixApplication + '_ADMIN_LMBANA',
        DASHBOARD_ADMIN_LMBSMPMAT: prefixApplication + '_ADMIN_LMBSMPMAT',
        DASHBOARD_ADMIN_KITPROT: prefixApplication + '_ADMIN_KITPROT',
        DASHBOARD_ADMIN_LIS: prefixApplication + '_ADMIN_LIS',
        DASHBOARD_ADMIN_SETTING: prefixApplication + '_ADMIN_SETTING',
        DASHBOARD_SUPERADMIN_STATS: prefixApplication + '_SUPERADMIN_STATS',
        DASHBOARD_COLLECTIONS : prefixApplication + '_COLLECTIONS'
    };

    module.exports = RolesMixin;
});
