define('sampleCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!sampleCreateEditLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'settings',
  'moment',
  'autocompleteView',
  'entities/samples'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  Settings,
  moment,
  AutocompleteView
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      sampleDt: '.date-sampleDt'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      selectLis: '.js-select-lis-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
      {name: 'sampleId', type: 'required'}
    ],

    fromTemplate: true,

    initialize: function(options) {
      this.model = options.sampleModel.clone();
      this.fromTemplate = this.options.newItem;
      if (this.options.newItem) {
        if (Settings.get('sampleDt')) {
          this.model.set('sampleDt', parseInt(Settings.get('sampleDt'), 10));
        }
      }
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.sampleDt = moment(this.model.get('sampleDt')).format('DD-MM-YYYY');
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      $.material.init();

      var lisView = new AutocompleteView(
        this._getAutocompleteOptions('refLis', 'code', 'code', 'v2/lis', 'selectlis.placeholder', 'code')
      );
      this.getRegion('selectLis').show(lisView);

      this.triggerMethod('enable:cancel:confirm');
    },

    onRender: function() {
      this.initDatePicker(this.ui.sampleDt, 'sampleDt');
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.model.save();
      }
    },

    onSave: function(model) {
      if (this.options.newItem) {
        Settings.set('sampleDt', model.get('sampleDt'));
      }
      var modelJSON = model.toJSON();
      this.options.sampleModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('sample:header:rerender', modelJSON);
      App.navigate('samples/' + model.get('secId'), {trigger: true});
      this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
      if (App.getCurrentRoute() ===  'samples/new') {
        App.navigate('samples', {trigger: true});
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=code').addClass('has-error');
    }
  });

  module.exports = CreateEditLayoutView;
});
