define('rackTableTabController',[
    'module',
    'app',
    'underscore',
    'backbone',
    'rackTableTabView'
], function(
    module,
    App,
    _,
    Backbone,
    TableTabView
) {
    'use strict';

    var TableTabController = {
        showTableTab: function(region, rackModel) {
            this.region = region;
            this.model = rackModel;
            this.view = new TableTabView({
                model: rackModel
            });
            this.view.listenTo(this.view, 'rackel:filteredDetailRow', _.bind(this.filteredDetailRow, this));
            this.view.listenTo(this.view, 'rackel:filteredDetailColumn', _.bind(this.filteredDetailColumn, this));
            this.view.listenTo(this.view, 'rackel:filteredDetailItem', _.bind(this.filteredDetailItem, this));

            region.show(this.view);
        },

        filteredDetailRow: function(row) {
            App.navigate('racks/'+this.view.model.get('secId')+'/details/row/' + row, {trigger: true});
        },

        filteredDetailColumn: function(column) {
            if (column) {
                App.navigate('racks/'+this.view.model.get('secId')+'/details/column/' + column, {trigger: true});
            } else {
                App.navigate('racks/'+this.view.model.get('secId')+'/details', {trigger: true});
            }
        },

        filteredDetailItem: function(row, column) {
            App.navigate('racks/'+this.view.model.get('secId')+'/details/row/'+row+'/column/' + column, {trigger: true});
        }
    };

    module.exports = TableTabController;
});
