define('rackDetailsTabView',[
    'module',
    'marionette',
    'template!runTemplateDetailsTabTpl'
], function(
    module,
    Marionette,
    detailsTabTpl
) {
    'use strict';

    var DetailsTabView = Marionette.LayoutView.extend({
        template: detailsTabTpl,

        ui: {
            tabs1: '.tabs'
        },

        events: {
        },

        regions: {
            wellTable: '.well-table',
            headerWell: '#header-region'
        }
    });

    module.exports = DetailsTabView;
});
