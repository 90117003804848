
define('template!wellEditSmpidTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="ui-show-result-cell wellSelectableItem" style="' +
((__t = ( backgroundColor?'background-color:' + backgroundColor + '; ':'' )) == null ? '' : __t) +
'width:100%; height:53px;">\n    ';
 if(colorCtrl) { ;
__p += '\n        ';
 if (well.smptype === 'NC') { ;
__p += '\n            <i class="mdi-content-remove-circle" style="position: absolute; font-size:18px;"></i>\n        ';
 } else if(well.smptype === 'PC') { ;
__p += ' \n            <i class="mdi-content-add-circle" style="position: absolute; font-size:18px;"></i>\n        ';
 } ;
__p += '\n    ';
 } ;
__p += '\n    <div class=" p-l-0 p-r-0 col-xs-2 h-16"></div>\n    ';
 if (editMode) { ;
__p += ' \n        <div class="col-xs-10 small-regular-compuzz p-l-2 p-r-0 h-16 smp-id-link">\n        <input type="text" tabindex="' +
((__t = ( tabIndex )) == null ? '' : __t) +
'" class="form-control floating-label js-info-input js-input-smpid text-white-on-focus"\n                style="height:17px; font-size: 13px; color: black;"\n                value="' +
((__t = ( well.smpid )) == null ? '' : __t) +
'"\n                data-placement="top">\n        </div>\n    ';
 } else { ;
__p += ' \n        <span class="col-xs-10 small-regular-compuzz p-l-5 p-r-0 h-16 smp-id-link"\n            style="white-space: nowrap; overflow: hidden; font-family: monospace;">' +
((__t = ( well.smpid )) == null ? '' : __t) +
'</span>\n    ';
 } ;
__p += '\n    <div class="well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: green;"></div>\n    <span class="btn-assay col-xs-10 small-regular-compuzz p-l-2 p-r-0 h-16"\n    style="white-space: nowrap; overflow: hidden;"><span>' +
((__t = ( well.refAssay?well.refAssay.code:'' )) == null ? '' : __t) +
'</span></span>\n    <div class="well-result-icons p-l-0 p-r-0 col-xs-2 h-16"></div>\n</div>';

}
return __p
};});

