define('runDetailsGraphView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'moment',
  'mobioChartView',
  'colorUtils'
], function (
  module,
  Marionette,
  _,
  App,
  moment,
  MobioChartView,
  ColorUtils
) {
  'use strict';

  var RunGraphView =  MobioChartView.extend({

      serializeData: function() {

      this.data = {
        datasets : [
        ]
      };
      var curve = {}, algo = {}, i;
      if (!this.amplGraph) {
        for (i = 1 ; i <= 6 ; i++) {
          curve[i] = (!this.channelToDisplay || this.channelToDisplay === i)?this.model.get('meltCurve' + i):null;
          algo[i] = this.modelRun.getAlgoFromChannel(this.model.get('refAssay'), i);
        }
      } else {
        for (i = 1 ; i <= 6 ; i++) {
          curve[i] = (!this.channelToDisplay || this.channelToDisplay === i)?this.model.get('amplCurve' + i):null;
          algo[i] = this.modelRun.getAlgoFromChannel(this.model.get('refAssay'), i);
        }
      }

      for (i = 1 ; i <= 6 ; i++) {
        if ( !_.isUndefined(curve[i]) && !_.isEmpty(curve[i]) && this.modelRun.isUsingChannel(this.model.get('refAssay'), i)) {
          var colorTarget1 = ColorUtils.colorTarget(i, this.modelRun, this.model); 
          this.data.datasets.push(this.addData(curve[i], colorTarget1.r, colorTarget1.g, colorTarget1.b,
              this.modelRun.getTargetName(this.model.get('refAssay'), i), algo[i], null, colorTarget1.dashed, 
              this.model.get('res1Comment'), this.model, i));
        }
      }

      if (this.data.datasets.length) {
        if (this.baseline !== null && this.baseline !== undefined) {
          this.data.datasets.push(this.addLine(this.baseline, 'baseline', 255, 0, 0));
        }
        if (this.threshold !== null && this.threshold !== undefined) {
          this.data.datasets.push(this.addLine(this.threshold, 'threshold', 0, 0, 255));
        }
      }

      this.generateLegend();
      return this.data;
    }
  });


  module.exports = RunGraphView;
});
