define('entities/racks',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var Rack = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/racks/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      'rackName':  '',
      'rackId': '',
      'lRackId': '',
      'eRackId': '',
      'rackSt': '0',
      'pcrSt': '',
      'isTemplate': false,
      'rackCType': 'ORI',
      'nbRows': 8,
      'nbCol': 12,
      'code': '',
      'name': ''
    },
    idAttribute: 'secId',

    getImageUid:function() {},

    postUrl: function () {
      return Settings.url('compuzz', 'v2/racks');
    },

    save: function() {
      this.url = this.postUrl();
      return Backbone.Model.prototype.save.call(this);
    }

  });

  var RackCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/racks');
    },
    model: Rack
  });

  var API = {

    getRackCollectionUrl: function() {
      new RackCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/racks', params);
    },

    getAllRacks: function(params) {
      var url = Settings.url('compuzz', 'v2/racks', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(racks) {
          var racksList = [];
          _.each(racks, function(rack){
            racksList.push(rack.secId);
          });
          defer.resolve(racksList);
        }
      });

      return defer.promise();
    },

    getRackCountUrl: function() {
      var params = '';
      return 'v2/racks/rowCount' + params;
    },

    deleteRack: function(secId){
      var url = Settings.url('compuzz', 'v2/racks/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    },

    importXlsFile: function(fileInfo, lisSecId) {
      var defer = $.Deferred(),
        formdata =  new FormData(),
        url = Settings.url('compuzz', 'v2/racks/excel', { lisSecId: lisSecId });

      formdata.append('file', fileInfo);

      $.ajax({
        type: 'POST',
        url: url,
        data: formdata,
        processData: false,
        contentType: false,
        success: function(resp) {
          defer.resolve(resp);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('rack:get-collection-url', function() {
      return API.getRackCollectionUrl();
  });

  App.reqres.setHandler('rack:get-collection-to-page', function(params) {
      return API.getAllRacks(params);
  });

  App.reqres.setHandler('rack:get-count-url', function() {
    return API.getRackCountUrl();
  });

  App.reqres.setHandler('rack:model', function(rackJSON) {
    return new Rack(rackJSON);
  });

  App.reqres.setHandler('rack:delete', function(secId) {
    return API.deleteRack(secId);
  });

  App.reqres.setHandler('rack:importXlsFile', function(fileInfo, lisSecId) {
    return API.importXlsFile(fileInfo, lisSecId);
  });
});
