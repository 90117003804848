define('wellView',[
    'module',
    'app',
    'marionette',
    'template!wellTpl',
    'template!wellTpl2',
    'wellResultIconView',
    'underscore',
    'template!wellPoolTooltipTpl',
    'template!wellPooledTooltipTpl',
    'entities/wells'
], function(
    module,
    App,
    Marionette,
    wellTpl,
    wellTpl2,
    WellResultIconView,
    _,
    wellPoolTooltipTpl,
    wellPooledTooltipTpl
) {
    'use strict';

    var WellView = Marionette.LayoutView.extend({
        ui: {
            elementRight: '.elementRight',
            selector: '.item-selector-circle',
            selectableItem: '.wellSelectableItem',
            poolLabel: '.pool-label',
            pooledLabel: '.pooled-label'
        },

        regions: {
            iconResult1: '.icon-result1',
            iconResult2: '.icon-result2',
            iconResult3: '.icon-result3',
            iconResult4: '.icon-result4',
            iconResult5: '.icon-result5',
            iconResult6: '.icon-result6'
        },

        events: {
            'click': 'onItemClick',
            'click @ui.selector': 'onSelectClick',
            'click .btn-assay': 'onAssayClick',
            'click .smp-id-link': 'onSampleIdClick'
        },

        getTemplate: function() {
            if (this.options.modelRun.get('settings')['TEST-MODE'] === '1') {
                return wellTpl2;
            } else {
                return wellTpl;
            }
        },

        onAssayClick: function(e) {
            e.preventDefault();
            e.stopPropagation();
            if (this.model.refAssay) {
                this.trigger('well:filteredDetailAssay', this.model.refAssay.secId);
            }
        },

        onSampleIdClick: function(e) {
            e.preventDefault();
            e.stopPropagation();
            if (!this.model.smptype || this.model.smptype === 'U' || this.model.smptype === 'P') {
                this.trigger('well:showSampleIds', this.model.smpid, this.model);
            } else {
                this.trigger('well:showSampleIdsQc', this.model.smpid, this.model);
            }
        },

        onSelectClick: function(e) {
            e.preventDefault();
            e.stopPropagation();

            var $template = this.ui.selectableItem,
                selected = $template.hasClass('selected') ? true : false;

            if (selected) {
                $template.removeClass('selected');
            } else {
                $template.addClass('selected');
            }

            this.trigger('well:selectedWell', this.model.pos, !selected);
        },

        onItemClick:function() {
            this.trigger('well:filteredDetailItem', this.model);
        },

        onShow: function() {
            if (this.displayResult) {
                var well = this.model;
                this.iconResult1View = new WellResultIconView({model: well, modelRun: this.options.modelRun, channel: 1});
                this.getRegion('iconResult1').show(this.iconResult1View);
                this.iconResult2View = new WellResultIconView({model: well, modelRun: this.options.modelRun, channel: 2});
                this.getRegion('iconResult2').show(this.iconResult2View);
                this.iconResult3View = new WellResultIconView({model: well, modelRun: this.options.modelRun, channel: 3});
                this.getRegion('iconResult3').show(this.iconResult3View);
                this.iconResult4View = new WellResultIconView({model: well, modelRun: this.options.modelRun, channel: 4});
                this.getRegion('iconResult4').show(this.iconResult4View);
                this.iconResult5View = new WellResultIconView({model: well, modelRun: this.options.modelRun, channel: 5});
                this.getRegion('iconResult5').show(this.iconResult5View);
                this.iconResult6View = new WellResultIconView({model: well, modelRun: this.options.modelRun, channel: 6});
                this.getRegion('iconResult6').show(this.iconResult6View);
            }
            var w = App.request('well:model', this.model);
            this.showPoolPopover(w);
            this.showPooledPopover(w);
        },

        showPooledPopover: function(well) {
            if (this.ui.pooledLabel && this.ui.pooledLabel.length > 0) {
              var templateData = well.toJSON();
              var content = Marionette.Renderer.render(wellPooledTooltipTpl, templateData);
              this.ui.pooledLabel.attr('data-content', content);
              this.ui.pooledLabel.popover({
                trigger: 'manual',
                placement: 'bottom',
                html: 'true',
                delay: {
                  show: '300',
                  hide: '500'
                },
                container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content')
              }).on('mouseenter', function () {
                var _this = this;
                $(this).popover('show');
                $('.popover').on('mouseleave', function () {
                    $(_this).popover('hide');
                });
              }).on('mouseleave', function () {
                var _this = this;
                setTimeout(function () {
                    if (!$('.popover:hover').length) {
                        $(_this).popover('hide');
                    }
                }, 300);
            }).data('bs.popover').tip().addClass('pool-popover');
            }
        },

        showPoolPopover: function(well) {
            if (this.ui.poolLabel && this.ui.poolLabel.length > 0 && (well.get('smpid') || well.get('smptype')) && well.get('refAssay')) {
              var templateData = well.toJSON();
              var content = Marionette.Renderer.render(wellPoolTooltipTpl, templateData);
              var t = $(this.ui.poolLabel);
              t.attr('data-content', content);
              t.popover({
                trigger: 'manual',
                placement: 'bottom',
                html: 'true',
                delay: {
                  show: '300',
                  hide: '100'
                },
                container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content')
              }).on('mouseenter', function () {
                var _this = this;
                $(this).popover('show');
                $('.popover').on('mouseleave', function () {
                    $(_this).popover('hide');
                });
              }).on('mouseleave', function () {
                var _this = this;
                setTimeout(function () {
                    if (!$('.popover:hover').length) {
                        $(_this).popover('hide');
                    }
                }, 300);
            }).data('bs.popover').tip().addClass('pool-popover');
            }
        },

        serializeData: function() {
            var templateData = {
            well: _.clone(this.model)
          };
          if (templateData.well.smpid && this.model.smptype !== 'P') {
            templateData.well.smpid = this.options.modelRun.getFormatedSmpId(this.model);
          }
          templateData.displayResult = '';
          if ((this.model.smpid || this.model.smptype === 'P') && 
                this.model.refAssay ) {
              templateData.displayResult = true;
          }

          templateData.firstOfAssay = this.options.firstOfAssay;

          templateData.error = false;
          templateData.warning = false;
          templateData.fixedError = false;
          templateData.fixedWarning = false;
          templateData.ok = false;

          switch(templateData.well.errorInd) {
            case 'E': templateData.error = true; break;
            case 'W': templateData.warning = true; break;
          }

          var colorWarnErr = ['#000000', '#e54095', '#000000', '#e54095'];
          if (this.options.modelRun.get('settings') && this.options.modelRun.get('settings').COLOR_WELL_ST) {
            colorWarnErr = this.options.modelRun.get('settings').COLOR_WELL_ST.split(';');
          }
          templateData.colorError = colorWarnErr[0];
          templateData.colorWarning = colorWarnErr[1];
          templateData.colorFixedError = colorWarnErr[2];
          templateData.colorFixedWarning = colorWarnErr[3];

          if (!templateData.warning && !templateData.error && (this.model.res1CodeErr === 'W' ||
            this.model.res2CodeErr === 'W' || this.model.res3CodeErr === 'W' ||
            this.model.res4CodeErr === 'W' || this.model.res5CodeErr === 'W' || this.model.res6CodeErr === 'W')) {
            templateData.warning = true;
          }

          if (this.model.res1CodeErr !== 'W' &&
              this.model.res2CodeErr !== 'W' && this.model.res3CodeErr !== 'W' &&
              this.model.res4CodeErr !== 'W' && this.model.res5CodeErr !== 'W' && this.model.res6CodeErr !== 'W' &&
              ((this.model.res1CommentErrAuto && this.model.res1CommentErrAuto.indexOf('W') === 0) ||
                  (this.model.res2CommentErrAuto && this.model.res2CommentErrAuto.indexOf('W') === 0) ||
                  (this.model.res3CommentErrAuto && this.model.res3CommentErrAuto.indexOf('W') === 0) ||
                  (this.model.res4CommentErrAuto && this.model.res4CommentErrAuto.indexOf('W') === 0) ||
                  (this.model.res5CommentErrAuto && this.model.res5CommentErrAuto.indexOf('W') === 0) ||
                  (this.model.res6CommentErrAuto && this.model.res6CommentErrAuto.indexOf('W') === 0))) {
              templateData.fixedWarning = true;
          }

          if (!templateData.error && (this.model.res1CodeErr === 'E' ||
            this.model.res2CodeErr === 'E' || this.model.res3CodeErr === 'E' ||
            this.model.res4CodeErr === 'E' || this.model.res5CodeErr === 'E' || this.model.res6CodeErr === 'E')) {
            templateData.warning = false;
            templateData.error = true;
          }

            if (this.model.res1CodeErr !== 'E' &&
                this.model.res2CodeErr !== 'E' && this.model.res3CodeErr !== 'E' &&
                this.model.res4CodeErr !== 'E' && this.model.res5CodeErr !== 'E' && this.model.res6CodeErr !== 'E' &&
                ((this.model.res1CommentErrAuto && this.model.res1CommentErrAuto.indexOf('E') === 0) ||
                    (this.model.res2CommentErrAuto && this.model.res2CommentErrAuto.indexOf('E') === 0) ||
                    (this.model.res3CommentErrAuto && this.model.res3CommentErrAuto.indexOf('E') === 0) ||
                    (this.model.res4CommentErrAuto && this.model.res4CommentErrAuto.indexOf('E') === 0) ||
                    (this.model.res5CommentErrAuto && this.model.res5CommentErrAuto.indexOf('E') === 0) ||
                    (this.model.res6CommentErrAuto && this.model.res6CommentErrAuto.indexOf('E') === 0))) {
                templateData.fixedError = true;
            }

          if (!templateData.error && !templateData.warning) {
            templateData.ok = true;
          }

          if (templateData.well.valWst <= 2) {
              templateData.colorVal = '#0000FF';
          } else if (templateData.well.valWst === 4) {
              templateData.colorVal = '#00FF00';
          } else if (templateData.well.valWst === 3) {
              templateData.colorVal = '#FF0000';
          }

          templateData.colorCtrl = null;

          templateData.colorAssay = null;

          var colorsAssay = ['#FF0000', '#00FF00','#fff302','#ff0021','#0012ff'];

          if (this.options.modelRun.get('settings') && this.options.modelRun.get('settings').COLORS_ASSAY) {
            colorsAssay = this.options.modelRun.get('settings').COLORS_ASSAY.split(';');
          }

          if (this.options.modelRun.get('settings') && this.options.modelRun.get('settings')['OTHER-ASSAY-IND']) {
            var otherAssayInd = this.options.modelRun.get('settings')['OTHER-ASSAY-IND'].split(';');
            templateData.colorOtherWellSameAssay = otherAssayInd[0];
            templateData.colorOtherWellOtherAssay = otherAssayInd[1];
          } else {
            templateData.colorOtherWellSameAssay = '#FF0000';
            templateData.colorOtherWellOtherAssay = '#FFFFFF';
          }

          if (templateData.displayResult) {
              var i = (this.options.assayIndex % (colorsAssay.length - 3));
              templateData.colorAssay = colorsAssay[i];
          }

          switch (templateData.well.smptype) {
              case 'NC': templateData.colorCtrl = colorsAssay[colorsAssay.length - 3]; break;
              case 'PC': templateData.colorCtrl = colorsAssay[colorsAssay.length - 2]; break;
              case 'C': templateData.colorCtrl = colorsAssay[colorsAssay.length - 1]; break;
          }

          this.displayResult = templateData.displayResult;

          templateData.runStatus = this.options.modelRun.get('status');

          templateData.wellResColor = this.options.modelRun.getColorWellRes(templateData.well.wellRes);

          if (templateData.well.wellsPool.length > 0) {
            templateData.pooled = true;
          } else {
            templateData.pooled = false;
          }
          if (templateData.well.wellsPooled.length > 0) {
            templateData.pool = true;
          } else {
            templateData.pool = false;
          }

          return templateData;
        }
    });

    module.exports = WellView;
});
