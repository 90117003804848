
define('template!sampleDetailTabTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="well mbamodel-detail">\n  <div class="detailed-info-form-container">\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.sampleId') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( sampleId ? sampleId : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.orderId') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( orderId ? orderId : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.patId') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( patId ? patId : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.refLis') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( refLis ? refLis.code : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.sampleDt') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( sampleDtString ? sampleDtString : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.ppgr') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( ppgr ? ppgr : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.sampleTest') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( sampleTest ? sampleTest : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.sampleSt') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( sampleSt ? sampleSt : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.res') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( res ? res : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.resNum') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( resNum ? resNum : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.refWell') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( refWell ? refWell.pos : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="col-md-6">\n        <div class="col-xs-4">' +
((__t = ( _.i18n('sample.refRackEl') )) == null ? '' : __t) +
'</div>\n        <div class="col-xs-8">' +
((__t = ( refRackEl ? refRackEl.rowNb : '-' )) == null ? '' : __t) +
'</div>\n      </div>\n    </div>\n    <div class="row">\n      <div class="pull-right p-r-15">\n        <button class="btn btn-primary js-edit">' +
((__t = ( _.i18n('common.edit') )) == null ? '' : __t) +
'</button>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

