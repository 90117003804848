/**
 * Created by bfr on 10/04/2020.
 */
define('racksShowView',[
  'module',
  'underscore',
  'backbone',
  'jqgridView',
  'dateUtils',
  'urlUtil',
  'app',
  'settings',
  'template!racksViewTpl',
  'datePickerPopoverMixin',
  'jqgridActionsButtonFormatterActions',
  'jqgridActionsButtonFormatterView',
  'bootbox'
], function(
  module,
  _,
  Backbone,
  JqGridView,
  dateConverter,
  UrlUtil,
  App,
  Settings,
  viewTpl,
  datePickerPopoverMixin,
  availableActions,
  ActionButtonsView,
  bootbox
) {
  'use strict';

  var RacksView =  JqGridView.extend({
    template: viewTpl,
    filtersName: 'rackFilters',

    regions: {
      actions: '.js-action-buttons-region'
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-list',
        jqGridPager: '#jq-grid-pager-list',
        deleteRack: '.delete-rack-link',
        uploadRack: '.js-upload-racks'
      }, this.gridUi);
    },

    events: {
      'click @ui.deleteRack': 'onDeleteRack',
      'change @ui.uploadRack': 'onUploadRack'
    },

    onShow: function() {
      $.material.init();

      var actionsButtonView = this._getActionButtonsView();
      this.listenTo(actionsButtonView, 'action', this._onAction);
      this.getRegion('actions').show(actionsButtonView);
    },

    _getActionButtonsView: function() {
      var actions = ['import'],
        actionsCollection = new Backbone.Collection();
      _.each(actions, function(action) {
        actionsCollection.push(availableActions[action]);
      });

      return new ActionButtonsView({
        collection: actionsCollection,
        dontShowIcons: true
      });
    },

    _onAction: function(action) {
      switch ( action ) {
        case 'import':
          this.$('#rack-excel-input' ).trigger('click');
          break;
      }
    },

    onUploadRack: function(e) {
      if (e && e.target && e.target.files && e.target.files[0]) {
        this.uploadExcel(e.target.files[0]);
      }
    },

    uploadExcel: function(fileInfo) {
      this.ui.uploadRack.val('');
      this.trigger('racks:import-xls-file', fileInfo);
    },

    serializeData: function () {
      this.selectedRacks = Settings.get('selectedRacks') || [];
      var templateData = {
        'type': 'racks'
      };
      return templateData;
    },

    onDeleteRack: function(event){
      this.trigger('rack:delete', $(event.currentTarget).find('span').attr('data-row-id'));
    },

    gridOptions: function(data) {
      var that = this,
        defaultsOptions = this.gridInitOptions(data),
        options = {
          colModel: [{
            label: '',
            name: 'secId',
            key: true,
            hidden: true
          },{
              label: _.i18n('rackList.rackName'),
              name: 'rackName',
              classes: 'order-link',
              formatter: this.defaultFormatter,
              search: false,
              index: 'rackName',
              width: 50
            }, {
              label: _.i18n('rackList.rackSt'),
              name: 'rackSt',
              classes: 'order-link',
              formatter: this.defaultFormatter,
              search: false,
              index: 'rackSt'
            }, {
              label: _.i18n('rackList.pcrSt'),
              name: 'pcrSt',
              classes: 'order-link',
              formatter: this.defaultFormatter,
              search: false,
              index: 'pcrSt'
            }, {
              label: _.i18n('rackList.rackId'),
              name: 'rackId',
              classes: 'order-link',
              formatter: this.defaultFormatter,
              search: false,
              index: 'rackId'
            }, {
              label: _.i18n('rackList.lRackId'),
              name: 'lRackId',
              classes: 'order-link',
              formatter: this.defaultFormatter,
              search: false,
              index: 'lRackId'
            }, {
              label: _.i18n('rackList.eRackId'),
              name: 'eRackId',
              classes: 'order-link',
              formatter: this.defaultFormatter,
              search: false,
              index: 'eRackId'
            }, {
              label: ' ',
              name: 'delete',
              search: false,
              classes: 'delete-rack-link delete-link',
              formatter: this.deleteIconFormatter,
              width: 20
            }
          ],

          serializeGridData: function (postData) {
            if (postData.status === that.selectDefault) {
              delete postData.status;
            }

            if (postData.modDate) {
              postData.dateFrom = datePickerPopoverMixin.parseDateFrom(postData.modDate);
              postData.dateTo = datePickerPopoverMixin.parseDateTo(postData.modDate);
            } else if (!that.sorting) {
              delete postData.dateFrom;
              delete postData.dateTo;
            }
            that.sorting = false;
            defaultsOptions.serializeGridData.apply(this, arguments);

            return postData;
          },

          gridComplete: function() {
            var purchaseOrderId = UrlUtil.parseQueryString().purchaseOrderId;
            if(purchaseOrderId) {
              UrlUtil.clearQueryString();
              that.preselectFirstRow();
            }
            defaultsOptions.gridComplete.apply(this, arguments);
            that.checkRacksSelection();
          },

          onSelectRow: function(rowId, bool, e) {
            if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
              var cellUpdated = that.$('.cell-payedToManufacturer, .cell-payedByCustomer');

              if (e.type === 'click' && cellUpdated.has(e.target).length === 0) {
                var route = App.getCurrentRoute() ===  'racks' ? App.getCurrentRoute() : 'racks/' + App.getCurrentRoute();
                App.navigate(route + '/' + rowId, {trigger: true});
              }
            }
          }
        };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    /*
     * racks selection with ctrl/shift keys
    */

    manageCtrlKey: function(grid, rowid) {
      var maxSelectedRacksNumber = 100,
          $row = grid.find('#' + rowid);
      if ($row.hasClass('selected')) {
        $row.removeClass('selected');
        this.onRowMultiselect(rowid, false);
      } else {
        if (this.selectedRacks.length === maxSelectedRacksNumber) {
          this.showWarningSelectedNumberOutOfRange();
        } else {
          $row.addClass('selected');
          this.onRowMultiselect(rowid, true);
        }
      }
    },

    manageShiftKey: function(grid, rowid) {
      var firstSelectedOrder = this.getFirstSelectedOrder(),
          lastSelectedRow = grid.find('#' + rowid),
          gridRows = grid.find('tr'),
          lastIndex = gridRows.index(lastSelectedRow),
          lastSelectedOrder = {
            rowid: rowid,
            page: this.pagination.currentPage,
            index: lastIndex
          };

      if (!firstSelectedOrder) firstSelectedOrder = lastSelectedOrder;
      if (firstSelectedOrder.page > lastSelectedOrder.page) {
        this.swapRacks(firstSelectedOrder, lastSelectedOrder);
      } else if (firstSelectedOrder.page === lastSelectedOrder.page){
        if (firstSelectedOrder.index > lastSelectedOrder.index) {
          this.swapRacks(firstSelectedOrder, lastSelectedOrder);
        }
      }
      if (this.checkIfOnlyCurrentPageSelection(firstSelectedOrder, lastSelectedOrder)) {
        this.selectWithoutRequest(firstSelectedOrder, lastSelectedOrder);
      } else {
        this.getCollectionToPage(firstSelectedOrder, lastSelectedOrder);
      }
    },

    checkIfOnlyCurrentPageSelection: function (first, last) {
      return first.page === last.page;
    },

    selectWithoutRequest: function (first, last) {
      var grid = this.ui.jqGrid,
          list = grid.find('tr');

      this.selectedRacks = [];
      list.each(_.bind(function(index, row){
        var $row = $(row),
            rowid = $row.attr('id');
        if (index >= first.index && 
            index <= last.index) {
          this.selectedRacks.push({
            rowid: rowid,
            page: this.pagination.currentPage,
            index: index
          });
        }
      }, this));
      this.checkRacksSelection();
    },

    swapRacks: function(first, last) {
      for (var key in last) {
        var temp = first[key];
        first[key] = last[key];
        last[key] = temp;
      }
    },

    getFirstSelectedOrder: function() {
      var firstSelected,
          lastPage = this.getLastPage(), 
          firstIndex = this.pagination.pageSize;
      if (this.selectedRacks.length === 0) {
        return null;
      } else {
        _.each(this.selectedRacks, function(order){
           if (order.page < lastPage) {
            lastPage = order.page;
            firstIndex = order.index;
            firstSelected = order;
           } else if (order.page === lastPage) {
            if (order.index < firstIndex) {
              firstSelected = order;
              firstIndex = order.index;
            }
           }
        });
        return firstSelected;
      }
    },

    getLastPage: function() {
      var lastPage;
      if (this.selectedRacks.length > 0) {
        lastPage = _.max(this.selectedRacks, function(rack){
          return rack.page;
        }).page;
      } else {
        lastPage = this.pagination.currentPage;
      }
      return lastPage;
    },

    getCollectionToPage: function (first, last) {
      var firstOrderIndex = (first.page - 1 ) * this.pagination.pageSize + (first.index - 1),
          lastOrderIndex = (last.page - 1 ) * this.pagination.pageSize + last.index,
          size = lastOrderIndex - firstOrderIndex || 1,
          params = {
            pageSize: size,
            first: firstOrderIndex,
            _search: false,
            nd: new Date().getTime(),
            rows: size,
            page: 1,
            sord: 'asc'
          };
      if (size > 100) {
        this.showWarningSelectedNumberOutOfRange();
      } else {
        $('#content-blinder').show();
        this.trigger('onLoadSelectedRacks', first, last, params);
      }
    },

    showWarningSelectedNumberOutOfRange: function() {
      bootbox.alert(_.i18n('common.errorOccuredSelectedItemsNumberOutOfRange'), function () {
        return;
      });
    },

    onSelectRacks: function(first, last, racks) {
      var pageSize = this.pagination.pageSize,
          page, orderIndex;

      this.selectedRacks = [];
      _.each(racks, _.bind(function(rack, index) {
        page = Math.floor((index + first.index - 1) / pageSize);
        orderIndex = index + first.index - page * pageSize;
        this.selectedRacks.push({
          rowid: rack,
          page: page + first.page,
          index: orderIndex
        });
      }, this));
      this.checkRacksSelection();
    },

    onRowMultiselect: function(rowid, select) {
      var index,
          grid = this.ui.jqGrid,
          gridList = grid.find('tr'),
          row = grid.find('#' + rowid),
          order = {
            rowid: rowid,
            page: this.pagination.currentPage,
            index: gridList.index(row)
          };
      if (select) {
        this.selectedRacks.push(order);
      } else {
        index = this.selectedRacks.indexOf(_.findWhere(this.selectedRacks, {rowid: rowid}));
        this.selectedRacks.splice(index, 1);
      }
      this.onSelectedRacksChanged();
    },

    checkRacksSelection: function() {
      var $list = this.ui.jqGrid.find('tr');
      $list.each(_.bind(function (index, item) {
        var $item = $(item);
        if (_.some(this.selectedRacks, {rowid: $item.attr('id')})) {
          $item.addClass('selected');
        } else {
          $item.removeClass('selected');
        }
      }, this));
      this.onSelectedRacksChanged();
    },

      onSelectedRacksChanged: function() {
      if (this.selectedRacks.length > 0) {
      }
      Settings.set('selectedRacks', this.selectedRacks);
    },

    onGridFilterChange: function() {
      var that = this,
        filters = {},
        value;

      _.each(this.colModel, function(column) {
        if (column.search) {
          if (column.stype === 'select') {
            value = that.$('select[name="' + column.index + '"]').val();
            filters[column.index] = value === that.selectDefault ? '' : value;
          } else if (column.sorttype === 'date') {
            value = that.$('input[name="' + column.index + '"]').val();
            if (column.index === 'modDate' && value) {
              filters.dateFrom = datePickerPopoverMixin.parseDateFrom(value);
              filters.dateTo = datePickerPopoverMixin.parseDateTo(value);
            }
          } else {
            value = that.$('input[name="' + column.index + '"]').val();
            filters[column.index] = value;
          }
        }
      });

      this.filters = filters;
      this.filterChange = true;
      this.updatePagination();
    }
  });

  module.exports = RacksView;
});
