/**
 * Created by OLD on 30/10/2015.
 */
define('adminMainRouter',[
  'underscore',
  'require',
  'exports',
  'module',
  'logger',
  'marionette',
  'app',
  'itemPagesNameMixin',
  'adminKitTabs',
  'adminAssayTabs',
  'adminKitlTabs',
  'adminKitpTabs',
  'adminResTransTabs',
  'adminKitprTabs',
  'adminKitcTabs',
  'adminAssayController',
  'adminKitlController',
  'adminKitcController',
  'adminKitprController',
  'adminAnaAssayTabs',
  'adminKitpController',
  'adminAnaAssayController',
  'adminLmbAnalyzerTabs',
  'adminLmbAnaTabs',
  'adminLmbSmpMatTabs',
  'adminKitProtTabs',
  'adminKitCQctController',
  'adminLisTabs',
  'adminSettingTabs'
], function(
    _,
  require,
  exports,
  module,
  Logger,
  Marionette,
  App,
  ItemPagesNameMixin,
  AdminKitTabs,
  AdminAssayTabs,
  AdminKitlTabs,
  AdminKitpTabs,
  AdminResTransTabs,
  AdminKitprTabs,
  AdminKitcTabs,
  AdminAssayController,
  AdminKitlController,
  AdminKitcController,
  AdminKitprController,
  AdminAnaAssayTabs,
  AdminKitpController,
  AdminAnaAssayController,
  AdminLmbAnalyzerTabs,
  AdminLmbAnaTabs,
  AdminLmbSmpMatTabs,
  AdminKitProtTabs,
  AdminKitCQctController,
  AdminLisTabs,
  AdminSettingTabs
) {
  'use strict';

  var AdminRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'admin/kitcs/:secId(/:tab)' : 'showKitcsAdminDetails',
      'admin/kitcs/:secId(/:tab)/new' : 'showKitcsAdminDetailNews',
      'admin/kitcs/:secId(/:tab)/:kitpSecId/edit' : 'showKitcsAdminDetailEdits',
      'admin/anaassays/:secId' : 'showAnaAssaysAdminDetails',
      'admin/assays/:secId(/:tab)' : 'showAssaysAdminDetails',
      'admin/assays/:secId(/:tab)/new' : 'showAssaysAdminDetailsNew',
      'admin/assays/:secId(/:tab)/:kitpSecId/edit' : 'showAssaysAdminDetailsEdit',
      'admin/kitl/:secId(/:tab)': 'showKitlAdminDetails',
      'admin/kitp/:secId(/:tab)' : 'showKitpAdminDetails',
      'admin/kitp/:secId(/:tab)/new' : 'showKitpAdminDetailsNew',
      'admin/restrans/:secId(/:tab)': 'showResTransAdminDetails',
      'admin/kitpr/:secId(/:tab)': 'showKitprAdminDetails',
      'admin/kits' : 'showKitsAdminList',
      'admin/kits/new' : 'showKitsAdminNew',
      'admin/kits/:secId(/:tab)' : 'showKitsAdminDetails',
      'admin/kits/:secId(/:tab)/new' : 'showKitsAdminDetailsNew',
      'admin/kits/:secId(/:tab)/:kitlpSecId/edit' : 'showKitsAdminDetailsEdit',
      'admin/lmbanalyzers' : 'showLmbAnalyzersAdminList',
      'admin/lmbanalyzers/new' : 'showLmbAnalyzersAdminNew',
      'admin/lmbanalyzers/:secId(/:tab)' : 'showLmbAnalyzersAdminDetails',
      'admin/lmbsmpmats' : 'showLmbSmpMatsAdminList',
      'admin/lmbsmpmats/new' : 'showLmbSmpMatsAdminNew',
      'admin/lmbsmpmats/:secId(/:tab)' : 'showLmbSmpMatsAdminDetails',
      'admin/lmbanas' : 'showLmbAnasAdminList',
      'admin/lmbanas/new' : 'showLmbAnasAdminNew',
      'admin/lmbanas/:secId(/:tab)' : 'showLmbAnasAdminDetails',
      'admin/lmbanas/:secId/:tab/new' : 'showLmbAnasAdminNewDetails',
      'admin/lmbanas/:secId/:tab/:kitlpSecId/edit' : 'showLmbAnasAdminEditDetails',
      'admin/kitprots' : 'showKitProtsAdminList',
      'admin/kitprots/new' : 'showKitProtsAdminNew',
      'admin/kitprots/:secId(/:tab)' : 'showKitProtsAdminDetails',
      'admin/kitprots/:secId(/:tab)/new' : 'showKitProtsAdminDetailsNew',
      'admin/kitprots/:secId(/:tab)/:kitlpSecId/edit' : 'showKitProtsAdminDetailsEdit',
      'admin/lis' : 'showLisAdminList',
      'admin/lis/new' : 'showLisAdminNew',
      'admin/lis/:secId(/:tab)' : 'showLisAdminDetails',
      'admin/lis/:secId(/:tab)/new' : 'showLisAdminDetailsNew',
      'admin/settings' : 'showSettingAdminList',
      'admin/settings/new' : 'showSettingAdminNew',
      'admin/settings/:secId(/:tab)' : 'showSettingAdminDetails'
    }
  });

  var API = {
    checkContent: function() {
      var defer = $.Deferred();

      require([
        'contentShowController'
      ], function(ContentShowController) {
        ContentShowController.showContent().done(function() {
          defer.resolve();
        });
      });
      return defer.promise();
    },

    showKitsAdminList: function() {
      this.showKitsAdmin(false);
    },

    showKitsAdminNew: function() {
      this.showKitsAdmin(true);
    },

    showKitsAdmin: function(openNewPopup) {
      this.checkContent().done(function() {
        require([
          'adminKitsController'
        ], function(AdminKitsController) {
          AdminKitsController.showKitList({
            type: 'kits'
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_KITS);

          if (openNewPopup) {
            AdminKitsController.showKitAdd();
          } else {
            if (AdminKitsController.createEditView) {
              AdminKitsController.createEditView.hide(true);
            }
          }
        });
      });
    },

    showKitsAdminDetailsNew: function(kitSecId, tab) {
      this.showKitsAdminDetailsParam(kitSecId, tab, true);
    },

    showKitsAdminDetails: function(kitSecId, tab) {
      this.showKitsAdminDetailsParam(kitSecId, tab, false);
    },

    showKitsAdminDetailsEdit: function(kitSecId, tab, kitlpSecId) {
        this.showKitsAdminDetailsParam(kitSecId, tab, false, kitlpSecId);
    },

      showKitsAdminDetailsParam: function(kitSecId, tab, newItem, kitlpSecId) {
      this.checkContent().done(_.bind(function() {
        require([
          'adminKitDetailsController'
        ], function(adminKitDetailsController) {
          if (!tab) {
            tab = _.findWhere(AdminKitTabs, {isDefault: true});
          } else {
            tab = _.findWhere(AdminKitTabs, {name: tab});
          }
          adminKitDetailsController.fetchKit({
            secId: kitSecId,
            tab: tab
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_KITS);
          if (newItem) {
            switch (tab.name) {
              case 'assay': AdminAssayController.showAssayAdd(kitSecId);
                    break;
              case 'kitl': AdminKitlController.showKitlAdd(kitSecId);
                break;
              case 'kitc': AdminKitcController.showKitcAdd(kitSecId);
                break;
              case 'kitpr': AdminKitprController.showKitprAdd(kitSecId);
                break;
            }
          } else if (kitlpSecId) {
            switch (tab.name) {
              case 'assay': AdminAssayController.showAssayEdit(kitSecId, kitlpSecId);
                break;
              case 'kitl': AdminKitlController.showKitlEdit(kitSecId, kitlpSecId);
                break;
              case 'kitc': AdminKitcController.showKitcEdit(kitSecId, kitlpSecId);
                break;
              case 'kitpr': AdminKitprController.showKitprEdit(kitSecId, kitlpSecId);
                break;
            }
          } else {
            if (AdminAssayController.createEditView) {
              AdminAssayController.createEditView.hide();
            }
            if (AdminKitlController.createEditView) {
              AdminKitlController.createEditView.hide();
            }
            if (AdminKitprController.createEditView) {
              AdminKitprController.createEditView.hide();
            }
          }
        });
      }, this));
    },

    showAssaysAdminDetailsNew: function(assaySecId, tab) {
      this.showAssaysAdminDetails(assaySecId, tab, true);
    },

    showAssaysAdminDetailsEdit: function(assaySecId, tab, kitpSecId) {
      this.showAssaysAdminDetails(assaySecId, tab, false, kitpSecId);
    },

    showKitlAdminDetails: function(kitlSecId, tab) {
      this.checkContent().done(_.bind(function() {
        require([
          'adminKitlDetailsController'
        ], function(adminKitlDetailsController) {
          if (!tab) {
            tab = _.findWhere(AdminKitlTabs, {isDefault: true});
          } else {
            tab = _.findWhere(AdminKitlTabs, {name: tab});
          }
          adminKitlDetailsController.fetchKitl({
            secId: kitlSecId,
            tab: tab
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_KITS);
          if (AdminKitlController.createEditView) {
            AdminKitlController.createEditView.hide();
          }
        });
      }, this));
    },

    showKitpAdminDetailsNew:  function(kitpSecId, tab) {
      this.showKitpAdminDetails(kitpSecId, tab, true);
    },

    showKitpAdminDetails: function(kitpSecId, tab, newItem) {
        this.checkContent().done(_.bind(function() {
            require([
                'adminKitpDetailsController',
                'adminSettingKitpController'
            ], function(adminKitpDetailsController, adminSettingKitpController) {
                if (!tab) {
                    tab = _.findWhere(AdminKitpTabs, {isDefault: true});
                } else {
                    tab = _.findWhere(AdminKitpTabs, {name: tab});
                }
                adminKitpDetailsController.fetchKitp({
                    secId: kitpSecId,
                    tab: tab
                });
                App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_KITS);
                if (AdminKitpController.createEditView) {
                    AdminKitpController.createEditView.hide();
                }
                if (newItem) {
                    switch(tab.name) {
                        case 'setting': adminSettingKitpController.showSettingAdd(kitpSecId);
                            break;
                    }
                }
            });
        }, this));
    },

    showResTransAdminDetails: function(resTransSecId, tab) {
      this.checkContent().done(_.bind(function() {
        require([
          'adminResTransDetailsController',
          'adminResTransController'
        ], function(adminResTransDetailsController, adminResTransController) {
          if (!tab) {
            tab = _.findWhere(AdminResTransTabs, {isDefault: true});
          } else {
            tab = _.findWhere(AdminResTransTabs, {name: tab});
          }
          adminResTransDetailsController.fetchResTrans({
            secId: resTransSecId,
            tab: tab
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_LMBANAS);
          if (adminResTransController.createEditView) {
            adminResTransController.createEditView.hide();
          }
        });
      }, this));
    },

    showAnaAssaysAdminDetails: function(anaAssaySecId, tab) {
      this.checkContent().done(_.bind(function() {
        require([
          'adminAnaAssayDetailsController'
        ], function(adminAnaAssayDetailsController) {
          if (!tab) {
            tab = _.findWhere(AdminAnaAssayTabs, {isDefault: true});
          } else {
            tab = _.findWhere(AdminAnaAssayTabs, {name: tab});
          }
          adminAnaAssayDetailsController.fetchAnaAssay({
            secId: anaAssaySecId,
            tab: tab
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_KITS);
        });
      }, this));
    },

    showKitprAdminDetails: function(kitprSecId, tab) {
      this.checkContent().done(_.bind(function() {
        require([
          'adminKitprDetailsController'
        ], function(adminKitprDetailsController) {
          if (!tab) {
            tab = _.findWhere(AdminKitprTabs, {isDefault: true});
          } else {
            tab = _.findWhere(AdminKitprTabs, {name: tab});
          }
          adminKitprDetailsController.fetchKitpr({
            secId: kitprSecId,
            tab: tab
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_KITPROTS);
          if (AdminKitpController.createEditView) {
            AdminKitpController.createEditView.hide();
          }
        });
      }, this));
    },

    showAssaysAdminDetails: function(assaySecId, tab, newItem, itemSecId) {
      this.checkContent().done(_.bind(function() {
        require([
          'adminAssayDetailsController'
        ], function(adminAssayDetailsController) {
          if (!tab) {
            tab = _.findWhere(AdminAssayTabs, {isDefault: true});
          } else {
            tab = _.findWhere(AdminAssayTabs, {name: tab});
          }
          adminAssayDetailsController.fetchAssay({
            secId: assaySecId,
            tab: tab
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_KITS);
          if (newItem) {
            switch(tab.name) {
              case 'kitp': AdminKitpController.showKitpAdd(assaySecId);
                           break;
              case 'anaassay': AdminAnaAssayController.showAnaAssayAdd(assaySecId);
                               break;
            }
          } else if (itemSecId) {
            switch(tab.name) {
              case 'kitp': AdminKitpController.showKitpEdit(assaySecId, itemSecId);
                           break;
              case 'anaassay': AdminAnaAssayController.showAnaAssayEdit(assaySecId, itemSecId);
                               break;
            }
          } else {
            if (AdminKitpController.createEditView) {
              AdminKitpController.createEditView.hide();
            }
            if (AdminAnaAssayController.createEditView) {
              AdminAnaAssayController.createEditView.hide();
            }
          }
        });
      }, this));
    },

    showKitcsAdminDetails: function(kitcSecId, tab, openNewPopup, kitcqctSecId) {
      this.checkContent().done(_.bind(function() {
        require([
          'adminKitcDetailsController'
        ], function(adminKitcDetailsController) {
          if (!tab) {
            tab = _.findWhere(AdminKitcTabs, {isDefault: true});
          } else {
            tab = _.findWhere(AdminKitcTabs, {name: tab});
          }
          adminKitcDetailsController.fetchKitc({
            secId: kitcSecId,
            tab: tab
          });

          if (openNewPopup) {
            AdminKitCQctController.showKitCQctAdd(kitcSecId);
          } else if (kitcqctSecId) {
            AdminKitCQctController.showKitCQctEdit(kitcSecId, kitcqctSecId);
          }else {
            if (AdminKitCQctController.createEditView) {
              AdminKitCQctController.createEditView.hide(true);
            }
          }

          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_KITS);
        });
      }, this));
    },

    showKitcsAdminDetailEdits: function(kitcSecId, tab, kitcqctSecId) {
      this.showKitcsAdminDetails(kitcSecId, tab, false, kitcqctSecId);
    },

    showKitcsAdminDetailNews: function(kitcSecId, tab) {
      this.showKitcsAdminDetails(kitcSecId, tab, true);
    },

    showLmbAnalyzersAdminList: function() {
      this.showLmbAnalyzersAdmin(false);
    },

    showLmbAnalyzersAdminNew: function() {
      this.showLmbAnalyzersAdmin(true);
    },

    showLmbAnalyzersAdmin: function(openNewPopup) {
      this.checkContent().done(function() {
        require([
          'adminLmbAnalyzersController'
        ], function(AdminLmbAnalyzersController) {
          AdminLmbAnalyzersController.showLmbAnalyzerList({
            type: 'lmbanalyzers'
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_LMBANALYZERS);

          if (openNewPopup) {
            AdminLmbAnalyzersController.showLmbAnalyzerAdd();
          } else {
            if (AdminLmbAnalyzersController.createEditView) {
              AdminLmbAnalyzersController.createEditView.hide(true);
            }
          }
        });
      });
    },

    showLmbAnalyzersAdminDetails: function(lmbAnalyzerSecId, tab) {
      this.checkContent().done(_.bind(function() {
        require([
          'adminLmbAnalyzerDetailsController'
        ], function(adminLmbAnalyzerDetailsController) {
          adminLmbAnalyzerDetailsController.fetchLmbAnalyzer({
            secId: lmbAnalyzerSecId,
            tab: _.findWhere(AdminLmbAnalyzerTabs, {name: tab})
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_LMBANALYZERS);
        });
      }, this));
    },

    showLmbSmpMatsAdminList: function() {
      this.showLmbSmpMatsAdmin(false);
    },

    showLmbSmpMatsAdminNew: function() {
      this.showLmbSmpMatsAdmin(true);
    },

    showLmbSmpMatsAdmin: function(openNewPopup) {
      this.checkContent().done(function() {
        require([
          'adminLmbSmpMatsController'
        ], function(AdminLmbSmpMatsController) {
          AdminLmbSmpMatsController.showLmbSmpMatList({
            type: 'lmbsmpmats'
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_LMBSMPMATS);

          if (openNewPopup) {
            AdminLmbSmpMatsController.showLmbSmpMatAdd();
          } else {
            if (AdminLmbSmpMatsController.createEditView) {
              AdminLmbSmpMatsController.createEditView.hide(true);
            }
          }
        });
      });
    },

    showLmbSmpMatsAdminDetails: function(lmbSmpMatSecId, tab) {
      this.checkContent().done(_.bind(function() {
        require([
          'adminLmbSmpMatDetailsController'
        ], function(adminLmbSmpMatDetailsController) {
          adminLmbSmpMatDetailsController.fetchLmbSmpMat({
            secId: lmbSmpMatSecId,
            tab: _.findWhere(AdminLmbSmpMatTabs, {name: tab})
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_LMBSMPMATS);
        });
      }, this));
    },

    showLmbAnasAdminList: function() {
      this.showLmbAnasAdmin(false);
    },

    showLmbAnasAdminNew: function() {
      this.showLmbAnasAdmin(true);
    },

    showLmbAnasAdmin: function(openNewPopup) {
      this.checkContent().done(function() {
        require([
          'adminLmbAnasController'
        ], function(AdminLmbAnasController) {
          AdminLmbAnasController.showLmbAnaList({
            type: 'lmbanas'
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_LMBANAS);

          if (openNewPopup) {
            AdminLmbAnasController.showLmbAnaAdd();
          } else {
            if (AdminLmbAnasController.createEditView) {
              AdminLmbAnasController.createEditView.hide(true);
            }
          }
        });
      });
    },

    showLmbAnasAdminNewDetails: function(lmbAnaSecId, tab) {
      this.showLmbAnasAdminDetails(lmbAnaSecId, tab, true);
    },

    showLmbAnasAdminEditDetails: function(lmbAnaSecId, tab, resTransSecId) {
      this.showLmbAnasAdminDetails(lmbAnaSecId, tab, false, resTransSecId);
    },

    showLmbAnasAdminDetails: function(lmbAnaSecId, tab, newItem, resTransSecId) {
      this.checkContent().done(_.bind(function() {
        require([
          'adminLmbAnaDetailsController',
          'adminLmbAnaAnaAssayController',
          'adminResTransController'
        ], function(adminLmbAnaDetailsController, adminLmbAnaAnaAssayController, adminResTransController) {
          if (!tab) {
            tab = _.findWhere(AdminLmbAnaTabs, {isDefault: true});
          } else {
            tab = _.findWhere(AdminLmbAnaTabs, {name: tab});
          }
          adminLmbAnaDetailsController.fetchLmbAna({
            secId: lmbAnaSecId,
            tab: tab
          });

          if (newItem) {
            switch (tab.name) {
              case 'anaassay': adminLmbAnaAnaAssayController.showAnaAssayAdd(lmbAnaSecId);
                break;
              case 'restrans': adminResTransController.showResTransAdd(lmbAnaSecId);
                break;
            }
          } else if (resTransSecId) {
            switch (tab.name) {
              case 'anaassay': adminLmbAnaAnaAssayController.showAnaAssayEdit(lmbAnaSecId, resTransSecId);
                break;
              case 'restrans': adminResTransController.showResTransEdit(lmbAnaSecId, resTransSecId);
                break;
            }
          } else {
            if (adminResTransController.createEditView) {
              adminResTransController.createEditView.hide();
            }
          }

          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_LMBANAS);
        });
      }, this));
    },

    showKitProtsAdminDetailsNew: function(kitSecId, tab) {
        this.showKitProtsAdminDetails(kitSecId, tab, true);
    },

    showKitProtsAdminDetailsEdit: function(kitSecId, tab, kitlpSecId) {
        this.showKitProtsAdminDetails(kitSecId, tab, false, kitlpSecId);
    },


    showKitProtsAdminList: function() {
      this.showKitProtsAdmin(false);
    },

    showKitProtsAdminNew: function() {
      this.showKitProtsAdmin(true);
    },

    showKitProtsAdmin: function(openNewPopup) {
      this.checkContent().done(function() {
        require([
          'adminKitProtsController'
        ], function(AdminKitProtsController) {
          AdminKitProtsController.showKitProtList({
            type: 'kitprots'
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_KITPROTS);

          if (openNewPopup) {
            AdminKitProtsController.showKitProtAdd();
          } else {
            if (AdminKitProtsController.createEditView) {
              AdminKitProtsController.createEditView.hide(true);
            }
          }
        });
      });
    },

    showKitProtsAdminDetails: function(kitProtSecId, tab, newItem, kitprSecId) {
      this.checkContent().done(_.bind(function() {
        require([
          'adminKitProtDetailsController'
        ], function(adminKitProtDetailsController) {
            if (!tab) {
                tab = _.findWhere(AdminKitProtTabs, {isDefault: true});
            } else {
                tab = _.findWhere(AdminKitProtTabs, {name: tab});
            }

          adminKitProtDetailsController.fetchKitProt({
            secId: kitProtSecId,
            tab: tab
          });

          if (newItem) {
              switch (tab.name) {
                  case 'kitpr': AdminKitprController.showKitprAdd(kitProtSecId);
                      break;
              }
          } else if (kitprSecId) {
              switch (tab.name) {
                  case 'kitpr': AdminKitprController.showKitprEdit(kitProtSecId, kitprSecId);
                      break;
              }
          } else {
              if (AdminKitprController.createEditView) {
                  AdminKitprController.createEditView.hide();
              }
          }
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_KITPROTS);
        });
      }, this));
    },

    showLisAdminList: function() {
      this.showLisAdmin(false);
    },

    showLisAdminNew: function() {
      this.showLisAdmin(true);
    },

    showLisAdmin: function(openNewPopup) {
      this.checkContent().done(function() {
        require([
          'adminLisController'
        ], function(LisController) {
          LisController.showLisList({
            type: 'lis'
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_LIS);

          if (openNewPopup) {
            LisController.showLisAdd();
          } else {
            if (LisController.createEditView) {
              LisController.createEditView.hide(true);
            }
          }
        });
      });
    },

    showLisAdminDetailsNew: function(lisSecId, tab) {
      this.showLisAdminDetails(lisSecId, tab, true);
    },

    showLisAdminDetails: function(lisSecId, tab) {
      if (!tab) {
        tab = 'details';
      }
      this.checkContent().done(_.bind(function() {
        require([
          'adminLisDetailsController'
        ], function(AdminLisDetailsController) {
          AdminLisDetailsController.fetchLis({
            secId: lisSecId,
            tab: _.findWhere(AdminLisTabs, {name: tab})
          });
          App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_LIS);
        });
      }, this));
    },

    showSettingAdminList: function() {
        this.showSettingAdmin(false);
    },

    showSettingAdminNew: function() {
        this.showSettingAdmin(true);
    },

    showSettingAdmin: function(openNewPopup) {
        this.checkContent().done(function() {
            require([
                'adminSettingController'
            ], function(SettingController) {
                SettingController.showSettingList({
                    type: 'setting'
                });
                App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_SETTING);

                if (openNewPopup) {
                    SettingController.showSettingAdd();
                } else {
                    if (SettingController.createEditView) {
                        SettingController.createEditView.hide(true);
                    }
                }
            });
        });
    },

    showSettingAdminDetails: function(settingSecId, tab) {
        if (!tab) {
            tab = 'details';
        }
        this.checkContent().done(_.bind(function() {
            require([
                'adminSettingDetailsController'
            ], function(AdminSettingDetailsController) {
                AdminSettingDetailsController.fetchSetting({
                    secId: settingSecId,
                    tab: _.findWhere(AdminSettingTabs, {name: tab}),
                    fromKitp: tab==='fromkitp'
                });
                App.execute('set:active:nav:item', ItemPagesNameMixin.ADMIN_SETTING);
            });
        }, this));
    }
  };

  App.on('admin:show', function() {
    App.navigate('admin');
  });

  App.on('before:start', function() {
    new AdminRouter({
      controller: API
    });
  });

  module.exports = AdminRouter;
});
