define('wellTemplateView',[
    'module',
    'marionette',
    'template!wellTemplateTpl'
], function(
    module,
    Marionette,
    wellTpl
) {
    'use strict';

    var WellTemplateView = Marionette.ItemView.extend({
        template: wellTpl,

        ui: {
            elementRight: '.elementRight'
        },

        events: {
            'click': 'onItemClick'
        },

        onItemClick:function() {
            this.trigger('welltemplate:filteredDetailItem', this.model.pos);
        },

        serializeData: function() {
            var templateData = {
                well: this.model
            };

            templateData.backgroundColor = null;

            if (this.options.modelRun.get('settings')['P-LAYOUT-COL']) {
                var col = Number(this.model.pos.substring(1, 3)) - 1;
                templateData.backgroundColor = this.options.modelRun.get('settings')['P-LAYOUT-COL'].split(';')[col];
            }

            return templateData;
        }
    });

    module.exports = WellTemplateView;
});
