define('entities/kitprots',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var KitProt = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/kitprots/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      code: '',
      name: '',
      resGraph: 'AM',
      channels: '',
      description: '',
      onlyExtractSamples: false,
      runNamePattern: '',
      defaultNbColumns: 12,
      defaultNbLines: 8,
      exportRoutine: ''
    },
    idAttribute: 'secId',
    getImageUid:function() {},

    postUrl: function () {
      return Settings.url('compuzz', 'v2/kitprots');
    },

    save: function() {
      this.url = this.postUrl();
      return Backbone.Model.prototype.save.call(this);
    }
  });

  var KitProtCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/kitprots');
    },
    model: KitProt
  });

  var API = {

    getKitProtCollectionUrl: function() {
      new KitProtCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/kitprots', params);
    },

    getAllKitProts: function(params) {
      var url = Settings.url('compuzz', 'v2/kitprots', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(items) {
          var list = [];
          _.each(items, function(item){
            list.push(item.secId);
          });
          defer.resolve(list);
        }
      });

      return defer.promise();
    },

    getKitProtCountUrl: function() {
      var params = '';
      return 'v2/kitprots/rowCount' + params;
    },

    deleteKitProt: function(secId){
      var url = Settings.url('compuzz', 'v2/kitprots/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('kitprot:get-collection-url', function() {
      return API.getKitProtCollectionUrl();
  });

  App.reqres.setHandler('kitprot:get-collection-to-page', function(params) {
      return API.getAllKitProts(params);
  });

  App.reqres.setHandler('kitprot:get-count-url', function() {
    return API.getKitProtCountUrl();
  });

  App.reqres.setHandler('kitprot:model', function(itemJSON) {
    return new KitProt(itemJSON);
  });

  App.reqres.setHandler('kitprot:delete', function(secId) {
    return API.deleteKitProt(secId);
  });
});
