define('rackTabsData',[
  'module',
  'underscore'
], function(
  module,
  _
) {
  'use strict';

  module.exports = [{
      name: 'detail',
      title: _.i18n('racks.detail.tabTitle')
    },
    {
      name: 'table',
      title: _.i18n('racks.table.tabTitle')
    },
    {
      name: 'details',
      title: _.i18n('racks.details.tabTitle')
    }
  ];
});
