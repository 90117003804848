define('rackDetailsTabController',[
    'module',
    'app',
    'underscore',
    'backbone',
    'rackDetailsTabView',
    'emptyView',
    'rackelTableView',
    'headerRackelTemplateView',
    'entities/rackels'
], function(
    module,
    App,
    _,
    Backbone,
    DetailsTabView,
    EmptyView,
    RackelTableView,
    HeaderRackelView
) {
    'use strict';

    var DetailsTabController = {
        showDetailsTab: function(region, rackModel, row, column, filters) {
            var that = this;
            this.model = rackModel;
            this.view = new DetailsTabView({
            });

            this.row = row;
            this.column = column;
            this.filters = filters;

            region.show(this.view);
            this.view.getRegion('wellTable').show(new EmptyView());
            that.onShowRackelTable();
        },

        onShowRackelTable: function() {
            this.wellView = new RackelTableView({
                tabName: this.tabName
            });

            this.wellView.model = this.model;

            this.wellView.listenTo(this.wellView, 'onGridLoaded', _.bind(this.onGridLoaded, this));
            this.wellView.listenTo(this.wellView, 'rackel-table:loaded', _.bind(this.onLoadSubViews, this));
            this.wellView.listenTo(App, 'well:header:rerender', _.bind(this.onRackelChange, this));
            this.wellView.listenTo(App, 'well:mass:edit', _.bind(this.onRackelsChange, this));
            this.view.getRegion('wellTable').show(this.wellView);
        },

        onGridLoaded:function () {
            var smpid = this.filters?this.filters.smpid:null;
            var assaySecId = this.filters?this.filters.assaySecId:null;
            var data = {
                url: App.request('rackel:get-collection-url', this.model.get('secId'), this.row,
                    this.column, assaySecId, smpid),
                rowCountUrl: App.request('rackel:get-count-url', this.model.get('secId'), this.row,
                    this.column, assaySecId, smpid)
            };
            this.wellView.displayGrid(data);
        },

        getModels: function(items) {
            var models = [];
            _.each(items, _.bind(function (item) {
                var well = _.findWhere(this.model.get('wells'), {secId: item.secId});
                models.push(App.request('rackel:model', well));
            }, this));
            return models;
        },

        onRackelsChange: function (wells) {
            _.each(wells, _.bind(function(well) {
                var wellJson = well.toJSON();
                for (var i = 0 ; i < this.model.get('wells').length ; i++) {
                    if (this.model.get('wells')[i].secId === wellJson.secId) {
                        this.model.get('wells')[i]= wellJson;
                    }
                }
            }, this));
            this.onShowRackelTable();
        },

        onRackelChange: function (wellJson) {
            for (var i = 0 ; i < this.model.get('wells').length ; i++) {
                if (this.model.get('wells')[i].secId === wellJson.secId) {
                    this.model.get('wells')[i]= wellJson;
                }
            }
            this.onShowRackelTable();
        },

        onLoadSubViews: function(wellSecId) {
            var well = _.findWhere(this.model.get('rackEls'), {secId: wellSecId});

            this.view.model = App.request('rackel:model', well);
            this.view.modelRack = this.model;
            if (this.headerRackelView) {
                this.headerRackelView.destroy();
            }
            this.headerRackelView = new HeaderRackelView();
            this.headerRackelView.model = this.view.model;
            this.headerRackelView.modelRack = this.model;
            var region = this.view.getRegion('headerRackel');
            if (region){
                region.show(this.headerRackelView);
            }
        }
    };

    module.exports = DetailsTabController;
});
