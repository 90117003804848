
define('template!wellPooledTooltipTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="" style="">\n    ';
 _.each(wellsPool, function(well) { ;
__p += '\n        <div class="row m-b-0">\n            <label class="col-xs-6">' +
((__t = ( well.runName )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-2">\n                <label class="row">' +
((__t = ( well.pos )) == null ? '' : __t) +
'</label>\n            </div>\n            <label class="col-xs-3">' +
((__t = ( well.smpid )) == null ? '' : __t) +
'</label>\n        </div>\n    ';
 }); ;
__p += '\n</div>';

}
return __p
};});

