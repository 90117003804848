
define('template!rackTabsHeaderTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="vertical-divider col-lg-1"></div>\n<div class="js-action-buttons-region runs-action-button f-r"></div>\n<span class="runs-back-to-list back-to-list js-back-to-list"><i class="mdi-content-reply"></i>' +
((__t = ( _.i18n("common.back.to.list") )) == null ? '' : __t) +
'</span>\n';

}
return __p
};});

