
define('template!lisCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container create-edit-lis-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group" data-field-name="code">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input js-code"\n                 data-field-name="code" placeholder="' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'"\n                 value="' +
((__t = ( code )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('lis.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                 data-placement="top">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="name">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="importType">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('lis.importType') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input" data-field-name="importType" value="">\n            <option value="N" ';
  if (importType === 'N') { ;
__p += ' selected ';
 } ;
__p += ' >N</option>\n            <option value="S" ';
  if (importType === 'S') { ;
__p += ' selected ';
 } ;
__p += ' >S</option>\n          </select>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="ppgrDef">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('lis.ppgrDef') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input" data-field-name="ppgrDef" value="">\n            <option value="S" ';
  if (ppgrDef === 'S') { ;
__p += ' selected ';
 } ;
__p += ' >S</option>\n            <option value="M" ';
  if (ppgrDef === 'M') { ;
__p += ' selected ';
 } ;
__p += ' >M</option>\n            <option value="L" ';
  if (ppgrDef === 'L') { ;
__p += ' selected ';
 } ;
__p += ' >L</option>\n          </select>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="importFmt">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('lis.importFmt') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="importFmt" placeholder="' +
((__t = ( _.i18n('lis.importFmt') )) == null ? '' : __t) +
'" value="' +
((__t = ( importFmt )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="exportFmt">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('lis.exportFmt') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="exportFmt" placeholder="' +
((__t = ( _.i18n('lis.exportFmt') )) == null ? '' : __t) +
'" value="' +
((__t = ( exportFmt )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="comment">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <textarea class="form-control floating-label js-info-input" rows="4" data-field-name="comment" placeholder="' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

