define('headerWellView',[
    'module',
    'marionette',
    'settings',
    'template!headerWellTpl',
    'targetCreateEditView',
    'runDetailsGraphView',
    'underscore',
    'colorUtils',
    'wellDisplayGraphView',
    'app',
    'validationUtils',
    'template!wellPoolTooltipTpl',
    'template!wellPooledTooltipTpl',
    'settings',
    'envHandler',
    'backbone',
    'entities/wells'
], function(
    module,
    Marionette,
    Settings,
    headerWellTpl,
    CreateEditLayoutView,
    RunGraphView,
    _,
    colorUtils,
    DisplayGraphLayoutView,
    App,
    ValidationUtils,
    wellPoolTooltipTpl,
    wellPooledTooltipTpl
) {
    'use strict';

    var HeaderWellView = Marionette.LayoutView.extend({
        template: headerWellTpl,

        ui: {
            btnEditWell: '.btn-edit-well',
            poolLabel: '.pool-label',
            pooledLabel: '.pooled-label'
        },

        events: {
            'click @ui.btnEditWell': 'onEditWell',
            'click .btn-info-well': 'onInfoWell',
            'click .btn-graph-well': 'onBtnGraphClick',
            'click .btn-validate-result': 'onValidateTargetClick',
            'click .btn-negative-result': 'onBtnNegativClick',
            'click .btn-expert-result': 'onBtnExpertClick',
            'click .btn-repeat-pcr-result': 'onBtnRepeatPcrClick',
            'click .btn-repeat-dna-result': 'onBtnRepeatDnaClick',
            'click .sampleid': 'onSampleIdClick'
        },

        onSampleIdClick: function() {
            if (!this.model.get('smptype') || this.model.get('smptype') === 'U' || this.model.get('smptype') === 'P') {
                var sampleId = this.model.get('smpid');
                sampleId = this.modelRun.extractOrderIdFromSampleId(sampleId);
                App.navigate('samplesIds/'+this.modelRun.get('secId')+'/' + sampleId + '/' + this.model.get('refAssay').secId + '/' +
                    this.model.get('pos'), {trigger: true});
            } else {
                App.navigate('samplesIdsQc/'+this.modelRun.get('secId')+'/' + this.model.get('smpid') + '/' + 
                    this.model.get('refAssay').secId + '/' +
                    this.modelRun.getRefMbAModel().secId, {trigger: true});
            }
        },

        serializeData: function() {
            var templateData = this.model.toJSON();
            templateData.results = this.modelRun.getResultForWell(this.model);
            _.each(templateData.results, _.bind(function(r) {
                r.colorGraph = colorUtils.rgbObjectToHexa(colorUtils.colorTarget(r.index, this.modelRun));
                r.userCode = '';
                var user = r.user;
                if (user) {
                    if (user.nickName && user.nickName !== 'null') {
                        r.userCode = user.nickName;
                    } else {
                        r.userCode = user.logonId.substr(0, 600);
                    }
                }
                var user2 = r.user2;
                if (user2) {
                    if (r.userCode) {
                        r.userCode += ', ';
                    }
                    if (user.nickName && user2.nickName !== 'null') {
                        r.userCode += user2.nickName;
                    } else {
                        r.userCode += user2.logonId.substr(0, 600);
                    }
                }
            }, this));
            templateData.channelToDisplay = this.channelToDisplay;

            if (this.modelRun.get('settings') && this.modelRun.get('settings')['OTHER-ASSAY-IND']) {
                var otherAssayInd = this.modelRun.get('settings')['OTHER-ASSAY-IND'].split(';');
                templateData.colorOtherWellSameAssay = otherAssayInd[0];
                templateData.colorOtherWellOtherAssay = otherAssayInd[1];
              } else {
                templateData.colorOtherWellSameAssay = '#FF0000';
                templateData.colorOtherWellOtherAssay = '#FFFFFF';
              }

            templateData = _.extend(templateData, this.model.getErrorObject());

            if (templateData.wellsPool.length > 0) {
                templateData.pooled = true;
            } else {
                templateData.pooled = false;
            }
            if (templateData.wellsPooled.length > 0) {
                templateData.pool = true;
            } else {
                templateData.pool = false;
            }
            return templateData;
        },

        onBtnRepeatDnaClick: function() {
            var validationUtils = new ValidationUtils({
                model: this.model,
                modelRun: this.modelRun,
                target: -1
            });
            validationUtils.repeatDna();
        },

        onBtnRepeatPcrClick: function() {
            var validationUtils = new ValidationUtils({
                model: this.model,
                modelRun: this.modelRun,
                target: -1
            });
            validationUtils.repeatPcr();
        }, 

        onBtnExpertClick: function(event) {
            var validationUtils = new ValidationUtils({
                model: this.model,
                modelRun: this.modelRun,
                target: $(event.target).data('target')
            });
            validationUtils.expertValidation();
        },

        onBtnNegativClick: function (event) {
            var validationUtils = new ValidationUtils({
                model: this.model,
                modelRun: this.modelRun,
                target: $(event.target).data('target')
            });
            validationUtils.changeToNeg();
        },

        onValidateTargetClick: function (event) {
            var validationUtils = new ValidationUtils({
                model: this.model,
                modelRun: this.modelRun,
                target: $(event.target).data('target')
            });
            validationUtils.validate();
        },

        onEditWell: function(event, readOnly) {
            this.createEditView = new CreateEditLayoutView({
                wellModel: this.model,
                runModel: this.modelRun,
                readOnly: readOnly,
                target: $(event.target).data('target'),
                newItem: false
            });
            this.createEditView.show({
                title: _.i18n('well.target.edit'),
                className: 'popupEditResult'
            });
        },

        onInfoWell: function(event) {
            this.onEditWell(event, true);
        },

        onShow: function() {
            this.$el.find('[data-toggle="tooltip"]').tooltip();

            this.showPoolPopover(this.model);
            this.showPooledPopover(this.model);
        },

        showPooledPopover: function(well) {
            if (this.ui.pooledLabel && this.ui.pooledLabel.length > 0) {
              var templateData = well.toJSON();
              var content = Marionette.Renderer.render(wellPooledTooltipTpl, templateData);
              this.ui.pooledLabel.attr('data-content', content);
              this.ui.pooledLabel.popover({
                trigger: 'manual',
                placement: 'bottom',
                html: 'true',
                delay: {
                  show: '300',
                  hide: '500'
                },
                container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content')
              }).on('mouseenter', function () {
                var _this = this;
                $(this).popover('show');
                $('.popover').on('mouseleave', function () {
                    $(_this).popover('hide');
                });
              }).on('mouseleave', function () {
                var _this = this;
                setTimeout(function () {
                    if (!$('.popover:hover').length) {
                        $(_this).popover('hide');
                    }
                }, 300);
            }).data('bs.popover').tip().addClass('pool-popover');
            }
        },

        showPoolPopover: function(well) {
            if (this.ui.poolLabel && this.ui.poolLabel.length > 0 && (well.get('smpid') || well.get('smptype')) && well.get('refAssay')) {
              var templateData = well.toJSON();
              var content = Marionette.Renderer.render(wellPoolTooltipTpl, templateData);
              var t = $(this.ui.poolLabel);
              t.attr('data-content', content);
              t.popover({
                trigger: 'manual',
                placement: 'bottom',
                html: 'true',
                delay: {
                  show: '300',
                  hide: '100'
                },
                container: this.imagePopoverContainer ? this.imagePopoverContainer : $('#content')
              }).on('mouseenter', function () {
                var _this = this;
                $(this).popover('show');
                $('.popover').on('mouseleave', function () {
                    $(_this).popover('hide');
                });
              }).on('mouseleave', function () {
                var _this = this;
                setTimeout(function () {
                    if (!$('.popover:hover').length) {
                        $(_this).popover('hide');
                    }
                }, 300);
            }).data('bs.popover').tip().addClass('pool-popover');
            }
        },

        onBtnGraphClick: function(event) {
            var Col = App.request('well:get-collection', this.modelRun.get('secId'), null, null, this.model.get('refAssay').secId, null);
            Col.fetch().then(_.bind(function() {
                this.data = this.modelRun.getAssaysTargets();
                this.displayGraphView = new DisplayGraphLayoutView({
                    models: Col.models,
                    model: this.modelRun.clone(),
                    newItem: false,
                    highlight: this.model
                });
                this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-validate:click', 
                    _.bind(this.onWellsValidateClick, this));
                this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-validate2:click', 
                    _.bind(this.onWellsValidate2Click, this));
                this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-invalidate:click', 
                    _.bind(this.onWellsInValidateClick, this));
                this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-repeat-dna:click', 
                    _.bind(this.onWellsRepeatDnaClick, this));
                this.displayGraphView.listenTo(this.displayGraphView, 'well-popup:assay-target-expert:click', 
                    _.bind(this.onWellsExpertClick, this));
                this.displayGraphView.allModel = Col.models;
                this.channelToDisplay = this.displayGraphView.channelToDisplay = $(event.target).data('target');
                this.displayGraphView.graphToDisplay = this.modelRun.getGraphToDisplay(this.modelRun);
                //this.displayGraphView.runAt = this.runAt;
                this.displayGraphView.hideOkButton = true;
                this.displayGraphView.hideNextPreviousButton = true;
                this.displayGraphView.selectionMode = false;                
    
                this.displayGraphView.show({
                    title: '',
                    className: 'popupTarget'
                });
            }, this));
        },

        onWellsValidateClick: function() {
            this.changeValidationStatus('well:target:validate');
        },
    
        onWellsValidate2Click: function() {
          this.changeValidationStatus('well:target:validate2');
        },
    
        onWellsInValidateClick: function() {
            this.changeValidationStatus('well:target:invalidate', -1);
        },

        onWellsRepeatDnaClick: function() {
            this.changeValidationStatus('well:target:invalidateallextract', -1);
        },

        onWellsExpertClick: function() {
            this.changeValidationStatus('well:target:validate:expert-required');
        },

        changeValidationStatus: function(status, chan) {
            var target = this.channelToDisplay;
            var lstString = {
                lst: []
            };
            _.each(this.displayGraphView.currentModels, function(well) {
                lstString.lst.push(well.get('secId'));
            });
            App.request(status, this.modelRun.get('secId'),
                    this.model.get('refAssay').secId, chan?chan:target, lstString).then(_.bind(function(result) {
                if(result.run) {
                    App.trigger('well:refresh:well', null, result.run.wells);
                } else {
                    App.trigger('well:refresh:well', null, result.wells);
                }
                this.emptyView.destroy();
            },this)).fail(_.bind(function (err) {
                if (err && err.status === 405) {
                    alert(_.i18n('error.noPermission'));
                    this.emptyView.destroy();
                }
            }, this));
        }
    });

    module.exports = HeaderWellView;
});
