
define('template!runCreateLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container" style="min-height: 150px;">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="radio pull-left radio-primary col-xs-12">\n        <label>\n          <input type="radio" class="create-run-radio create-run-radio-template" name="create-run-radio" value="fromTemplate" checked>\n          ' +
((__t = ( _.i18n('run.fromTemplate') )) == null ? '' : __t) +
'\n        </label>\n      </div>\n      <div class="createRunFromTemplate">\n        <div class="form-group" data-field-name="refRunTemplate">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('run.runTemplate') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            <div class="js-select-run-template-region"></div>\n          </div>\n        </div>\n      </div>\n      <div class="radio pull-left radio-primary col-xs-12">\n        <label>\n          <input type="radio" class="create-run-radio create-run-radio-empty" name="create-run-radio" value="fromEmpty">\n          ' +
((__t = ( _.i18n('run.fromEmpty') )) == null ? '' : __t) +
'\n        </label>\n      </div>\n      <div class="createRunFromEmpty hidden">\n        <div class="form-group" data-field-name="name">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            <input type="text" class="form-control floating-label js-info-input run-input-name" data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n          </div>\n        </div>\n      </div>\n      <div class="radio pull-left radio-primary col-xs-12">\n        <label>\n          <input type="radio" class="create-run-radio create-run-radio-plate-layout" name="create-run-radio" value="fromPlateLayout">\n          ' +
((__t = ( _.i18n('run.fromPlateLayout') )) == null ? '' : __t) +
'\n        </label>\n      </div>\n      <div class="createRunFromPlateLayout hidden">\n        <div class="form-group">\n          <label class="col-xs-3 control-label"> </label>\n          <div class="col-xs-9">\n            <input type="file"\n              class="js-upload-runs-plate-layout" data-icon="true"\n              accept=".csv"/>\n          </div>\n        </div>\n      </div>\n      <div class="radio pull-left radio-primary col-xs-12">\n        <label>\n          <input type="radio" class="create-run-radio create-run-radio-cycler-export" name="create-run-radio" value="fromCyclerExport">\n          ' +
((__t = ( _.i18n('run.fromCyclerExport') )) == null ? '' : __t) +
'\n        </label>\n      </div>\n      <div class="createRunFromCyclerExport hidden">\n        <div class="form-group">\n          <label class="col-xs-3 control-label"> </label>\n          <div class="col-xs-9">\n            <input type="file"\n              class="js-upload-runs-cycler-export" data-icon="true"\n              accept=".xlsx,.xls,.xml,.rdml,.csv"/>\n          </div>\n        </div>\n        <div class="form-group">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('run.refKitProt') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            <div class="js-select-kitprot-region-from-cycler-export"></div>\n          </div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

