/**
 * Created by BFR on 18/04/2019.
 */
define('sampleIdsQcGridView',[
  'module',  
  'marionette',
  'underscore',
  'app',
  'jquery',
  'settings',
  'jqgridView',
  'template!sampleIdsQcGridTpl',
  'wellResultIconView',
  'dateUtils',
  'qcLayoutView',
  'entities/wells',
  'entities/runs'
], function(
  module,
  Marionette,
  _,
  App,
  $,
  Settings,
  JqGridView,
  viewTpl,
  WellResultIconView,
  dateConverter,
  QcLayoutView
) {
  'use strict';

  var SampleIdsWellTableView = JqGridView.extend({
    filterEnable: false,
    GRID_MIN_WIDTH: 600,
    template: viewTpl,
    channelToDisplay: null,

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-well',
        jqGridPager: '#jq-grid-pager-list',
        jqgRow: 'tr.jqgrow'
      }, this.gridUi);
    },

    events: {
        'mousedown .ui-jqgrid-sortable': 'onHeaderClick'
    },

    onShow: function() {
      $.material.init();
    },

    initialize: function() {
      App.on('well:refresh:well', this.onWellChange, this);
    },

    onWellChange: function(well, wells) {
      if (!wells) {
          wells = [];
      }
      if (well) {
          wells.push(well.toJSON());
      }
      if (wells) {
          _.each(wells, _.bind(function(w) {
              var coltd, html, linetr = this.$el.find('#' + w.secId);
              var item = _.find(this.codeTableData, function(item){ return item.well.secId === w.secId; });
              if (item) {
                  item.well = w;
                  for (var i = 1 ; i <= 6 ; i++) {
                      coltd = linetr.find('.well-ch' + i);
                      html = this.squareIconFormatter(null, {colModel: {name: i.toString()}}, item);
                      coltd.html(html);
                  }
              }
          }, this));
      }
    },

    serializeData: function() {
      var templateData = {};
      templateData.editEnable = false;
      if (this.channelToDisplay) {
          templateData.editEnable = true;
      }
      templateData.sortOn = this.sortOn;
      return templateData;
    },

    pagination: function() {
      return {
          first: 0,
          rowCount: 0,
          pageSize: 30,
          currentPage: 1
      };
    },

    onHeaderClick: function(e) {
        this.qcPopup = new QcLayoutView({
            data: this.codeTableData,
            channel: Number(e.currentTarget.id.replace('jqgh_jq-grid-well_', ''))
        });
        this.qcPopup.show({
            title: _.i18n('run.qc'),
            className: 'popupQc'
        });
    },

    gridOptions: function(data) {
       var defaultsOptions, options, 
        _colModel = [];

        _colModel.push({
            label: '',
            name: 'well.secId',
            key: true,
            hidden: true
        });

        _colModel.push({
            label: _.i18n('well.refRun'),
            name: 'refRun.creatDate',
            classes: 'well-list-label',
            formatter: this.dateTimeFormatter,
            search: false,
            sortable: false,
            index: 'refRun.creatDate',
            width: 80,
            cellattr: this.nameTooltipFormatter
        });

        _colModel.push({
            label: _.i18n('well.refRun'),
            name: 'refRun.name',
            classes: 'well-list-label',
            formatter: this.lastCharFormater,
            search: false,
            sortable: false,
            index: 'refRun.name',
            width: 80,
            cellattr: this.nameTooltipFormatter
        });

        _colModel.push({
            label: _.i18n('well.pos'),
            name: 'well.pos',
            classes: 'well-list-pos-label',
            formatter: this.wellDetailLinkFormatter,
            search: false,
            sortable: false,
            index: 'pos',
            width: 40,
            cellattr: this.nameTooltipFormatter
        });

        if (this.channelToDisplay) {
            _colModel.push({
                label: _.i18n('well.target'),
                name: 'well.refAssay',
                classes: 'well-list-label',
                formatter: _.bind(this.displayTargetFormater, this),
                search: false,
                sortable: false,
                index: 'refAssay',
                width: 50,
                cellattr: this.nameTooltipFormatter
            });
        }

        _colModel.push({
            label: _.i18n('well.ch1'),
            name: '1',
            classes: 'well-list-label well-ch1',
            formatter: _.bind(this.squareIconFormatter, this),
            search: false,
            sortable: true,
            index: 'res1Code',
            title: false,
            width: 30
        });

        _colModel.push({
            label: _.i18n('well.ch2'),
            name: '2',
            classes: 'well-list-label well-ch2',
            formatter: _.bind(this.squareIconFormatter, this),
            search: false,
            sortable: false,
            index: 'res1Code',
            title: false,
            width: 30
        });

        _colModel.push({
            label: _.i18n('well.ch3'),
            name: '3',
            classes: 'well-list-label well-ch3',
            formatter: _.bind(this.squareIconFormatter, this),
            search: false,
            sortable: false,
            index: 'res1Code',
            title: false,
            width: 30
        });
        _colModel.push({
            label: _.i18n('well.ch4'),
            name: '4',
            classes: 'well-list-label well-ch4',
            formatter: _.bind(this.squareIconFormatter, this),
            search: false,
            sortable: false,
            index: 'res1Code',
            title: false,
            width: 30
        });
        _colModel.push({
            label: _.i18n('well.ch5'),
            name: '5',
            classes: 'well-list-label well-ch5',
            formatter: _.bind(this.squareIconFormatter, this),
            search: false,
            sortable: false,
            index: 'res1Code',
            title: false,
            width: 30
        });
        _colModel.push({
            label: _.i18n('well.ch6'),
            name: '6',
            classes: 'well-list-label well-ch6',
            formatter: _.bind(this.squareIconFormatter, this),
            search: false,
            sortable: false,
            index: 'res1Code',
            title: false,
            width: 30
        });

      if (this.channelToDisplay) {
          _colModel.push({
              label: _.i18n('well.res'+this.channelToDisplay+'Num'),
              name: 'well.resNum',
              classes: 'well-list-label',
              formatter: _.bind(this.resNumFormatter, this),
              search: false,
              sortable: false,
              index: 'resNum'
          });
      }
      defaultsOptions = this.gridInitOptions(data);
      options = {
          colModel: _colModel,
          sortname: this.sortOn?this.sortOn:'date',
          height: 610
      };

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    nameTooltipFormatter: function(cellValue, options, object) {
      return ' title="'+object.refRun.name+'"';
    },

    dateTooltipFormatter: function(cellValue, options, object) {
      var date = dateConverter.toDateTimeFormatString(object.refRun.creatDate);
      return ' title="'+date+'"';
    },

    wellDetailLinkFormatter: function(cellValue, options, object) {
      if (cellValue === null || cellValue === undefined) { cellValue = ''; }

      return '<a href="/#runs/'+object.refRun.secId+'/details/row/'+cellValue[0]+'/column/'+
          cellValue[1]+cellValue[2]+'" class="cell-default" data-row-id="' +
          options.rowId + '">' + cellValue + '</a>';
    },

    displayTargetFormater: function(cellValue) {
      var targetName = this.model.getTargetName(cellValue, this.channelToDisplay);
      return '<span class="cell-default">' + targetName + '</span>';
    },

    resNumFormatter: function(cellValue, call, object) {
        var model = App.request('well:model', object.well);
        return '<span class="cell-default">' + model.getResFormated(this.channelToDisplay) + '</span>';
    },

    squareIconFormatter: function(cellValue, call, object) {
      var runModel = App.request('run:model', object.refRun);
      var start, end;
      start = '<div class="row m-b-0">';
      var i = call.colModel.name;
        this['iconResult'+i+'View'] = new WellResultIconView({model: object.well, modelRun: runModel, channel: Number(i)});
        this['iconResult'+i+'View'].render();
        start += '<div class="col-xs-12 p-r-0">';
        start += this['iconResult'+i+'View'].$el.html();
        start += '<span class="col-xs-1 p-l-10" style="min-width: 5px; min-height: 20px; font-size:12px;">'+
            (object.well['res'+i+'Num1']?object.well['res'+i+'Num1']:' ') + '</span>';
        start += '</div>';

      end = '</div>';

      return start + end;
    },

    onLoadComplete: function(data) {
      this.codeTableData = data;
      if(data && data.length) {
        this.ui.jqGrid.setSelection(data[0].well.secId).click();
      }
    },

    onPreselectRow: function(rowId) {
      if (this.codeTableData) {
        this.ui.jqgRow.removeClass('gridrow-selected');
        this.ui.jqGrid.find('tr#' + rowId).addClass('gridrow-selected');
        this.trigger('well-table:loaded', rowId);
      } else {
        return;
      }
    },

    onCheckRowCount: function(rowCount) {
      if (rowCount === 0) {
        this.trigger('well:check-rowcount');
      }
    }
  });

  module.exports = SampleIdsWellTableView;
});
