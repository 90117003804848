define('wellEditSmpIdView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!wellEditSmpIdTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'autocompleteView',
  'settings',
  'jquery'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  AutocompleteView,
  Settings,
  $
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    models: [],

    ui: {
      input: '.js-info-input',
      cmbsSmpid: '.cmb-smpid'
    },

    events: {
      'change @ui.input': 'onInputChange',
      'click .js-cancel-popup': 'hide',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      selectSmpid: '.js-select-kitc-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
    ],

    onInputChange: function(e) {
      var $target = $(e.currentTarget),
          value = $target.val(),
          secId = $target.data('secid'),
          fileName = $target.data('field-name');
      var model = _.find(this.models, function(item) { return item.get('secId') === secId;});
      model.set(fileName, value);

      if ($target.data('field-name') === 'smptype') {
        switch(value) {
          case 'E':
              this.$el.find('.div-input-smpid[data-secid=' + secId + ']').hide();
              this.$el.find('.div-cmb-smpid[data-secid=' + secId + ']').hide();
              break;
          case 'PC':
          case 'NC':
          case 'C':
              this.$el.find('.div-input-smpid[data-secid=' + secId + ']').hide();
              var cmb = this.$el.find('.div-cmb-smpid[data-secid=' + secId + ']');
              cmb.show();
              cmb.find('.js-form-input').val('');
              break;
          default:
              this.$el.find('.div-cmb-smpid[data-secid=' + secId + ']').hide();
              var input = this.$el.find('.div-input-smpid[data-secid=' + secId + ']');
              input.show();
              input.find('.js-info-input').change();
              break;
        }
      }
    },

    initialize: function(options) {
      this.models = [];
      this.modelRun = options.modelRun;
      _.each(options.models, _.bind(function(model) {
        var cModel = model.clone();
        if (!cModel.get('smptype')) {
          cModel.set('smptype', 'P');
        }
        this.models.push(model.clone());
      }, this));
    },

    serializeData: function() {
      var templateData = {};
      templateData.models = this.models;
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      $.material.init();

      _.each(this.ui.cmbsSmpid, _.bind(function(item) {
        var r, model, secId = $(item).data('secid');
        r = this.addRegion('.cmb-smpid-'+secId, '.cmb-smpid-'+secId);

        model = _.find(this.models, function(model){ return model.get('secId') === secId; });

        var kitCView = new AutocompleteView(
            this._getAutocompleteOptions(model, 'smpid', 'smpid', 'code', 'v2/kits/' + model.get('refAssay').refKit.secId + '/kitcs',
                'selectkitc.placeholder', 'code', this.onSmpidChange)
        );
        r.show(kitCView);
      }, this));

      this.triggerMethod('enable:cancel:confirm');
    },

    _getAutocompleteOptions: function(model, modelProperty, valueProperty, queryProperty, urlPath, placeHolderLabel,
                                      valueKey, callback) {
      return {
        model: model,
        type: 'remote',
        remote: Settings.url('compuzz', urlPath),
        valueKey: valueKey,
        apiKey: 'secId',
        placeholder: _.i18n(placeHolderLabel),
        value: model.get(valueProperty),
        name: modelProperty,
        onPressKey: true,
        lazyLoad: true,
        keys: {
          query: queryProperty,
          limit: 'pageSize'
        },
        callback: _.bind(callback?callback:this.onChangeAutoComplete, this)
      };
    },

    onSmpidChange: function(fieldName, model, cmb) {
      cmb.options.model.set(fieldName, model.get('code'));
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        var list = [];
        _.each(this.models, function(wellModel) {
          var modelToSave = wellModel.clone();
          modelToSave.set('amplCurve1', null);
          modelToSave.set('amplCurve2', null);
          modelToSave.set('amplCurve3', null);
          modelToSave.set('amplCurve4', null);
          modelToSave.set('amplCurve5', null);
          modelToSave.set('amplCurve6', null);
          modelToSave.set('meltCurve1', null);
          modelToSave.set('meltCurve2', null);
          modelToSave.set('meltCurve3', null);
          modelToSave.set('meltCurve4', null);
          modelToSave.set('meltCurve5', null);
          modelToSave.set('meltCurve6', null);
          list.push(modelToSave.save());
        });

        $.when(list).done(_.bind(function (){
          this.triggerMethod('saved');
          this.hide();
          App.trigger('well:mass:edit', this.models);
        }, this));
      }
    },

    onSave: function() {

    },

    hide: function() {
      var runSecId = this.modelRun?this.modelRun.get('secId'):null;
      this.trigger('popup:hide');
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();

      if (App.getCurrentRoute() === 'runs/' + runSecId + '/assays-targets') {
        App.navigate('runs/empty', {trigger: false, replace: true});
        App.navigate('runs/' + this.modelRun.get('secId') + '/assays-targets', {trigger: true, replace: true});
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.triggerMethod('hide:label');
      }
    }
  });

  module.exports = CreateEditLayoutView;
});
