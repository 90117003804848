/**
 * Created by bfr on 10/04/2020.
 */
define('racksShowController',[
  'module',
  'logger',
  'underscore',
  'app',
  'racksShowView',
  'jquery',
  'bootbox',
  'emptyView',
  'rackCreateEditLayoutView',
  'rackCreateFromUploadView',
  'entities/racks'
], function(
  module,
  Logger,
  _,
  App,
  View,
  $,
  bootbox,
  EmptyView,
  CreateEditLayoutView,
  CreateFromUploadView
) {
  'use strict';

  var RacksController = {
    showRacks: function() {
      var view = new View();

      this.view = view;

      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      view.listenTo(view, 'onLoadSelectedRacks', _.bind(this.onLoadSelectedRacks, this));
      view.listenTo(view, 'rack:delete', _.bind(this.onDelete, this));
      view.listenTo(view, 'racks:import-xls-file', _.bind(this.onImportXls, this));
      
      App.regions.getRegion('main').currentView.getRegion('content').show(view);
    },

    showNewRack: function() {
      this.createEditView = new CreateEditLayoutView({
        rackModel: App.request('rack:model'),
        newItem: true
      });
      this.createEditView.show({
        title: _.i18n('rack.new')
      });
    },

    onGridLoaded: function() {
      var that = this, data;

      data = {
        url: App.request('rack:get-collection-url'),
        rowCountUrl: App.request('rack:get-count-url')
      };

      that.view.displayGrid(data);
    },

    onImportXls: function(fileInfo) {
      var that = this;
      var createFromUploadView = new CreateFromUploadView();
      createFromUploadView.listenTo(createFromUploadView, 'popup-create:selectLis', _.bind(function(lis) {
          this.view.onDestroy();
          this.emptyView = new EmptyView();
          App.regions.getRegion('main').currentView.getRegion('content').show(this.emptyView);

          $.when(App.request('rack:importXlsFile', fileInfo, lis?lis.get('secId'):null)).done(function() {
              that.emptyView.destroy();
              that.showRacks();
          }).fail(function() {
              that.emptyView.destroy();
              that.showRacks();
              bootbox.alert(_.i18n('common.errorOccuredWhileImporting'), function () {
                  return;
              });
          });
      }, this));

      createFromUploadView.show({
          title: _.i18n('sample.import')
      });
    },

    onLoadSelectedRacks: function(first, last, params) {
      $.when(App.request('rack:get-collection-to-page', params))
          .done(_.bind(function(racks) {
            this.view.triggerMethod('selectRacks', first, last, racks);
            $('#content-blinder').hide();
          }, this))
          .fail(function() {
            $('#content-blinder').hide();
          });
    },

    onDelete: function(secId){
      bootbox.confirm(_.i18n('rack.confirmDelete'), _.bind(function(result) {
        if(result){
          App.request('rack:delete', secId)
              .done(_.bind(function(){
                this.view.updatePagination('reloadGrid');
              }, this));
        }
    }, this));
    }
  };

  module.exports = RacksController;
});
