/**
 * Created by RKL on 21/08/2015.
 */
define('samplesShowView',[
  'module',
  'underscore',
  'backbone',
  'jqgridActionsButtonFormatterActions',
  'jqgridActionsButtonFormatterView',
  'jqgridView',
  'dateUtils',
  'urlUtil',
  'app',
  'settings',
  'template!samplesViewTpl'
], function (
  module,
  _,
  Backbone,
  availableActions,
  ActionButtonsView,
  JqGridView,
  dateConverter,
  UrlUtil,
  App,
  Settings,
  viewTpl
) {
  'use strict';

  var SamplesView = JqGridView.extend({
    template: viewTpl,
    filtersName: 'samplesFilters',

    regions: {
      actions: '.js-action-buttons-region'
    },

    ui: function () {
      return _.extend({
        jqGrid: '#jq-grid-list',
        uploadSamples: '.js-upload-samples',
        jqGridPager: '#jq-grid-pager-list',
        deleteRun: '.delete-run-link'
      }, this.gridUi);
    },

    events: {
      'click @ui.deleteRun': 'onDeleteRun',
      'change @ui.uploadSamples': 'onUploadSamples'
    },

    onShow: function () {
      $.material.init();

      var actionsButtonView = this._getActionButtonsView();

      this.listenTo(actionsButtonView, 'action', this._onAction);

      this.getRegion('actions').show(actionsButtonView);
    },

    _getActionButtonsView: function () {
      var actions = ['import'],
        actionsCollection = new Backbone.Collection();
      _.each(actions, function (action) {
        actionsCollection.push(availableActions[action]);
      });

      return new ActionButtonsView({
        collection: actionsCollection,
        dontShowIcons: true
      });
    },

    _onAction: function (action) {
      switch (action) {
        case 'import':
          this.$('#sample-excel-input').trigger('click');
          break;
      }
    },

    onUploadSamples: function (e) {
      if (e && e.target && e.target.files && e.target.files[0]) {
        this.uploadExcel(e.target.files[0]);
      }
    },

    uploadExcel: function (fileInfo) {
      this.ui.uploadSamples.val('');
      this.trigger('sample:import-xls-file', fileInfo);
    },

    serializeData: function () {
      this.selectedRuns = Settings.get('selectedSamples') || [];
      var templateData = {
        'type': 'samples'
      };
      return templateData;
    },

    onDeleteRun: function (event) {
      this.trigger('sample:delete', $(event.currentTarget).find('span').attr('data-row-id'));
    },

    gridOptions: function (data) {
      var defaultsOptions = this.gridInitOptions(data),
        options = {
          colModel: [{
            label: '',
            name: 'secId',
            key: true,
            hidden: true
          }, {
            label: _.i18n('sample.sampleId'),
            name: 'sampleId',
            classes: 'sample-link',
            formatter: this.defaultFormatter,
            search: false,
            index: 'sampleId'
          }, {
            label: _.i18n('sample.orderId'),
            name: 'orderId',
            classes: 'sample-link',
            formatter: this.defaultFormatter,
            search: false,
            index: 'orderId'
          }, {
            label: _.i18n('sample.patId'),
            name: 'patId',
            classes: 'sample-link',
            formatter: this.defaultFormatter,
            search: false,
            index: 'patId'
          }, {
            label: _.i18n('sample.refLis'),
            name: 'refLis.code',
            classes: 'sample-link',
            formatter: this.defaultFormatter,
            search: false,
            index: 'refLis.code'
          }, {
            label: _.i18n('sample.sampleDt'),
            name: 'sampleDt',
            classes: 'sample-link',
            formatter: this.dateFormatter,
            search: false,
            index: 'sampleDt'
          }, {
            label: ' ',
            name: 'delete',
            search: false,
            classes: 'delete-run-link delete-link',
            formatter: this.deleteIconFormatter,
            width: 20
          }
          ],

          onSelectRow: function (rowId, bool, e) {
            if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
              App.navigate('samples/' + rowId, { trigger: true });
            }
          }
        };

      options = _.defaults(options, defaultsOptions);

      return options;
    }
  });

  module.exports = SamplesView;
});
