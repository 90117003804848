define('rackCreateEditLayoutView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!rackCreateEditLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  createEditLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: createEditLayoutTpl,

    ui: {
      input: '.js-info-input',
      inputName: '.rack-input-name'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'focus @ui.input' : 'onFocus',
      'change @ui.input': 'onInputChange',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
    ],

    initialize: function(options) {
      this.model = options.rackModel.clone();
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.newItem = this.options.newItem;
      return templateData;
    },

    onShow: function() {
      $.material.init();
      this.triggerMethod('enable:cancel:confirm');
    },

    onConfirm: function() {
      if(this.validate(this.fieldsToValidate)) {
        this.rackEls = this.model.get('rackEls');
        this.model.set('rackEls', null);
        this.model.save();
      }
    },

    onSave: function(model) {
      var modelJSON = model.toJSON();
      this.model.set('rackEls', this.rackEls);
      this.options.rackModel.set(modelJSON);
      this.triggerMethod('saved');
      App.trigger('rack:header:rerender', modelJSON);
      this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
      if (App.getCurrentRoute() ===  'racks/new') {
        App.navigate('racks', {trigger: true});
      } else {
        App.navigate('racks/' + this.options.rackModel.get('secId'), {trigger: true});
      }
    },

    onSaveError: function(model, response) {
      if(response.status === 409) {
        this.onDuplicateCode();
        this.triggerMethod('hide:label');
      }
    },

    onDuplicateCode: function() {
      this.ui.endUserIdInput.popover('show');
      this.ui.endUserIdInput.addClass('invalid');
      this.ui.endUserIdInput.closest('div[data-field-name=code').addClass('has-error');
    }
  });

  module.exports = CreateEditLayoutView;
});
