
define('template!rackCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group" data-field-name="rackName">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('rack.rackName') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input run-input-name" data-field-name="rackName" placeholder="' +
((__t = ( _.i18n('rack.rackName') )) == null ? '' : __t) +
'" value="' +
((__t = ( rackName )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="rackId">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('rack.rackId') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="rackId" placeholder="' +
((__t = ( _.i18n('rack.rackId') )) == null ? '' : __t) +
'" value="' +
((__t = ( rackId )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="lRackId">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('rack.lRackId') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="lRackId" placeholder="' +
((__t = ( _.i18n('rack.lRackId') )) == null ? '' : __t) +
'" value="' +
((__t = ( lRackId )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="eRackId">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('rack.eRackId') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="eRackId" placeholder="' +
((__t = ( _.i18n('rack.eRackId') )) == null ? '' : __t) +
'" value="' +
((__t = ( eRackId )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="rackCType">\n        <label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("rack.rackCType") )) == null ? '' : __t) +
':</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input" data-field-name="rackCType" value="' +
((__t = ( rackCType )) == null ? '' : __t) +
'">\n            <option value="ORI" ';
  if (nbCol === "ORI") { ;
__p += ' selected ';
 } ;
__p += ' >ORI</option>\n            <option value="LYS" ';
  if (nbCol === "LYS") { ;
__p += ' selected ';
 } ;
__p += ' >LYS</option>\n            <option value="ELU" ';
  if (nbCol === "ELU") { ;
__p += ' selected ';
 } ;
__p += ' >ELU</option>\n          </select>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="nbCol">\n        <label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("rack.nbCol") )) == null ? '' : __t) +
':</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input" data-field-name="nbCol" value="' +
((__t = ( nbCol )) == null ? '' : __t) +
'">\n            <option value="1" ';
  if (nbCol === 1) { ;
__p += ' selected ';
 } ;
__p += ' >1</option>\n            <option value="2" ';
  if (nbCol === 2) { ;
__p += ' selected ';
 } ;
__p += ' >2</option>\n            <option value="3" ';
  if (nbCol === 3) { ;
__p += ' selected ';
 } ;
__p += ' >3</option>\n            <option value="4" ';
  if (nbCol === 4) { ;
__p += ' selected ';
 } ;
__p += ' >4</option>\n            <option value="5" ';
  if (nbCol === 5) { ;
__p += ' selected ';
 } ;
__p += ' >5</option>\n            <option value="6" ';
  if (nbCol === 6) { ;
__p += ' selected ';
 } ;
__p += ' >6</option>\n            <option value="7" ';
  if (nbCol === 7) { ;
__p += ' selected ';
 } ;
__p += ' >7</option>\n            <option value="8" ';
  if (nbCol === 8) { ;
__p += ' selected ';
 } ;
__p += ' >8</option>\n            <option value="9" ';
  if (nbCol === 9) { ;
__p += ' selected ';
 } ;
__p += ' >9</option>\n            <option value="10" ';
  if (nbCol === 10) { ;
__p += ' selected ';
 } ;
__p += ' >10</option>\n            <option value="11" ';
  if (nbCol === 11) { ;
__p += ' selected ';
 } ;
__p += ' >11</option>\n            <option value="12" ';
  if (nbCol === 12) { ;
__p += ' selected ';
 } ;
__p += ' >12</option>\n          </select>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="nbRows">\n        <label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("rack.nbRows") )) == null ? '' : __t) +
':</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input" data-field-name="nbRows" value="' +
((__t = ( nbRows )) == null ? '' : __t) +
'">\n            <option value="1" ';
  if (nbRows === 1) { ;
__p += ' selected ';
 } ;
__p += ' >1</option>\n            <option value="2" ';
  if (nbRows === 2) { ;
__p += ' selected ';
 } ;
__p += ' >2</option>\n            <option value="3" ';
  if (nbRows === 3) { ;
__p += ' selected ';
 } ;
__p += ' >3</option>\n            <option value="4" ';
  if (nbRows === 4) { ;
__p += ' selected ';
 } ;
__p += ' >4</option>\n            <option value="5" ';
  if (nbRows === 5) { ;
__p += ' selected ';
 } ;
__p += ' >5</option>\n            <option value="6" ';
  if (nbRows === 6) { ;
__p += ' selected ';
 } ;
__p += ' >6</option>\n            <option value="7" ';
  if (nbRows === 7) { ;
__p += ' selected ';
 } ;
__p += ' >7</option>\n            <option value="8" ';
  if (nbRows === 8) { ;
__p += ' selected ';
 } ;
__p += ' >8</option>\n          </select>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

