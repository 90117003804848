/**
 * Created by BFR on 01/04/2020.
 */
define('validationUtils',[
    'module',
    'app',
    'marionette',
    'settings',
    'underscore',
    'entities/wells'
  ], function(
    module,
    App,
    Marionette,
    Settings,
    _
  ) {
    'use strict';
  
    var ValidationUtils = Marionette.Object.extend({

        expertValidation: function() {
            this.options.type = 'validation-expert';
            this.changeValidationStatus2('well:target:validate:expert-required');
        },

        changeToNeg: function() {
            if(this.options.modelsCopy === undefined && this.options.models && this.options.models.length > 0) {
                this.options.modelsCopy = [];
                _.each(this.options.models, _.bind(function(m) {
                    this.options.modelsCopy.push(m);
                }, this));
            }
            if(this.options.modelsCopy && this.options.modelsCopy.length > 0) {
                this.current = this.changeToNeg;
                this.options.model = this.options.modelsCopy.pop();
            }
            this.options.type = 'change-to-neg';
            if (Settings.get('currentUser').type && Settings.get('currentUser').type.indexOf('VAL2') !== -1) {
                this.changeValidationStatus2('well:target:validate2');
            } else {
                this.changeValidationStatus2('well:target:validate');
            }
        },
        
        validate: function() {
            if(this.options.modelsCopy === undefined && this.options.models && this.options.models.length > 0) {
                this.options.modelsCopy = [];
                _.each(this.options.models, _.bind(function(m) {
                    this.options.modelsCopy.push(m);
                }, this));
            }
            if(this.options.modelsCopy && this.options.modelsCopy.length > 0) {
                this.current = this.validate;
                this.options.model = this.options.modelsCopy.pop();
            }
            this.options.type = 'confirm-result';
            if (Settings.get('currentUser').type && Settings.get('currentUser').type.indexOf('VAL2') !== -1) {
                this.changeValidationStatus2('well:target:validate2');
            } else {
                this.changeValidationStatus2('well:target:validate');
            }
        },

        repeatPcr: function() {
            if(this.options.modelsCopy === undefined && this.options.models && this.options.models.length > 0) {
                this.options.modelsCopy = [];
                _.each(this.options.models, _.bind(function(m) {
                    this.options.modelsCopy.push(m);
                }, this));
            }
            if(this.options.modelsCopy && this.options.modelsCopy.length > 0) {
                this.current = this.repeatPcr;
                this.options.model = this.options.modelsCopy.pop();
            }
            this.options.type = 'repeat';
            App.request('well:target:invalidate', this.options.modelRun.get('secId'),
                this.options.model.get('refAssay').secId, this.options.target, {lst:[this.options.model.get('secId')]})
                    .then(_.bind(this.refreshModel, this)).fail(_.bind(function (err) {
                if (err && err.status === 405) {
                    alert(_.i18n('error.noPermission'));
                }
            }, this));
        },

        repeatDna: function() {
            this.options.type = 'repeat-extract';
            App.request('well:target:invalidateallextract', this.options.modelRun.get('secId'),
                this.options.model.get('refAssay').secId, this.options.target, {lst:[this.options.model.get('secId')]})
                    .then(_.bind(this.refreshModel, this)).fail(_.bind(function (err) {
                if (err && err.status === 405) {
                    alert(_.i18n('error.noPermission'));
                }
            }, this));
        },

        repeatDnaDil12: function() {
            if(this.options.modelsCopy === undefined && this.options.models && this.options.models.length > 0) {
                this.options.modelsCopy = [];
                _.each(this.options.models, _.bind(function(m) {
                    this.options.modelsCopy.push(m);
                }, this));
            }
            if(this.options.modelsCopy && this.options.modelsCopy.length > 0) {
                this.current = this.repeatDnaDil12;
                this.options.model = this.options.modelsCopy.pop();
            }
            this.options.type = 'repeat-extract-dil12';
            App.request('well:target:invalidateallextract', this.options.modelRun.get('secId'),
                this.options.model.get('refAssay').secId, this.options.target, {lst:[this.options.model.get('secId')]})
                    .then(_.bind(this.refreshModel, this)).fail(_.bind(function (err) {
                if (err && err.status === 405) {
                    alert(_.i18n('error.noPermission'));
                }
            }, this));
        },

        repeatDnaDil15: function() {
            if(this.options.modelsCopy === undefined && this.options.models && this.options.models.length > 0) {
                this.options.modelsCopy = [];
                _.each(this.options.models, _.bind(function(m) {
                    this.options.modelsCopy.push(m);
                }, this));
            }
            if(this.options.modelsCopy && this.options.modelsCopy.length > 0) {
                this.current = this.repeatDnaDil15;
                this.options.model = this.options.modelsCopy.pop();
            }
            this.options.type = 'repeat-extract-dil15';
            App.request('well:target:invalidateallextract', this.options.modelRun.get('secId'),
                this.options.model.get('refAssay').secId, this.options.target, {lst:[this.options.model.get('secId')]})
                    .then(_.bind(this.refreshModel, this)).fail(_.bind(function (err) {
                if (err && err.status === 405) {
                    alert(_.i18n('error.noPermission'));
                }
            }, this));
        },

        refreshModel: function(result) {
            var item = _.find(result.run?result.run.wells:result.wells, _.bind(function(item) {
                    return item.secId === this.options.model.get('secId');
                }, this));
            if (item) {
                this.options.model.set(item);
            }
          this.save(result);
        },

        changeValidationStatus2: function(status) {
            App.request(status, this.options.modelRun.get('secId'),
                this.options.model.get('refAssay').secId, this.options.target, {lst:[this.options.model.get('secId')]})
                  .then(_.bind(this.save, this)).fail(_.bind(function (err) {
                if (err && err.status === 405) {
                    alert(_.i18n('error.noPermission'));
                }
            }, this));
        },

        save: function(result) {
            var i;
            switch(this.options.type) {
                case 'confirm-result':
                    this.options.model.set('res' + this.options.target + 'CodeErr', '');
                    this.options.model.set('val' + this.options.target + 'St', 3.2);
                    break;
                case 'change-to-neg':
                    this.options.model.set('res' + this.options.target + 'CodeErr', '');
                    this.options.model.set('res' + this.options.target + 'Num1', '');
                    this.options.model.set('res' + this.options.target + 'Num2', '');
                    this.options.model.set('res' + this.options.target + 'Num3', '');
                    this.options.model.set('res' + this.options.target + 'Code', 'N');
                    this.options.model.set('val' + this.options.target + 'St', 3.2);
                    break;
                case 'repeat':
                    if (this.options.target === -1) {
                        for (i = 1 ; i <= 6 ; i++) {
                            if (this.options.model.get('val' + i + 'St') !== 9) {
                                this.options.model.set('res' + i + 'Comment', 'Repeat PCR');
                            }
                        }
                    } else {
                        this.options.model.set('res' + this.options.target + 'Comment', 'Repeat PCR');
                    }
                    break;
                case 'repeat-extract':
                    if (this.options.target === -1) {
                        for (i = 1 ; i <= 6 ; i++) {
                            if (this.options.model.get('val' + i + 'St') !== 9) {
                                this.options.model.set('res' + i + 'Comment', 'Rerun for confirmation');
                            }
                        }
                    } else {
                        this.options.model.set('res' + this.options.target + 'Comment', 'Rerun for confirmation');
                    }
                    break;
                case 'repeat-extract-dil12':
                    if (this.options.target === -1) {
                        for (i = 1 ; i <= 6 ; i++) {
                            if (this.options.model.get('val' + i + 'St') !== 9) {
                                this.options.model.set('res' + i + 'Comment', 'Dilution 1:2');
                            }
                        }
                    } else {
                        this.options.model.set('res' + this.options.target + 'Comment', 'Dilution 1:2');
                    }
                    break;
                case 'repeat-extract-dil15':
                    if (this.options.target === -1) {
                        for (i = 1 ; i <= 6 ; i++) {
                            if (this.options.model.get('val' + i + 'St') !== 9) {
                                this.options.model.set('res' + i + 'Comment', 'Dilution 1:5');
                            }
                        }
                    } else {
                        this.options.model.set('res' + this.options.target + 'Comment', 'Dilution 1:5');
                    }
                    break;
            }

            this.amplCurve1 = this.options.model.get('amplCurve1');
            this.amplCurve2 = this.options.model.get('amplCurve2');
            this.amplCurve3 = this.options.model.get('amplCurve3');
            this.amplCurve4 = this.options.model.get('amplCurve4');
            this.amplCurve5 = this.options.model.get('amplCurve5');
            this.amplCurve6 = this.options.model.get('amplCurve6');
            this.meltCurve1 = this.options.model.get('meltCurve1');
            this.meltCurve2 = this.options.model.get('meltCurve2');
            this.meltCurve3 = this.options.model.get('meltCurve3');
            this.meltCurve4 = this.options.model.get('meltCurve4');
            this.meltCurve5 = this.options.model.get('meltCurve5');
            this.meltCurve6 = this.options.model.get('meltCurve6');
            this.options.model.set('amplCurve1', null);
            this.options.model.set('amplCurve2', null);
            this.options.model.set('amplCurve3', null);
            this.options.model.set('amplCurve4', null);
            this.options.model.set('amplCurve5', null);
            this.options.model.set('amplCurve6', null);
            this.options.model.set('meltCurve1', null);
            this.options.model.set('meltCurve2', null);
            this.options.model.set('meltCurve3', null);
            this.options.model.set('meltCurve4', null);
            this.options.model.set('meltCurve5', null);
            this.options.model.set('meltCurve6', null);
            this.options.model.saveResult().then(_.bind(function(model) {
                this.options.model.set('amplCurve1', this.amplCurve1);
                this.options.model.set('amplCurve2', this.amplCurve2);
                this.options.model.set('amplCurve3', this.amplCurve3);
                this.options.model.set('amplCurve4', this.amplCurve4);
                this.options.model.set('amplCurve5', this.amplCurve5);
                this.options.model.set('amplCurve6', this.amplCurve6);
                this.options.model.set('meltCurve1', this.meltCurve1);
                this.options.model.set('meltCurve2', this.meltCurve2);
                this.options.model.set('meltCurve3', this.meltCurve3);
                this.options.model.set('meltCurve4', this.meltCurve4);
                this.options.model.set('meltCurve5', this.meltCurve5);
                this.options.model.set('meltCurve6', this.meltCurve6);
                this.options.model.set(model);

                if (result) {
                    if(result.run) {
                        App.trigger('well:refresh:well', null, result.run.wells);
                    } else {
                        App.trigger('well:refresh:well', null, result.wells);
                    }
                }
                App.trigger('well:refresh:well', this.options.model);

                if (!this.current || (this.current && this.options.modelsCopy.length === 0)) {
                    if (this.end) {
                        this.end();
                    }
                } else if (this.current) {
                    this.current();
                }
            }, this));
        }
    });
  
    module.exports = ValidationUtils;
  });
  
