define('miniWellView',[
    'module',
    'marionette',
    'jquery',
    'template!miniWellTpl',
    'wellResultIconView'
], function(
    module,
    Marionette,
    $,
    miniWellTpl,
    WellResultIconView
) {
    'use strict';

    var MiniWellView = Marionette.LayoutView.extend({
        template: miniWellTpl,

        ui: {
            selectableItem: '.miniWellSelectableItem'
        },

        regions: {
            resultIcon: '.result-icon'
        },

        events: {
            'dblclick': 'onItemDblClick',
            'click': 'onItemClick',
            'click .item-selector-circle': 'onSelectClick'
        },

        onSelectClick: function(e) {
            e.preventDefault();
            e.stopPropagation();

            var $template = this.ui.selectableItem,
                selected = $template.hasClass('selected') ? true : false;

            if (selected) {
                $template.removeClass('selected');
                this.trigger('well:removeSelection', this.model);
            } else {
                $template.addClass('selected');
                this.trigger('well:addSelection', this.model);
            }
        },

        onItemDblClick:function() {
            this.trigger('well:filteredDetailItems', this.model);
        },

        onItemClick:function() {
            $('.miniWellSelectableItem').removeClass('selected');
            this.trigger('well:filteredDetailItem', this.model);
        },

        onShow: function() {
            if (this.displayResult) {
                this.iconResultView = new WellResultIconView({
                    model: this.model.toJSON(),
                    modelRun: this.options.modelRun,
                    channel: this.options.channel
                });
                this.getRegion('resultIcon').show(this.iconResultView);
            }
        },

        initialize: function () {
            this.model.on('change', this.reRender, this);
        },

        onDestroy: function() {
            this.model.off('change', this.reRender, this);
        },

        reRender: function() {
            this.render();
            this.onShow();
        },

        serializeData: function() {
          var templateData = {
            well: this.model.toJSON()
          };

          templateData.displayResult = '';
          if ((templateData.well.smpid || templateData.well.smptype === 'P')  && templateData.well.refAssay) {
              templateData.displayResult = true;
          }

          if (!templateData.well.selected) {
            templateData.well.selected = false;
          }

          if (this.options.modelRun.get('settings') && this.options.modelRun.get('settings')['OTHER-ASSAY-IND']) {
            var otherAssayInd = this.options.modelRun.get('settings')['OTHER-ASSAY-IND'].split(';');
            templateData.colorOtherWellSameAssay = otherAssayInd[0];
            templateData.colorOtherWellOtherAssay = otherAssayInd[1];
          } else {
            templateData.colorOtherWellSameAssay = '#FF0000';
            templateData.colorOtherWellOtherAssay = '#FFFFFF';
          }

          templateData.wellResColor = this.options.modelRun.getColorWellRes(templateData.well.wellRes);
          this.displayResult = templateData.displayResult;

          return templateData;
        }
    });

    module.exports = MiniWellView;
});
