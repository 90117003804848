/**
 * Created by RKL on 07/09/2015.
 */
define('adminKitDetailsView',[
  'module',
  'marionette',
  'underscore',
  'backbone',
  'app',
  'settings',
  'imageUtils',
  'template!adminKitDetailsTpl',
  'adminKitTabs',
  'rolesMixin',
  'backToListBehavior'
], function (
  module,
  Marionette,
  _,
  Backbone,
  App,
  Settings,
  imageUtils,
  viewTpl,
  kitsTabs,
  RolesMixin,
  BackToListBehavior
) {
  'use strict';

  var KitsDetailsView =  Marionette.LayoutView.extend({
    template: viewTpl,

    ui: {
      tab: '.kit-tab',
      tabPane: '.tab-pane',
      logo: '.js-logo-holder',
      name: '.p-name',
      code: '.p-code'
    },

    events: {
      'click @ui.tab': 'onTabClick',
      'change @ui.collectionToggle': 'onCollectionToggle'
    },

    regions: {
      kitDetailTab: '#detail-tab',
      assayListTab: '#assay-tab',
      kitlListTab: '#kitl-tab',
      kitprListTab: '#kitpr-tab',
      kitcListTab: '#kitc-tab'
    },

    behaviors: {
      BackToList: {
        behaviorClass: BackToListBehavior,
        redirectUrl: 'admin/kits'
      }
    },

    serializeData: function() {
      var templateData = this.model.toJSON();
      templateData.kitsTabs = [];
      _.each(kitsTabs, function(tab) {
        if (!tab.role || Settings.get('cAccountRoles')[tab.role]) {
          templateData.kitsTabs.push(tab);
        }
      });
      templateData.compulogoCollection = this.model.get('compulogoCollection');
      return templateData;
    },

    onRender: function() {
      if (this.options.data.tab) {
        this.ui.tab.removeClass('active');
        this.$el.find('.tab-pane').removeClass('active');
        this.$el.find('.kit-tab').removeClass('active');
        this.$el
          .find('.kit-tab[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
        this.$el
          .find('.tab-pane[data-name="' + this.options.data.tab.name + '"]')
          .addClass('active');
      }
    },

    onShow: function() {
      $.material.init();
    },

    onTabClick: function(e) {
      var $target = $(e.currentTarget),
        tab = {
          route: $target.data('route'),
          role: $target.data('role'),
          name: $target.data('name')
        };

      this.ui.tab.removeClass('active');
      this.ui.tabPane.removeClass('active');
      $target.addClass('active');
      this.$('.tab-pane[data-name="' + tab.name + '"]').addClass('active');

      this.triggerMethod('click:tab', tab);
    },

    onHeaderChange: function(kit) {
      this.ui.name.text(kit.name);
      this.ui.code.text(kit.code);
    }
  });

  module.exports = KitsDetailsView;
});
