/**
 * Created by BFR on 18/04/2019.
 */
define('sampleIdsView',[
    'module',
    'app',
    'underscore',
    'marionette',
    'jquery',
    'template!sampleIdsTpl',
    'sampleIdsGridView',
    'wellDetailView',
    'entities/wells'
], function (
    module,
    App,
    _,
    Marionette,
    $,
    viewTpl,
    SampleIdsGridView,
    WellDetailView
) {
    'use strict';

    var RunDetailView = Marionette.LayoutView.extend({
        template: viewTpl,

        ui: {
            'title': '.title-list'
        },

        events: {
        },

        regions: {
            listRegion: '.sample-ids-view',
            rightView: '.right-view'
        },

        onShow: function() {
            $.material.init();

            this.gridView = new SampleIdsGridView();
            this.gridView.listenTo(this.gridView, 'well-table:loaded', _.bind(this.onLoadSubViews, this));
            this.gridView.listenTo(this.gridView, 'onGridLoaded', _.bind(this.onGridLoaded, this));

            var region = this.getRegion('listRegion');
            if (region) {
                region.show(this.gridView);
            }
        },

        onLoadSubViews: function(wellSecId) {
            var line = _.find(this.gridView.codeTableData, function(line) {
                return line.well.secId === wellSecId;
            });
            this.onCreateRunGraphView(line);
        },

        onCreateRunGraphView: function(line) {
            this.model = App.request('well:model', line.well);
            this.modelRun = App.request('run:model', line.refRun);
            if (this.detailView) {
                this.detailView.destroy();
            }
            this.detailView = new WellDetailView({
                model: this.model
            });
            this.detailView.model = this.model;
            this.detailView.modelRun = this.modelRun;

            this.ui.title.html(this.model.get('smpid') + ' - ' + this.model.get('refAssay').code + ', ' +this.model.get('pos'));

            this.getRegion('rightView').show(this.detailView);
        },

        onGridLoaded:function () {
            var data = {
                url: App.request('run:get-collection-sampleids-url', this.options.runSecId, this.options.sampleId + '%25', {
                }),
                rowCountUrl: App.request('run:count-collection-sampleids-url', this.options.runSecId, this.options.sampleId + '%25', {
                })
            };
            this.gridView.displayGrid(data);
        }
    });

    module.exports = RunDetailView;
});
