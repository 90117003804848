
define('template!sampleCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group" data-field-name="sampleId">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('sample.sampleId') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="sampleId" placeholder="' +
((__t = ( _.i18n('sample.sampleId') )) == null ? '' : __t) +
'" value="' +
((__t = ( sampleId )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('sample.refLis') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="js-select-lis-region"></div>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="sampleTest">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('sample.sampleTest') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="sampleTest" placeholder="' +
((__t = ( _.i18n('sample.sampleTest') )) == null ? '' : __t) +
'" value="' +
((__t = ( sampleTest )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="ppgr">\n        <label class="col-xs-3 overflow-hidden control-label">' +
((__t = ( _.i18n("sample.ppgr") )) == null ? '' : __t) +
':</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input" data-field-name="ppgr" value="' +
((__t = ( ppgr )) == null ? '' : __t) +
'">\n            <option value="S" ';
  if (ppgr === 'S') { ;
__p += ' selected ';
 } ;
__p += ' >S - ' +
((__t = ( _.i18n("sample.ppgr.S") )) == null ? '' : __t) +
'</option>\n            <option value="M" ';
  if (ppgr === 'M') { ;
__p += ' selected ';
 } ;
__p += ' >M - ' +
((__t = ( _.i18n("sample.ppgr.M") )) == null ? '' : __t) +
'</option>\n            <option value="L" ';
  if (ppgr === 'L') { ;
__p += ' selected ';
 } ;
__p += ' >L - ' +
((__t = ( _.i18n("sample.ppgr.L") )) == null ? '' : __t) +
'</option>\n          </select>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="orderId">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('sample.orderId') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="orderId" placeholder="' +
((__t = ( _.i18n('sample.orderId') )) == null ? '' : __t) +
'" value="' +
((__t = ( orderId )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="patId">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('sample.patId') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="patId" placeholder="' +
((__t = ( _.i18n('sample.patId') )) == null ? '' : __t) +
'" value="' +
((__t = ( patId )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("sample.sampleDt") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="datepicker" class="form-control date-sampleDt js-info-input" data-field-name="sampleDt" placeholder="' +
((__t = ( sampleDt )) == null ? '' : __t) +
'" value="' +
((__t = ( sampleDt )) == null ? '' : __t) +
'">\n          <span class="input-group-btn">\n              <span class="caret pull-right m-t-5"></span>\n          </span>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

