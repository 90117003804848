define('runSelectTemplateLayoutView',[
  'module',
  'marionette',
  'backbone',
  'dialogFormView',
  'template!runSelectTemplateLayoutTpl',
  'savingBehavior',
  'bootbox',
  'underscore',
  'app',
  'settings',
  'autocompleteView',
  'entities/runs'
], function(
  module,
  Marionette,
  Backbone,
  DialogFormView,
  selectTemplateLayoutTpl,
  SavingBehavior,
  bootbox,
  _,
  App,
  Settings,
  AutocompleteView
) {
  'use strict';

  var CreateEditLayoutView = DialogFormView.extend({
    template: selectTemplateLayoutTpl,

    ui: {
      input: '.js-info-input'
    },

    events: {
      'click .js-cancel-popup': 'hide',
      'click .js-confirm': 'onConfirm'
    },

    regions: {
      selectRunTemplate: '.js-select-run-template-region'
    },

    behaviors: {
      Saving: {
        behaviorClass: SavingBehavior
      }
    },

    modelEvents: {
      sync: 'onSave',
      error: 'onSaveError'
    },

    fieldsToValidate: [
    ],

    onShow: function() {
      $.material.init();
      var runTempalteView = new AutocompleteView(
          this._getAutocompleteOptions('refRunTemplate', 'name', 'name', 'v2/runtemplates',
              'selectruntemplate.placeholder', 'nameComment', this.onTemplateChange)
      );
      this.getRegion('selectRunTemplate').options.allowMissingEl = true;
      this.getRegion('selectRunTemplate').show(runTempalteView);

      this.triggerMethod('enable:cancel:confirm');
    },

    onTemplateChange: function(fieldName, model) {
      this.currentTemplate = model;
    },

    onConfirm: function() {
      this.trigger('run:template-selected', this.currentTemplate);
      this.hide();
    },

    hide: function() {
      this.triggerMethod('hide');
      this.box.modal('hide');
      this.destroy();
    }
  });

  module.exports = CreateEditLayoutView;
});
