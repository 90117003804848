define('env',['module', 'browserUtils'
], function (module, BrowserUtils) {
  'use strict';
  
  var Env = {
    config: {
      defaults: {
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'app/styles/css/images/logomobio.png',
        compuzzLogo: 'app/styles/css/images/logomobiolink.png',
        notFoundImage: 'app/styles/css/images/404.png',
        catalogueDefaultImage: 'app/styles/css/images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/'
      },
      development: {
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'app/styles/css/images/logomobio.png',
        compuzzLogo: 'app/styles/css/images/logomobiolink.png',
        notFoundImage: 'app/styles/css/images/404.png',
        catalogueDefaultImage: 'app/styles/css/images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/'
      },
      minified: {
        filesPath: 'fileManagement/',
        serverUrl: 'http://localhost:8085/',
        defaultLogo: 'images/logomobio.png',
        compuzzLogo: 'images/compuzz.svg',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'http://localhost:8082/'
      },
      beta: {
        filesPath: 'files/',
        serverUrl: 'https://beta.mobiolink.com/',
        compuzzLogo: 'images/logomobiolink.png',
        defaultLogo: 'images/logomobio.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '/beta/',
        editOrderUrl: 'https://beta.mobiolink.com/htmlcompuzz/'
      },
      adtv2: {
        filesPath: 'files/',
        serverUrl: 'https://mobiolink.compuzz.com/',
        compuzzLogo: 'images/logomobiolink.png',
        defaultLogo: 'images/logomobio.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://optv2.compuzz.com/htmlcompuzz/'
      },
      aws: {
        filesPath: 'files/',
        serverUrl: 'https://dashboard.mobiolink.com/',
        compuzzLogo: 'images/logomobiolink.png',
        defaultLogo: 'images/logomobio.png',
        notFoundImage: 'images/404.png',
        catalogueDefaultImage: 'images/book.png',
        logoToolSubDomain: '',
        editOrderUrl: 'https://dashboard.mobiolink.com/htmlcompuzz/'
      },
      teamw: {
          filesPath: 'files/',
          serverUrl: 'http://srvxmobioapp01.team-w.local/',
          compuzzLogo: 'images/logomobiolink.png',
          defaultLogo: 'images/logomobio.png',
          notFoundImage: 'images/404.png',
          catalogueDefaultImage: 'images/book.png',
          logoToolSubDomain: '',
          editOrderUrl: 'http://srvxmobioapp01.team-w.local/'
      },
      teamwtest: {
          filesPath: 'files/',
          serverUrl: 'http://srvxmobiotestapp01.team-w.local/',
          compuzzLogo: 'images/logomobiolink.png',
          defaultLogo: 'images/logomobio.png',
          notFoundImage: 'images/404.png',
          catalogueDefaultImage: 'images/book.png',
          logoToolSubDomain: '',
          editOrderUrl: 'http://srvxmobiotestapp01.team-w.local/'
      }
    },

    isProduction: function() {
      return this.getEnvironment() === 'production';
    },

    getEnvironment: function() {
      switch ( BrowserUtils.getHostName() ) {
        case 'localhost':
        case '127.0.0.1':
          return 'localhost';
        case 'betamobiolink.compuzz.com':
          return 'beta';
        case 'mobiolink.compuzz.com':
          return 'production';
      }
    }
  };
  module.exports = Env;
});

