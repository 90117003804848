
define('template!kitProtCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group" data-field-name="code">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitprot.code') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input js-code"\n                 data-field-name="code" placeholder="' +
((__t = ( _.i18n('kitprot.code') )) == null ? '' : __t) +
'"\n                 value="' +
((__t = ( code )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('kitprot.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                 data-placement="top">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="name">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitp.refMbAModel') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <div class="js-select-mbamodel-region"></div>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="description">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <textarea class="form-control floating-label js-info-input" rows="4" data-field-name="description" placeholder="' +
((__t = ( _.i18n('common.description') )) == null ? '' : __t) +
'">' +
((__t = ( description )) == null ? '' : __t) +
'</textarea>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="resGraph">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitprot.resGraph') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <select class="form-control js-info-input" data-field-name="resGraph" value="">\n            <option value="A" ';
  if (resGraph === 'A') { ;
__p += ' selected ';
 } ;
__p += ' >A</option>\n            <option value="AM" ';
  if (resGraph === 'AM') { ;
__p += ' selected ';
 } ;
__p += ' >AM</option>\n            <option value="M" ';
  if (resGraph === 'M') { ;
__p += ' selected ';
 } ;
__p += ' >M</option>\n          </select>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="channels">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitprot.channels') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="channels" placeholder="' +
((__t = ( _.i18n('kitprot.channels') )) == null ? '' : __t) +
'" value="' +
((__t = ( channels )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group checkbox-group" data-field-name="onlyExtractSamples">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n("kitprot.onlyExtractSamples") )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 checkbox checkbox-primary">\n          <label>\n            <input type="checkbox" id="onlyExtractSamples" data-field-name="onlyExtractSamples" class="js-keep-logged-in js-info-input" ' +
((__t = ( onlyExtractSamples ? 'checked' : '' )) == null ? '' : __t) +
'>\n          </label>\n        </div>\n      </div>\n      <div class="form-group" data-field-name="runNamePattern">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitprot.runNamePattern') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="runNamePattern" placeholder="' +
((__t = ( _.i18n('kitprot.runNamePattern') )) == null ? '' : __t) +
'" value="' +
((__t = ( runNamePattern )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="defaultNbColumns">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitprot.defaultNbColumns') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="defaultNbColumns" placeholder="' +
((__t = ( _.i18n('kitprot.defaultNbColumns') )) == null ? '' : __t) +
'" value="' +
((__t = ( defaultNbColumns )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="defaultNbLines">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitprot.defaultNbLines') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="number" class="form-control floating-label js-info-input" data-field-name="defaultNbLines" placeholder="' +
((__t = ( _.i18n('kitprot.defaultNbLines') )) == null ? '' : __t) +
'" value="' +
((__t = ( defaultNbLines )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="exportRoutine">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('kitprot.exportRoutine') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control floating-label js-info-input" data-field-name="exportRoutine" placeholder="' +
((__t = ( _.i18n('kitprot.exportRoutine') )) == null ? '' : __t) +
'" value="' +
((__t = ( exportRoutine )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

