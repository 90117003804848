
define('template!wellResultIconTpl2', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-xs-1 p-l-0 p-r-16-per tooltip1">\n    ';
 if (color) { ;
__p += '\n    <div class="circleResult circleResult1" style="position: absolute; background-color: ' +
((__t = ( color )) == null ? '' : __t) +
' !important; ' +
((__t = ( ic?'width:9px;height:9px;margin-left:4px;margin-top:6px;':'' )) == null ? '' : __t) +
'">\n        ';
 if (repeatSt === 'P') { ;
__p += '\n            <div class="ico-repeat-pcr" style="' +
((__t = ( ic?'width:9px; height:4px; border-bottom-right-radius:9px; border-bottom-left-radius:9px; bottom:0.6px; left:-1px;':'')) == null ? '' : __t) +
'"></div>\n        ';
 } else if (repeatSt === 'D') { ;
__p += '\n            <div class="ico-repeat-dna-1" style="' +
((__t = ( ic?'height: 4px; width: 4px; border-radius: 4px 0 0 0;':'' )) == null ? '' : __t) +
'"></div>\n            <div class="ico-repeat-dna-2" style="' +
((__t = ( ic?'height: 4px; width: 4px; border-radius: 4px 0 0 0;':'' )) == null ? '' : __t) +
'"></div>\n        ';
 } ;
__p += '\n    </div>\n    ';
 } ;
__p += '\n    ';
 if (valSt === 2.1) { ;
__p += '\n        \n    ';
 } else if (valSt === 2.2) { ;
__p += '\n\n    ';
 } else if (valSt === 3.1) { ;
__p += '\n        <i class="mdi-social-school" style="position: absolute; font-size: 11px; color:black; ' +
((__t = ( ic?'top:1px; left:8px;':'top:0px;left:7px' )) == null ? '' : __t) +
'"></i>\n    ';
 } else if (valSt === 3.2) { ;
__p += '\n        <i class="mdi-action-done" style="position: absolute; font-size: 11px; color:black; ' +
((__t = ( ic?'left:8px; top:2px;':'left:8px; top:1px;' )) == null ? '' : __t) +
'"></i>\n    ';
 } else if (valSt >= 4 && valSt < 5) { ;
__p += '\n        <i class="mdi-action-done-all" style="position: absolute; font-size: 11px; color:black; ' +
((__t = ( ic?'left:4px; top:1px;':'left:5px; top:0px;' )) == null ? '' : __t) +
'"></i>\n    ';
 } else if (valSt === 5) { ;
__p += '\n        <div class="smallCircleVal" style="background-color: white; ' +
((__t = ( ic?'width:5px; height:5px; border-radius:5px; left:6px; top:6px;':'left:4px; top:4px;' )) == null ? '' : __t) +
'"></div>\n    ';
 } ;
__p += '\n\n    ';
 if(!resCodeErr && resCommentErrAuto) { ;
__p += '\n        <i class="mdi-alert-warning" style="position: absolute; font-size: 9px; color:black; ' +
((__t = ( ic?'top:10px; left:4px':'top:10px; left:3px' )) == null ? '' : __t) +
'"></i>\n    ';
 } ;
__p += '\n\n    ';
 if (resCodeErr === 'E' || resCodeErr === 'W') { ;
__p += '\n        <i class="mdi-alert-warning" style="position: absolute; font-size: 13px; color:black; ' +
((__t = ( ic?'top:8px; left:2px':'top:8px; left:1px' )) == null ? '' : __t) +
'"></i>\n    ';
 } ;
__p += '\n</div>\n';

}
return __p
};});

