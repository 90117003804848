define('entities/runtemplates',[
  'app',
  'backbone',
  'settings',
  'jquery',
  'underscore'
], function(
  App,
  Backbone,
  Settings,
  $,
  _
) {
  'use strict';

  var RunTemplate = Backbone.Model.extend({
    fetch: function() {
      this.url = Settings.url('compuzz', 'v2/runtemplates/' + this.get('secId'));

      return Backbone.Model.prototype.fetch.call(this);
    },
    defaults: {
      'code':  '',
      'plateId': '',
      'plateRowNb': 'H',
      'plateColumnNb': '12',
      'comment': '',
      'isPool': false,
      'isPCR': false,
      'isPR': false
    },
    idAttribute: 'secId',

    getImageUid:function() {},

    postUrl: function () {
      return Settings.url('compuzz', 'v2/runtemplates');
    },

    save: function() {
      this.url = this.postUrl();
      return Backbone.Model.prototype.save.call(this);
    }

  });

  var RunTemplateCollection = Backbone.Collection.extend({
    url: function() {
      return Settings.url('compuzz', 'v2/runtemplates');
    },
    model: RunTemplate
  });

  var API = {

    getRunTemplateCollectionUrl: function() {
      new RunTemplateCollection();
      var params = {};
      return Settings.url('compuzz', 'v2/runtemplates', params);
    },

    getAllRunTemplates: function(params) {
      var url = Settings.url('compuzz', 'v2/runtemplates', params),
          defer = $.Deferred();

      $.ajax({
        method: 'get',
        url: url,
        success: function(runtemplates) {
          var runtemplatesList = [];
          _.each(runtemplates, function(runtemplate){
            runtemplatesList.push(runtemplate.secId);
          });
          defer.resolve(runtemplatesList);
        }
      });

      return defer.promise();
    },

    getRunTemplateCountUrl: function() {
      var params = '';
      return 'v2/runtemplates/rowCount' + params;
    },

    deleteRunTemplate: function(secId){
      var url = Settings.url('compuzz', 'v2/runtemplates/'+secId),
          defer = $.Deferred();

      $.ajax({
        type: 'DELETE',
        url: url,
        success: function (data) {
          defer.resolve(data);
        }
      });

      return defer.promise();
    }
  };

  App.reqres.setHandler('runtemplate:get-collection-url', function() {
      return API.getRunTemplateCollectionUrl();
  });

  App.reqres.setHandler('runtemplate:get-collection-to-page', function(params) {
      return API.getAllRunTemplates(params);
  });

  App.reqres.setHandler('runtemplate:get-count-url', function() {
    return API.getRunTemplateCountUrl();
  });

  App.reqres.setHandler('runtemplate:model', function(runtemplateJSON) {
    return new RunTemplate(runtemplateJSON);
  });

  App.reqres.setHandler('runtemplate:delete', function(secId) {
    return API.deleteRunTemplate(secId);
  });

});
