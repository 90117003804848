/**
 * Created by RKL on 21/08/2015.
 */
define('samplesController',[
  'module',
  'logger',
  'underscore',
  'app',
  'samplesShowView',
  'jquery',
  'bootbox',
  'sampleCreateEditLayoutView',
  'emptyView',
  'sampleCreateFromUploadView',
  'entities/samples'
], function(
  module,
  Logger,
  _,
  App,
  View,
  $,
  bootbox,
  CreateEditLayoutView,
  EmptyView,
  CreateFromUploadView
) {
  'use strict';

  var SamplesController = {
    showSampleList: function() {
      var view = new View();

      this.view = view;

      view.listenTo(view, 'onGridLoaded', _.bind(this.onGridLoaded, this));
      view.listenTo(view, 'sample:delete', _.bind(this.onDelete, this));
      view.listenTo(view, 'sample:import-xls-file', _.bind(this.onImportXls, this));
      App.regions.getRegion('main').currentView.getRegion('content').show(view);
    },

    showNewSample: function() {
      this.createEditView = new CreateEditLayoutView({
        sampleModel: App.request('sample:model'),
        newItem: true
      });
      this.createEditView.show({
        title: _.i18n('sample.new')
      });
    },

    onImportXls: function(fileInfo) {
      var that = this;
      var createFromUploadView = new CreateFromUploadView();
      createFromUploadView.listenTo(createFromUploadView, 'popup-create:selectLis', _.bind(function(lis) {
          this.view.onDestroy();
          this.emptyView = new EmptyView();
          App.regions.getRegion('main').currentView.getRegion('content').show(this.emptyView);

          $.when(App.request('sample:importXlsFile', fileInfo, lis?lis.get('secId'):null)).done(function() {
              that.emptyView.destroy();
              that.showSampleList();
          }).fail(function() {
              that.emptyView.destroy();
              that.showSampleList();
              bootbox.alert(_.i18n('common.errorOccuredWhileImporting'), function () {
                  return;
              });
          });
      }, this));

      createFromUploadView.show({
          title: _.i18n('sample.import')
      });
    },

    onGridLoaded: function() {
      var that = this, data;

      data = {
        url: App.request('sample:get-collection-url'),
        rowCountUrl: App.request('sample:get-count-url')
      };

      that.view.displayGrid(data);
    },

    onDelete: function(secId){
      bootbox.confirm(_.i18n('sample.confirmDelete'), _.bind(function(result) {
        if(result){
          App.request('sample:delete', secId)
              .done(_.bind(function(){
                this.view.updatePagination('reloadGrid');
              }, this));
        }
      }, this));
    }
  };

  module.exports = SamplesController;
});
