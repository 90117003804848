
define('template!runEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="detailed-info-form-container" style="min-height: 150px;">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n      <div class="error-msg">  </div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="createRunFromEmpty row">\n        <div class="form-group">\n          <div class="col-xs-6" data-field-name="name">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n              <input type="text" class="form-control floating-label js-info-input run-input-name" data-field-name="name" placeholder="' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n            </div>\n          </div>\n          <div class="col-xs-6" data-field-name="plateColumnNb">\n            <label class="col-xs-6 overflow-hidden control-label">' +
((__t = ( _.i18n("run.status") )) == null ? '' : __t) +
':</label>\n            <div class="col-xs-6">\n              <select class="form-control js-info-input" data-field-name="status" value="' +
((__t = ( status )) == null ? '' : __t) +
'">\n                <option value="0" ';
  if (status === '0') { ;
__p += ' selected ';
 } ;
__p += ' >0 - ' +
((__t = ( _.i18n("run.status.0") )) == null ? '' : __t) +
'</option>\n                <option value="1" ';
  if (status === '1') { ;
__p += ' selected ';
 } ;
__p += ' >1 - ' +
((__t = ( _.i18n("run.status.1") )) == null ? '' : __t) +
'</option>\n                <option value="2" ';
  if (status === '2') { ;
__p += ' selected ';
 } ;
__p += ' >2 - ' +
((__t = ( _.i18n("run.status.2") )) == null ? '' : __t) +
'</option>\n                <option value="3" ';
  if (status === '3') { ;
__p += ' selected ';
 } ;
__p += ' >3 - ' +
((__t = ( _.i18n("run.status.3") )) == null ? '' : __t) +
'</option>\n                <option value="4" ';
  if (status === '4') { ;
__p += ' selected ';
 } ;
__p += ' >4 - ' +
((__t = ( _.i18n("run.status.4") )) == null ? '' : __t) +
'</option>\n                ';
 if (status === '3') { ;
__p += ' \n                  <option value="5" ';
  if (status === '5') { ;
__p += ' selected ';
 } ;
__p += ' >5 - ' +
((__t = ( _.i18n("run.status.5") )) == null ? '' : __t) +
'</option>\n                ';
 } ;
__p += '\n              </select>\n            </div>\n          </div>\n        </div>\n        <div class="form-group">\n          <div class="col-xs-6 h-16">\n          </div>\n          <div class="col-xs-6">\n            <label class="col-xs-6 control-label">' +
((__t = ( _.i18n('run.valStatus') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-6">\n              <input type="text" class="form-control floating-label js-info-input" data-field-name="valStatus" placeholder="' +
((__t = ( _.i18n('run.valStatus') )) == null ? '' : __t) +
'" value="' +
((__t = ( valStatus )) == null ? '' : __t) +
'">\n            </div>\n          </div>\n        </div>\n        <div class="col-xs-8" style="position:absolute; top:54px; left:10px;">\n          <label class="">' +
((__t = ( _.i18n('run.poolStatusLevel') )) == null ? '' : __t) +
'</label>\n          <div class="" style="display: contents;">\n            <input type="checkbox" data-field-name="isDWPoolPlate" class="js-keep-logged-in js-info-input pool-checkbox" ' +
((__t = ( isDWPoolPlate ? 'checked' : '' )) == null ? '' : __t) +
'>\n          </div>\n        </div>\n        <div class="m-b-15">\n            <table border="1">\n              <thead>\n                  <tr style="background-color: #11ABC3;">\n                      <th class="p-l-2" style="width: 92px;">' +
((__t = ( _.i18n("run.state") )) == null ? '' : __t) +
'</th>\n                      <th class="p-l-2" style="width: 45px;"></th>\n                      <th class="p-l-2" style="width: 41px;">' +
((__t = ( _.i18n("run.is") )) == null ? '' : __t) +
'</th>\n                      <th class="p-l-2" style="width: 80px;">' +
((__t = ( _.i18n("run.plateId") )) == null ? '' : __t) +
'</th>\n                      <th class="p-l-2" style="width: 115px;">' +
((__t = ( _.i18n("run.datehh") )) == null ? '' : __t) +
'</th>\n                      <th class="p-l-2" style="width: 118px;">' +
((__t = ( _.i18n("run.instr") )) == null ? '' : __t) +
'</th>\n                      <th class="p-l-2" style="width: 118px;">' +
((__t = ( _.i18n("run.prot") )) == null ? '' : __t) +
'</th>\n                  </tr>\n              </thead>\n              <tbody>\n                  <tr>\n                    <td class="p-l-2">' +
((__t = ( _.i18n("run.primary") )) == null ? '' : __t) +
'</td>\n                    <td class="p-l-2">PR</td>\n                    <td class="p-l-2">\n                      <input type="checkbox" data-field-name="isPRState" class="js-keep-logged-in js-info-input" ' +
((__t = ( isPRState ? 'checked' : '' )) == null ? '' : __t) +
'>\n                    </td>\n                    <td class=""><input type="text" class="form-control floating-label js-info-input js-plateId"\n                        data-field-name="plateIdPR" placeholder=""\n                        value="' +
((__t = ( plateIdPR )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('run.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                        data-placement="top"></td>\n                    <td class=""><input type="text" class="form-control floating-label js-info-input js-plateId"\n                      data-field-name="datehhPR" placeholder=""\n                      value="' +
((__t = ( datehhPR )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('run.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                      data-placement="top"></td>\n                    <td class="p-l-2" style="background-color: lightgrey;"></td>\n                    <td class="p-l-2" style="background-color: lightgrey;"></td>\n                  </tr>\n                  <tr>\n                    <td class="p-l-2">' +
((__t = ( _.i18n("run.primaryDw") )) == null ? '' : __t) +
'</td>\n                    <td class="p-l-2">DW</td>\n                    <td class="p-l-2">\n                      <input type="checkbox" data-field-name="isDWState" class="js-keep-logged-in js-info-input" ' +
((__t = ( isDWState ? 'checked' : '' )) == null ? '' : __t) +
'>\n                    </td>\n                    <td class=""><input type="text" class="form-control floating-label js-info-input js-plateId"\n                        data-field-name="plateIdDW" placeholder=""\n                        value="' +
((__t = ( plateIdDW )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('run.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                        data-placement="top"></td>\n                    <td class=""><input type="text" class="form-control floating-label js-info-input js-plateId"\n                      data-field-name="datehhDW" placeholder=""\n                      value="' +
((__t = ( datehhDW )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('run.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                      data-placement="top"></td>\n                    <td class="p-l-2" style="background-color: lightgrey;"></td>\n                    <td class="p-l-2" style="background-color: lightgrey;"></td>\n                  </tr>\n                  <tr>\n                    <td class="p-l-2">' +
((__t = ( _.i18n("run.lyse") )) == null ? '' : __t) +
'</td>\n                    <td class="p-l-2">LY</td>\n                    <td class="p-l-2">\n                      <input type="checkbox" data-field-name="isLYState" class="js-keep-logged-in js-info-input after-lyse" ' +
((__t = ( isLYState ? 'checked' : '' )) == null ? '' : __t) +
'>\n                    </td>\n                    <td class=""><input type="text" class="form-control floating-label js-info-input js-plateId after-lyse"\n                        data-field-name="plateIdLY" placeholder="" \n                        value="' +
((__t = ( plateIdLY )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('run.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                        data-placement="top"></td>\n                    <td class=""><input type="text" class="form-control floating-label js-info-input js-plateId after-lyse"\n                      data-field-name="datehhLY" placeholder=""\n                      value="' +
((__t = ( datehhLY )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('run.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                      data-placement="top"></td>\n                    <td class="p-l-2" style="background-color: lightgrey;"></td>\n                    <td class="p-l-2" style="background-color: lightgrey;"></td>\n                  </tr>\n                  <tr>\n                    <td class="p-l-2">' +
((__t = ( _.i18n("run.eluat") )) == null ? '' : __t) +
'</td>\n                    <td class="p-l-2">EL</td>\n                    <td class="p-l-2">\n                      <input type="checkbox" data-field-name="isELState" class="js-keep-logged-in js-info-input after-lyse" ' +
((__t = ( isELState ? 'checked' : '' )) == null ? '' : __t) +
'>\n                    </td>\n                    <td class=""><input type="text" class="form-control floating-label js-info-input js-plateId after-lyse"\n                        data-field-name="plateIdEL" placeholder=""\n                        value="' +
((__t = ( plateIdEL )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('run.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                        data-placement="top"></td>\n                    <td class=""><input type="text" class="form-control floating-label js-info-input js-plateId after-lyse"\n                      data-field-name="datehhEL" placeholder="" \n                      value="' +
((__t = ( datehhEL )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('run.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                      data-placement="top"></td>\n                    <td class=""><input type="text" class="form-control floating-label js-info-input js-plateId after-lyse"\n                      data-field-name="eluatInstr" placeholder=""\n                      value="' +
((__t = ( eluatInstr )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('run.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                      data-placement="top"></td>\n                    <td class="p-l-2" style="background-color: lightgrey;"></td>\n                  </tr>\n                  <tr>\n                    <td class="p-l-2">' +
((__t = ( _.i18n("run.PCR") )) == null ? '' : __t) +
'</td>\n                    <td class="p-l-2">PC</td>\n                    <td class="p-l-2">\n                      <input type="checkbox" data-field-name="isPCState" class="js-keep-logged-in js-info-input after-lyse" ' +
((__t = ( isPCState ? 'checked' : '' )) == null ? '' : __t) +
'>\n                    </td>\n                    <td class=""><input type="text" class="form-control floating-label js-info-input js-plateId after-lyse"\n                        data-field-name="plateId" placeholder="" \n                        value="' +
((__t = ( plateId )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('run.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                        data-placement="top"></td>\n                    <td class=""><input type="text" class="form-control floating-label js-info-input js-plateId after-lyse"\n                      data-field-name="datehhPC" placeholder="" \n                      value="' +
((__t = ( datehhPC )) == null ? '' : __t) +
'" data-content="' +
((__t = ( _.i18n('run.duplicatedCodeError') )) == null ? '' : __t) +
'"\n                      data-placement="top"></td>\n                    <td class="p-l-2"><div class="js-select-lmbanalyzer-region"></div></td>\n                    <td class="p-l-2"><div class="js-select-kitprot-region"></div></td>\n                  </tr>\n              </tbody>\n            </table>\n        </div>\n        <div class="form-group ' +
((__t = ( newItem?'hidden':'' )) == null ? '' : __t) +
'"" data-field-name="comment">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            <textarea class="form-control floating-label js-info-input" rows="4" data-field-name="comment" placeholder="' +
((__t = ( _.i18n('common.comment') )) == null ? '' : __t) +
'">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n          </div>\n        </div>\n        <div class="form-group">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('run.layoutFileName') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            ';
 if (layoutFileName) { ;
__p += ' \n              <a href="' +
((__t = ( layoutFileUrl )) == null ? '' : __t) +
'" class="clickable">' +
((__t = ( layoutFileName )) == null ? '' : __t) +
'</a>\n            ';
 } else { ;
__p += ' \n              -\n            ';
 } ;
__p += '\n          </div>\n        </div>\n        <div class="form-group">\n          <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('run.resultFileName') )) == null ? '' : __t) +
'</label>\n          <div class="col-xs-9">\n            ';
 if (resultFileName) { ;
__p += ' \n              <a href="' +
((__t = ( resultFileUrl )) == null ? '' : __t) +
'" class="clickable">' +
((__t = ( resultFileName )) == null ? '' : __t) +
'</a>\n            ';
 } else { ;
__p += ' \n              -\n            ';
 } ;
__p += '\n          </div>\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

