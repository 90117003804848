
define('template!racksViewTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="content">\n  <div class="well jq-grid-table-page">\n  \t<h2>' +
((__t = ( _.i18n('menu.rackManagement') )) == null ? '' : __t) +
'</h2>\n    <div class="form-group">\n      <a href="#racks/new" class="btn new-run-button btn-sm add-btn-text">\n        <i class="mdi-content-add"></i>\n        <span>' +
((__t = ( _.i18n('rack.new') )) == null ? '' : __t) +
'</span>\n      </a>\n      <div class="js-action-buttons-region racks-action-button f-r"></div>\n      <!-- import -->\n      <input type="file"\n             id="rack-excel-input"\n             class="hidden js-upload-racks" data-icon="true"\n             multiple="multiple"\n             accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>\n    </div>\n    <div class="sub-caccounts-table">\n      <table id="jq-grid-list" class="cacountTable" role="grid"></table>\n      <div id="jq-grid-pager-list"></div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

