define('rackelView',[
    'module',
    'marionette',
    'template!rackelTpl'
], function(
    module,
    Marionette,
    rackelTpl
) {
    'use strict';

    var RackelView = Marionette.ItemView.extend({
        template: rackelTpl,

        ui: {
            elementRight: '.elementRight'
        },

        events: {
            'click': 'onItemClick'
        },

        onItemClick:function() {
            this.trigger('rackel:filteredDetailItem', this.model.rowNb, this.model.colNb);
        },

        serializeData: function() {
            return {
                rackel: this.model
            };
        }
    });

    module.exports = RackelView;
});
